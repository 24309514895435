import React from "react";
import classNames from "classnames";
import { BrowserView, MobileView } from "react-device-detect";
import Flex from "antd-mobile/es/flex";

// import DesktopHeaderLogo from "src/bepaid/assets/images/desktop/header_ipaymy_logo.svg";
import DesktopFooterLeft from "src/bepaid/assets/images/desktop/footer_pci.svg";
import DesktopFooterRight from "src/bepaid/assets/images/desktop/ipaymy2025.png";

// import { getUtmLink } from "src/bepaid/utils/getUtmParams";

import styles from "./GuestLayout.module.scss";

const GuestLayout: React.FC<any> = (props: any) => {
  const { children, header, title } = props;

  React.useEffect(() => {
    try {
      (window as any).Intercom("update");
    } catch (e) {
      window.Logger.guestError("Intercom update failed: " + e.toString());
    }
  }, []);

  const renderDesktopView = () => {
    return (
      <>
        {/* <div className={styles.banner}>
          We've recently updated our Terms and Conditions.&nbsp;
          <a
            target="_blank"
            href={getUtmLink("https://www.ipaymy.com/terms-conditions")}
          >
            Read more here.
          </a>
        </div> */}
        <div className={classNames(styles.desktopWrapper, styles.background)}>
          {/* <a target="_blank" href={getUtmLink("https://www.ipaymy.com")}>
            <img
              className={styles.headerLogo}
              src={DesktopHeaderLogo}
              alt="header ipaymy logo"
            />
          </a> */}
          <img
            className={classNames(styles.footer, styles.left)}
            src={DesktopFooterLeft}
            alt="footer pci logo"
          />
          <img
            className={classNames(styles.footer, styles.right)}
            src={DesktopFooterRight}
            alt="footer ipaymy logo"
          />
          <div className={styles.centerBox}>{children}</div>
        </div>
      </>
    );
  };

  const renderMobileView = () => {
    return (
      <>
        {/* <div
          className={classNames(styles.banner, {
            [styles.mobileBanner]: isMobile
          })}
        >
          We've recently updated our Terms and Conditions.&nbsp;
          <a
            target="_blank"
            href={getUtmLink("https://www.ipaymy.com/terms-conditions")}
          >
            Read more here.
          </a>
        </div> */}
        <div className={styles.wrapper}>
          {header && (
            <div className={styles.header}>
              <Flex direction="column" justify="center" align="center">
                <Flex.Item>{header}</Flex.Item>
              </Flex>
            </div>
          )}
          <div>
            <div>
              {title && (
                <div className={styles.title}>
                  <Flex
                    direction="column"
                    justify="center"
                    align="center"
                    alignContent="center"
                  >
                    <Flex.Item>{title}</Flex.Item>
                  </Flex>
                </div>
              )}
              <div className={styles.content}>{children}</div>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <BrowserView>{renderDesktopView()}</BrowserView>
      <MobileView>{renderMobileView()}</MobileView>
    </>
  );
};

export default GuestLayout;
