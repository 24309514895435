/**
 * Selectors
 *
 * It's like "views" in relational databases.
 * Store the most efficient format in the reducer state.
 * But to retrieve into easy to consume form, use selector.
 */
import { RootState } from "../reducers";
import * as jwtDecode from "jwt-decode";
import _get from "lodash-es/get";
import { ROLES } from "../AccountProfile/const";
import CurrencyUtils from "src/ipm-shared/Utils/Currency";
import {
  getAvailableAcquirers,
  getCurrentAccountProfile,
  hasFetchedAccountProfile
} from "../AccountProfile/selectors";
import {
  getCurrencyCodeFromCountryId,
  getCountryCodeFromCountryId
} from "../Country/selectors";
import {
  getCurrencyCodeById,
  getCurrencyIdByCode
} from "../Currency/selectors";

import { accessTokenProps } from "src/ipm-shared/store/metadata/access_token_property_list";

import { BRAND_ID } from "src/ipm-shared/store/model/CollectedAccount/const";
// Auth
// By using local storage, the token always available. Please do not change.

export const getToken = (state?: RootState) =>
  localStorage.getItem("token") || "";

// Please do not change since it depends on unchanged getToken
export const getTokenData = (
  prop: keyof typeof accessTokenProps,
  defaultValue?: any
) => {
  let decodedToken: object;
  try {
    decodedToken = jwtDecode(getToken());
  } catch (e) {
    decodedToken = {};
  }
  const value: string = _get(decodedToken, "v", "") as string;
  if (value !== "") {
    const parts = value.split("|");
    switch (prop) {
      case "account_country_id":
      case "acquirer_id":
      case "id":
      case "uid":
      case "version":
      case "payment_country_id":
      case "payment_currency_id":
      case "payment_paid_currency_id":
      case "feature_gate":
      case "role_id":
        return parseInt(_get(parts, accessTokenProps[prop], "0"), 10);
      case "sub_id":
        if (_get(parts, accessTokenProps[prop]) === "_") {
          return 0;
        }
        return parseInt(_get(parts, accessTokenProps[prop], "0"), 10);
      case "dashboard_theme":
      case "app_id":
        if (_get(parts, accessTokenProps[prop], "_") === "_") {
          return undefined;
        } else {
          return _get(parts, accessTokenProps[prop]).toString();
        }
      case "has_company":
      case "is_business":
      case "is_production":
      case "has_company_draft":
        return _get(parts, accessTokenProps[prop]) === "1";
    }
  }

  return defaultValue;
};

export const hasToken = (state?: RootState): boolean =>
  !!localStorage.getItem("token");
export const getSmsOtpToken = (state: RootState) => state.auth.smsOtpToken;
export const hasSmsOtpToken = (state: RootState) =>
  state.auth.smsOtpToken !== "";
export const getFeatures = (state?: RootState): number =>
  getTokenData("feature_gate", 0) as number;

export const hasCompany = (state?: RootState): boolean =>
  getTokenData("has_company", false) as boolean;
export const hasCompanyDraft = (state?: RootState): boolean =>
  getTokenData("has_company_draft", false) as boolean;
export const getAccountCountryId = (state?: RootState): number =>
  getTokenData("account_country_id", 0) as number;
export const getDashboardTheme = (state?: RootState) =>
  getTokenData("dashboard_theme", undefined) as string | undefined;
export const getRoleId = (state?: RootState): number =>
  getTokenData("role_id", 0) as number;
export const getAccountCurrencyFromCountryId = (state?: RootState): number => {
  const countryId = getAccountCountryId(state);
  switch (countryId) {
    case 1:
      return 1;
    case 2:
      return 2;
    case 3:
      return 3;
    case 16:
      return 11;
    default:
      return 0;
  }
};
export const getCurrentCountry = (state: RootState): string => {
  return getCountryCodeFromCountryId(
    state,
    getCurrentCountryId(state)
  ) as string;
};

export const getAccountCountryCode = (state: RootState): string => {
  const countryId = getAccountCountryId(state);
  return getCountryCodeFromCountryId(state, countryId);
};

export const getAccountCurrencyId = (state: RootState): number => {
  const countryId = getAccountCountryId();
  return getCurrencyIdByCode(
    state,
    getCurrencyCodeFromCountryId(state, countryId)
  ) as number;
};

export const getCurrentCountryId = (state: RootState): number => {
  // NOTE: we still depend on account profile country id to display correct international UI
  // TODO: find a good way to destroy this completely
  if (hasFetchedAccountProfile(state)) {
    return getCurrentAccountProfile(state).countryId;
  }

  return getTokenData("payment_country_id", 0);
};

export const getCurrentCurrency = (state: RootState): string =>
  getCurrencyCodeById(state, getCurrentCurrencyId());

export const getIsBusinessAccount = (state?: RootState): boolean =>
  getTokenData("is_business", false);

export const getIsPersonalAccount = (state?: RootState): boolean =>
  !getIsBusinessAccount();

export const getIsProduction = (state: RootState): boolean =>
  getTokenData("is_production", false) as boolean;

export const isAdminRole = (state?: RootState): boolean => {
  const roleId = getRoleId();
  return roleId === ROLES.INTERNAL_ADMIN_1 || roleId === ROLES.INTERNAL_ADMIN_2;
};

export const isEnterpriseRole = (state?: RootState): boolean => {
  const roleId = getRoleId();
  return roleId === ROLES.ENTERPRISE_USER;
};

export const getCurrentCurrencyId = (state?: RootState): number =>
  getTokenData("payment_currency_id", 0);

export const getCurrentPaidCurrencyId = (state?: RootState): number =>
  getTokenData("payment_paid_currency_id", 0);

export const getCurrentPaidCurrency = (state?: RootState): string =>
  CurrencyUtils.convertFromId(getCurrentPaidCurrencyId()).code;

export const getAcquirerId = (state: RootState): number => {
  const defaultAccountProfileAcquirerId = _get(
    getAvailableAcquirers(state).find(
      (acquirer: any) => acquirer.card_brand_id === BRAND_ID.VISA
    ),
    "id"
  );

  return defaultAccountProfileAcquirerId || getTokenData("acquirer_id", -1);
};

export const getAccessTokenAppId = (state?: RootState): string =>
  getTokenData("app_id", null) as string;
export const isEmailVerificationRequired = (state: RootState) =>
  state.auth.emailVerificationRequired;

export const isDisplayIntPayReminderPopup = (state: RootState) =>
  state.auth.isDisplayIntPayReminderPopup;

export const getCurrentCurrencyCode = (state: RootState): string => {
  if (hasFetchedAccountProfile(state)) {
    return getCurrentAccountProfile(state).currencyCode;
  }

  return getCurrentCountry(state);
};

export const canAccessCryptoPayment = (state: RootState): boolean => {
  return getCurrentAccountProfile(state).canAccessCryptoPayment;
};

export const getCurrentUserId = (state?: RootState): string =>
  getTokenData("uid", null) as string;
