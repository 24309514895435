import React, { createRef } from "react";
import { isDesktop, BrowserView, MobileView } from "react-device-detect";
import classNames from "classnames";

import styles from "./SplashScreen.module.scss";

interface IProps {
  ready: boolean;
  disabled: boolean;
  children: any;
}

const SplashScreen = ({ ready, children, disabled }: IProps) => {
  const wrapperDiv = createRef<any>();

  // useEffect(() => {
  //   const img = document.getElementById("splash_screen_img");

  //   if (!img || disabled) {
  //     return;
  //   }

  //   wrapperDiv.current.append(img);

  //   return () => {
  //     const globalUI = document.getElementById("global-fetch-ui");

  //     if (img && globalUI && !disabled) {
  //       globalUI.append(img);
  //     }
  //   };
  // }, []);

  if (disabled) {
    return (
      <div className={styles.wrapper}>
        <div
          className={classNames(
            styles.dashboard,
            styles.showDashboardWithoutAnimation
          )}
        >
          <div className={styles.layoutInner}>{ready && children}</div>
        </div>
      </div>
    );
  }

  return (
    <div
      className={classNames(styles.wrapper, {
        [styles.wrapperDesktop]: isDesktop
      })}
    >
      <BrowserView>
        <div ref={wrapperDiv} className={styles.gifSplash} />
        <div
          className={`${styles.dashboard} ${
            ready
              ? styles.activeAnimation
              : ` ${styles.dashboardSplash} ${styles.activeAnimationReverse}`
          }`}
        >
          <div className={styles.layoutInner}>{ready && children}</div>
        </div>
      </BrowserView>
      <MobileView>
        <>
          <div ref={wrapperDiv} className={styles.gifSplash} />
          <div
            className={`${styles.dashboard} ${
              ready
                ? styles.activeAnimation
                : ` ${styles.dashboardSplash} ${styles.activeAnimationReverse}`
            }`}
          >
            <div className={styles.layoutInner}>{ready && children}</div>
          </div>
        </>
      </MobileView>
    </div>
  );
};

export default SplashScreen;
