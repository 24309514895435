import React, { useEffect, useState } from "react";

import SCHEDULED_TYPE from "src/ipm-shared/components/ScheduleType";

import Select from "src/bepaid/components/Form/controls/Select";
import styles from "./ScheduleForm.module.scss";
import OneTimeDateSelect from "./OneTimeDatesSelect";
import RecurringDateSelect from "./RecurringDateSelect";
import { SCHEDULE_FILTER_OPTIONS } from "src/bepaid/pages/Filter/const";

import { SCHEDULE_FREQUENCY } from "src/ipm-shared/store/model/Payment/const";
import { ADD_FORM } from "src/ipm-shared/store/model/Payment/const";

import _get from "lodash-es/get";
import FeatureGateUtil from "src/ipm-shared/components/FeatureGating/Util";

const scheduleTypes = [
  { label: "One-time", value: SCHEDULED_TYPE.OPTION_ONE_TIME },
  { label: "Recurring", value: SCHEDULED_TYPE.OPTION_RECURRING }
];

const SCHEDULE_FREQUENCY_OPTIONS = SCHEDULE_FILTER_OPTIONS.find(
  s => s.key === "FREQUENCY"
)?.children;

const frequencyTypes = SCHEDULE_FREQUENCY_OPTIONS?.map(s => ({
  label: s.name,
  value: s.key
}));

const View = (props: any) => {
  const {
    loading,
    handleSelectStartDate,
    handleSelectEndDate,
    chargeDate,
    chargeDateEnd,
    earliestDate,
    flashPayDate,
    instantPayDate,
    exclusionDates,
    pspNonWorkingDates,
    countryCode,
    getControl,
    setControl,
    features,
    defaultScheduleType,
    defaultRecurringStartDate,
    defaultRecurringEndDate,
    disableSelectNextYear
  } = props;

  const showFlashPay =
    !isNaN(flashPayDate) &&
    FeatureGateUtil.verifyFeature(features, "FLASH_PAYMENT");

  const showInstantPay =
    !isNaN(instantPayDate) &&
    FeatureGateUtil.verifyFeature(features, "INSTANT_PAYMENT");

  const [scheduledType, scheduleType] = useState(
    _get(getControl("schedule_type_draft", ADD_FORM), "value") ===
      SCHEDULED_TYPE.OPTION_RECURRING
      ? SCHEDULED_TYPE.OPTION_RECURRING
      : SCHEDULED_TYPE.OPTION_ONE_TIME
  );
  const [recurringFrequency, setRecurringFrequency] = useState<string>(
    _get(getControl("frequency_draft"), "value") || SCHEDULE_FREQUENCY.MONTHLY
  );

  const [oneTimeDate, setOneTimeDate] = useState<Date>();
  const [recurringDates, setRecurringDates] = useState({
    firstDate: _get(getControl("start_date", ADD_FORM), "value") || undefined,
    lastDate: _get(getControl("end_date", ADD_FORM), "value") || undefined
  });

  const selectOnetimeDate = (d: any) => {
    setOneTimeDate(d);
    handleSelectStartDate(d);
  };

  // This method called when user select a date on calendar
  const onSelectRecurringStartDate = (d: any) => {
    setRecurringDates({
      firstDate: d,
      lastDate: recurringDates.lastDate
    });

    handleSelectStartDate(d);
  };

  const onSelectRecurringEndDate = (d: any) => {
    setRecurringDates({
      firstDate: recurringDates.firstDate,
      lastDate: d
    });

    handleSelectEndDate(d);
  };

  const selectRecurringDate = (firstDate: any, lastDate: any) => {
    setRecurringDates({
      firstDate,
      lastDate
    });

    handleSelectStartDate(firstDate);
    handleSelectEndDate(lastDate);
  };

  const showFlashPayNote =
    (countryCode === "HK" || countryCode == "SG") &&
    showFlashPay &&
    scheduledType === SCHEDULED_TYPE.OPTION_ONE_TIME;

  const showInstantPayNote =
    countryCode == "SG" &&
    showInstantPay &&
    scheduledType === SCHEDULED_TYPE.OPTION_ONE_TIME;

  const commonDatePickerProps = {
    loading,
    chargeDate,
    chargeDateEnd,
    earliestDate,
    flashPayDate,
    instantPayDate,
    exclusionDates,
    pspNonWorkingDates,
    showFlashPay,
    showInstantPay
  };

  const flashPayInfo = {
    SG: {
      flashPayAmount: "+$25",
      flashPayAmountTooltip: "Select to upgrade for S$25",
      flashPayAmountTooltipDisable:
        "Only available for payments made before 11:00pm SGT"
    },
    HK: {
      flashPayAmount: "+HKD150",
      flashPayAmountTooltip: "Select to upgrade for HKD150",
      flashPayAmountTooltipDisable:
        "Only available for payments made before 11:00pm HKT"
    },
    AU: {
      flashPayAmount: "+A$25",
      flashPayAmountTooltip: "Select to upgrade for A$25",
      flashPayAmountTooltipDisable:
        "Only available for payments made before 6:30pm AEST"
    }
  }[countryCode];

  const instantPayInfo = {
    SG: {
      instantPayAmount: "+$40",
      instantPayAmountTooltip: "Select to upgrade for S$40",
      instantPayAmountTooltipDisable:
        "Only available for payments made on the same day from 12.01am - 1.00pm SGT"
    }
  }[countryCode];

  const pickersByType = {
    [SCHEDULED_TYPE.OPTION_ONE_TIME]: (
      <OneTimeDateSelect
        onSelectDate={selectOnetimeDate}
        value={oneTimeDate}
        isAUDeadline={countryCode === "AU"}
        flashPayInfo={flashPayInfo}
        showFlashPayNote={showFlashPayNote}
        instantPayInfo={instantPayInfo}
        showInstantPayNote={showInstantPayNote}
        countryCode={countryCode}
        {...commonDatePickerProps}
      />
    ),
    [SCHEDULED_TYPE.OPTION_RECURRING]: (
      <React.Fragment>
        <Select
          name={"frequency"}
          value={recurringFrequency}
          defaultValue={SCHEDULE_FREQUENCY.MONTHLY}
          label="Frequency"
          options={frequencyTypes}
          onChange={(frequency: string) => {
            setRecurringFrequency(frequency);
            setControl({
              name: "frequency_draft",
              errors: [],
              value: frequency
            });
          }}
        />
        <RecurringDateSelect
          value={recurringDates}
          onSelectRecurringStartDate={onSelectRecurringStartDate}
          onSelectRecurringEndDate={onSelectRecurringEndDate}
          selectDate={selectRecurringDate}
          label={"First payout date"}
          disableSelectNextYear={disableSelectNextYear}
          showHelperText={props.entity === "landlord"}
          countryCode={countryCode}
          {...commonDatePickerProps}
        />
      </React.Fragment>
    )
  };

  const pickers = pickersByType[scheduledType];

  useEffect(() => {
    setControl({
      name: "schedule_type",
      errors: [],
      value: scheduledType,
      form: ADD_FORM
    });
    setControl({
      name: "frequency",
      errors: [],
      value: recurringFrequency
    });
  }, []);

  useEffect(() => {
    if (defaultScheduleType) {
      scheduleType(defaultScheduleType);
      setControl({
        name: "schedule_type",
        errors: [],
        value: defaultScheduleType,
        form: ADD_FORM
      });
    }
  }, [defaultScheduleType]);

  useEffect(() => {
    if (defaultRecurringStartDate && defaultRecurringEndDate) {
      setTimeout(() => {
        selectRecurringDate(defaultRecurringStartDate, defaultRecurringEndDate);
      }, 400);
    }
  }, [defaultRecurringStartDate, defaultRecurringEndDate]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.selectContainer}>
        <Select
          value={scheduledType}
          name="schedule_type"
          label="Schedule type"
          options={scheduleTypes}
          onChange={(type: number) => {
            scheduleType(type);
            setControl({
              name: "schedule_type_draft",
              errors: [],
              value: type,
              form: ADD_FORM
            });
            if (type === SCHEDULED_TYPE.OPTION_ONE_TIME) {
              selectOnetimeDate(new Date());
            } else {
              if (defaultRecurringStartDate && defaultRecurringEndDate) {
                selectRecurringDate(
                  defaultRecurringStartDate,
                  defaultRecurringEndDate
                );
              }
            }
          }}
        />
        {pickers}
        {/* {showFlashPayNote && (
            <p className={styles.warningText}>
              {"Note: Flash Pay is only applicable to Visa and Mastercards."}
            </p>
          )} */}
      </div>
    </div>
  );
};

export default View;
