/* tslint:disable:object-literal-sort-keys */
import React, { useEffect, useMemo, useState } from "react";
import classNames from "classnames";
import { Redirect } from "react-router-dom";
import { BrowserView, MobileView } from "react-device-detect";
import _get from "lodash-es/get";
import _isEmpty from "lodash-es/isEmpty";
import * as queryString from "query-string";

import { history } from "src/ipm-shared/store";
import { ADD_FORM } from "src/ipm-shared/store/model/CollectedAccount/const";
import T from "src/ipm-shared/Utils/Intl";
import { FetchModalID } from "src/ipm-shared/components/GlobalUI/actions";
import ScrollUtil from "src/ipm-shared/Utils/Scroll";

import MainLayout from "src/bepaid/layouts/MainLayout";
import DesktopModalLayout from "src/bepaid/components/Modal/components/DesktopModalLayout";
import Button from "src/bepaid/components/Form/controls/Button";
import ROUTES from "src/bepaid/routes";
import Progress from "src/bepaid/components/Progress";
import { invokeMessage } from "src/bepaid/components/Message";

import CompanyInformation from "../CompanyInformation";
import ProfileInformation from "../ProfileInformation";
import BusinessInformation from "../BusinessInformation";
import BankDetails from "../BankDetails";
import AccountDetails from "../AccountDetails";
// import AdditionalData from "../AdditionalData";

import styles from "./KycContents.module.scss";

const KYCForm = ({
  step,
  stepsRender,
  stepIndex,
  isFirstLogin,
  checkboxConfirm,
  className
}: any) => {
  return (
    <div className={className}>
      {step.description && (
        <div className={styles.title}>
          <span>{step.description}</span>
        </div>
      )}

      {stepsRender.map((st: any, idx: number) => (
        <div key={st.key} hidden={idx !== stepIndex}>
          {/* {!isFirstLogin && <h4 className={styles.subTitle}>{st.title}</h4>} */}
          <st.component {...st.props} checkboxConfirm={checkboxConfirm} />
        </div>
      ))}
    </div>
  );
};

const KYCButtonSubmit = ({ onClickNext, isLoading, step }: any) => {
  return (
    <div className={styles.btnNext}>
      <Button
        type="primary"
        htmlType="submit"
        onClick={onClickNext}
        loading={isLoading}
        id={step.id}
      >
        {isLoading ? step.nextBtnTitleLoading : step.nextBtnTitle}
      </Button>
    </div>
  );
};

const KYCContents: React.FC<any> = props => {
  const isFirstLogin = +props.firstLogin === 1;
  const beforeFirstInvoice = {
    masterDescription:
      "Please share the information below with us and we’ll get your account up and running!",
    masterTitle: "Almost there",
    firstLoginDescription: () => {
      let description = T.transl("BANK_DETAILS_INSTRUCTIONS");
      switch (props.accountCountryCode) {
        case "SG":
          return (
            <>
              {description}
              {T.transl("BANK_DETAILS_INSTRUCTIONS_SG", {
                b: (text: any) => <span>{text}</span>
              })}
            </>
          );
        case "MY":
          return (
            <>
              {description}
              {T.transl("BANK_DETAILS_INSTRUCTIONS_MY", {
                b: (text: any) => <span>{text}</span>
              })}
            </>
          );
        case "HK":
          return (
            <>
              {description}
              {T.transl("BANK_DETAILS_INSTRUCTIONS_HK", {
                b: (text: any) => <span>{text}</span>
              })}
            </>
          );
        case "AU":
          return (
            <>
              {description}
              {T.transl("BANK_DETAILS_INSTRUCTIONS_AU", {
                b: (text: any) => <span>{text}</span>
              })}
            </>
          );
        default:
          return (
            <>
              {description}
              {T.transl("BANK_DETAILS_INSTRUCTIONS_SG", {
                b: (text: any) => <span>{text}</span>
              })}
            </>
          );
      }
    }
  };

  const nextBtnTitle = "Move forward";
  const nextBtnTitleLoading = "Moving forward...";

  const [isLoading, setLoading] = useState(false);
  const [isSkipLoading, setSkipLoading] = useState(false);
  const [stepIndex, setStepIndex] = useState(0);
  const [checkboxConfirm, setCheckboxConfirm] = useState({
    checkboxOther: false,
    checkboxOtherError: "",
    checkboxToS: false,
    checkboxToSError: ""
  });
  const [optionAccountDetail, setOptionAccountDetail] = React.useState({
    bPoba: !_isEmpty(
      _get(props.collectedAccount, "payeeData.kycDocuments.b_poba", [])
    )
      ? "Y"
      : "N",
    bStructuredChart: !_isEmpty(
      _get(
        props.collectedAccount,
        "payeeData.kycDocuments.b_structured_chart",
        []
      )
    )
      ? "Y"
      : "N",
    bLetterOfAuthorization: !_isEmpty(
      _get(
        props.collectedAccount,
        "payeeData.kycDocuments.b_letter_of_authorization",
        []
      )
    )
      ? "Y"
      : "N",
    bDirector: !_isEmpty(
      _get(props.collectedAccount, "payeeData.kycDocuments.b_director", [])
    )
      ? "Y"
      : "N",
    bShareholder: !_isEmpty(
      _get(props.collectedAccount, "payeeData.kycDocuments.b_shareholder", [])
    )
      ? "Y"
      : "N"
  });

  const onSetCheckboxConfirm = (data: any) => {
    setCheckboxConfirm(prev => ({
      ...prev,
      ...data
    }));
  };

  const onSetOptionAccountDetail = (option: string, value: string) => {
    setOptionAccountDetail(prev => ({
      ...prev,
      [option]: value
    }));
  };

  const shareProps = {
    accountProfile: props.accountProfile,
    collectedAccount: props.collectedAccount,
    accountCurrencyId: props.accountCurrencyId,
    isHongKongAccount: props.isHongKongAccount,
    accountCountryCode: props.accountCountryCode
  };

  const steps = useMemo(() => {
    let resultStep = [];
    if (!isFirstLogin) {
      resultStep = [
        {
          description:
            "Please provide some basic information about your business.",
          key: "business_information",
          nextBtnTitle,
          id: "ipaymy_kyc_move_forward",
          nextBtnTitleLoading,
          component: BusinessInformation,
          props: shareProps,
          title: "Get started",
          ...beforeFirstInvoice
        },
        {
          description:
            "Please provide the email address you would like all information regarding payments made to your account to be sent to.",
          key: "profile_information",
          nextBtnTitle,
          id: "ipaymy_kyc_move_forward",
          nextBtnTitleLoading,
          component: ProfileInformation,
          props: shareProps,
          title: "Get started",
          ...beforeFirstInvoice
        },
        {
          description: isFirstLogin
            ? beforeFirstInvoice.firstLoginDescription()
            : beforeFirstInvoice.masterDescription,
          key: "bank_details",
          nextBtnTitle: "Submit Fetch details",
          id: "ipaymy_kyc_submit_fetch_details",
          nextBtnTitleLoading: "Submitting Fetch details...",
          component: BankDetails,
          props: shareProps,
          title: "Bank details",
          ...beforeFirstInvoice
        }
      ];
    } else {
      resultStep = [
        {
          description: "Tell us something about you and your company.",
          key: "company_information",
          nextBtnTitle,
          nextBtnTitleLoading,
          id: "ipaymy_kyc_move_forward",
          component: CompanyInformation,
          props: shareProps,
          title: T.transl("GET_STARTED"),
          ...beforeFirstInvoice
        },
        {
          description: "Tell us a bit more about your company.",
          key: "company_details",
          nextBtnTitle: "Submit account details",
          nextBtnTitleLoading: "Submitting account details...",
          id: "ipaymy_kyc_submit_account_details",
          component: AccountDetails,
          props: {
            ...shareProps,
            setCheckboxConfirm: onSetCheckboxConfirm,
            setOptionAccountDetail: onSetOptionAccountDetail,
            optionAccountDetail: optionAccountDetail
          },
          title: T.transl("GET_STARTED"),
          ...beforeFirstInvoice
        }
      ];
    }
    return resultStep;
  }, []);

  const step = steps[stepIndex] || steps[0];
  const handleErrorForm = (err: any) => {
    const fields = _get(err, "fields", {});
    Object.keys(fields).forEach((key: string) => {
      let elm = document.querySelector(
        "input[name='" + key + "']"
      ) as HTMLElement;
      if (!elm) {
        elm = document.querySelector("#" + key) as HTMLElement;
      }
      const mainLayout = document.getElementById("kyc_wrapper_content");
      if (elm && mainLayout) {
        const topMain = mainLayout.getBoundingClientRect().top;
        const topElm = elm.getBoundingClientRect().top;
        ScrollUtil.scrollTopElement(
          mainLayout,
          mainLayout.scrollTop + topElm - topMain - 70
        );
      }
    });
  };
  const afterSave = (err: any, data: any) => {
    setLoading(false); // ok
    if (err) {
      handleErrorForm(err);
      return;
    }
    switch (stepIndex) {
      case 0:
        setStepIndex(stepIndex + 1);
        if (isFirstLogin) {
          props.fetchCompanies(true);
        } else {
          props.fetchAccountProfiles();
        }
        props.fetchMainCollectedAccount();
        break;
      case 1:
        if (isFirstLogin) {
          if (props.onSaved) {
            props.onSaved();
          }
          invokeMessage("success", "Account details submitted.");
          props.fetchMainCollectedAccount();
          props.fetchAccountProfiles();
          if (props.accountCountryCode == "SG") {
            props.fetchMyInfoSingPass(({ confirmed = false, info }: any) => {
              if (!confirmed) {
                history.push(
                  `${ROUTES.MY_INFO}?is_biz=n&redirect_url=${encodeURIComponent(
                    props.redirectUrl
                  )}`
                );
              } else {
                if (props.redirectUrl) {
                  history.push(props.redirectUrl);
                } else {
                  history.push(ROUTES.ROOT);
                }
              }
            });
          } else {
            history.push(ROUTES.ROOT);
          }
        } else {
          setStepIndex(stepIndex + 1);
        }
        break;
      case 2:
        if (props.onSaved) {
          props.onSaved();
        }
        if (!isFirstLogin) {
          invokeMessage("success", "Fetch details submitted.");
          props.fetchMainCollectedAccount(() => {
            if (props.redirectUrl) {
              history.push(props.redirectUrl);
            } else {
              history.push(ROUTES.ROOT);
            }
          });
          return;
        }
        break;
      default:
        setStepIndex(0);
    }
    const mainLayout = document.getElementById("kyc_wrapper_content");
    if (mainLayout) {
      if (mainLayout.scrollTop > 30) {
        ScrollUtil.scrollTopElement(mainLayout);
      }
    }
  };

  const onClickNext = () => {
    setLoading(true); // ok
    if (isFirstLogin && stepIndex === 0) {
      if (props.currentCompany) {
        props.editCompany((error: any) => {
          if (error === null) {
            props.createCollectedAccount(
              true,
              stepIndex + 1,
              afterSave,
              setLoading,
              isFirstLogin
            );
          } else {
            setLoading(false); // ok
          }
        });
      } else {
        props.addCompany((error: any) => {
          if (error === null) {
            props.createCollectedAccount(
              true,
              stepIndex + 1,
              afterSave,
              setLoading,
              isFirstLogin
            );
          }
        }, setLoading);
      }
    } else if (isFirstLogin && stepIndex === 1) {
      if (checkboxConfirm.checkboxOther) {
        setCheckboxConfirm((prevValues: any) => ({
          ...prevValues,
          checkboxOtherError: ""
        }));
        if (optionAccountDetail.bPoba === "N") {
          props.setControl({
            form: ADD_FORM,
            name: "b_poba",
            value: null
          });
        } else {
          const bPoba = _get(props.getControl("b_poba", ADD_FORM), "value");
          if (!bPoba) {
            let errors = {
              fields: {
                b_poba: ["ERROR_FIELD_REQUIRED"]
              },
              form: []
            };
            props.parseServerErrors(errors, ADD_FORM);
            handleErrorForm(errors);
            setLoading(false);
            return;
          }
        }
        if (optionAccountDetail.bStructuredChart === "N") {
          props.setControl({
            form: ADD_FORM,
            name: "b_structured_chart",
            value: null
          });
        } else {
          const bStructuredChart = _get(
            props.getControl("b_structured_chart", ADD_FORM),
            "value"
          );
          if (!bStructuredChart) {
            let errors = {
              fields: {
                b_structured_chart: ["ERROR_FIELD_REQUIRED"]
              },
              form: []
            };
            props.parseServerErrors(errors, ADD_FORM);
            handleErrorForm(errors);
            setLoading(false);
            return;
          }
        }
        if (optionAccountDetail.bLetterOfAuthorization === "N") {
          props.setControl({
            form: ADD_FORM,
            name: "b_letter_of_authorization",
            value: null
          });
        } else {
          const bLetterOfAuthorization = _get(
            props.getControl("b_letter_of_authorization", ADD_FORM),
            "value"
          );
          if (!bLetterOfAuthorization) {
            let errors = {
              fields: {
                b_letter_of_authorization: ["ERROR_FIELD_REQUIRED"]
              },
              form: []
            };
            props.parseServerErrors(errors, ADD_FORM);
            handleErrorForm(errors);
            setLoading(false);
            return;
          }

          const idVerification = _get(
            props.getControl("id_verification", ADD_FORM),
            "value"
          );
          if (!idVerification) {
            let errors = {
              fields: {
                id_verification: ["ERROR_FIELD_REQUIRED"]
              },
              form: []
            };
            props.parseServerErrors(errors, ADD_FORM);
            handleErrorForm(errors);
            setLoading(false);
            return;
          }
        }
        if (optionAccountDetail.bDirector === "N") {
          props.setControl({
            form: ADD_FORM,
            name: "b_director",
            value: null
          });
        } else {
          const bDirector = _get(
            props.getControl("b_director", ADD_FORM),
            "value"
          );
          if (!bDirector) {
            let errors = {
              fields: {
                b_director: ["ERROR_FIELD_REQUIRED"]
              },
              form: []
            };
            props.parseServerErrors(errors, ADD_FORM);
            handleErrorForm(errors);
            setLoading(false);
            return;
          }
        }
        if (optionAccountDetail.bShareholder === "N") {
          props.setControl({
            form: ADD_FORM,
            name: "b_shareholder",
            value: null
          });
        } else {
          const bShareholder = _get(
            props.getControl("b_shareholder", ADD_FORM),
            "value"
          );
          if (!bShareholder) {
            let errors = {
              fields: {
                b_shareholder: ["ERROR_FIELD_REQUIRED"]
              },
              form: []
            };
            props.parseServerErrors(errors, ADD_FORM);
            handleErrorForm(errors);
            setLoading(false);
            return;
          }
        }

        const bDigitalSignature = _get(
          props.getControl("b_digital_signature", ADD_FORM),
          "value"
        );
        if (!bDigitalSignature && props.isSingaporeAccount) {
          let errors = {
            fields: {
              b_digital_signature: ["ERROR_FIELD_REQUIRED"]
            },
            form: []
          };
          props.parseServerErrors(errors, ADD_FORM);
          handleErrorForm(errors);
          setLoading(false);
          return;
        }
        // draft, isFetch
        props.createCollectedAccount(
          true,
          stepIndex + 1,
          afterSave,
          setLoading,
          isFirstLogin
        );
      } else {
        setCheckboxConfirm((prevValues: any) => ({
          ...prevValues,
          checkboxOtherError: prevValues.checkboxOther
            ? ""
            : T.transl("ERROR_FIELD_REQUIRED")
        }));
        setLoading(false); // ok
      }
      return;
    } else if (!isFirstLogin && stepIndex === 2) {
      // draft, isFetch
      props.createCollectedAccount(
        false,
        null,
        afterSave,
        setLoading,
        isFirstLogin
      );
    } else {
      props.createCollectedAccount(
        true,
        stepIndex + 1,
        afterSave,
        setLoading,
        isFirstLogin
      );
    }
  };

  const onSkipped = (d: number) => {
    if (props.onSaved) {
      props.onSaved();
      setSkipLoading(false);
    }
    history.push("/");
  };

  const onClickMaybeLater = () => {
    if (stepIndex === 2) {
      if (props.currentCompany && stepIndex === 2) {
        props.editCompany(() => {
          onSkipped(1);
        });
      } else {
        props.addCompany(() => {
          onSkipped(2);
        });
      }
    } else {
      if (props.collectedAccount) {
        setSkipLoading(true);
        props.createCollectedAccount(
          true,
          null,
          () => {
            props.fetchMainCollectedAccount();
            onSkipped(3);
          },
          setLoading,
          isFirstLogin
        );
      } else {
        props.fetchMainCollectedAccount();
        onSkipped(5);
      }
    }

    props.changeUserSetting({
      enable: true,
      fetchAccountProfile: true,
      property: "kyc_first_time_popup",
      refreshDisable: true
    });
  };
  const toggleModal = () => {
    props.toggleModal(FetchModalID.KYC_MODAL);
    history.push(ROUTES.ROOT);
  };

  const goBack = () => {
    if (props.isSingaporeAccount) {
      const qs = queryString.parse(window.location.search);
      if (qs.back_url) {
        history.push(qs.back_url as string);
        return;
      }
    }
    if (stepIndex === 0) {
      toggleModal();
    } else {
      setStepIndex(prevIndex => {
        return prevIndex - 1;
      });
    }
  };

  const stepsRender = steps;

  useEffect(() => {
    if (props.currentCompany) {
      props.setControl({
        form: ADD_FORM,
        name: "account_id",
        value: props.currentCompany.accountId
      });
    }
  }, [props.currentCompany]);

  useEffect(() => {
    if (props.collectedAccount) {
      props.setControl({
        errors: [],
        form: ADD_FORM,
        name: `bank_documents`,
        value: _get(props.collectedAccount, "payeeData.bankDocuments", []).join(
          ","
        )
      });
    }
  }, [props.collectedAccount]);

  if (!props.accountProfile.email && stepIndex === 0) {
    return null;
  }

  if (props.collectedAccount) {
    if (!props.collectedAccount.draft) {
      return <Redirect to={ROUTES.ROOT} />;
    }
  }
  const renderButtonSubmit = () => {
    return (
      <KYCButtonSubmit
        {...{
          onClickNext,
          isLoading,
          step,
          onClickMaybeLater,
          isSkipLoading,
          collectedAccount: props.collectedAccount,
          isFirstLogin
        }}
      />
    );
  };
  const renderKYCForm = (extraProps: any) => {
    return (
      <KYCForm
        {...{
          step,
          stepsRender,
          stepIndex,
          isFirstLogin,
          checkboxConfirm
        }}
        {...extraProps}
      />
    );
  };
  var onBack: any = goBack;
  if (!isFirstLogin && props.isSingaporeAccount && stepIndex == 0) {
    onBack = undefined;
  }
  if (isFirstLogin && !props.isSingaporeAccount) {
    onBack = undefined;
  }
  return (
    <>
      <BrowserView>
        <DesktopModalLayout
          title={isFirstLogin ? step.title : step.masterTitle}
          maxWidth450={true}
          onBack={onBack}
          onClose={!isFirstLogin ? toggleModal : undefined}
          btnSubmit={renderButtonSubmit()}
        >
          <div className={classNames(styles.wrapper, styles.desktopWrapper)}>
            <div id="kyc_wrapper_content" className={styles.wrapperInner}>
              {renderKYCForm({ className: styles.formBody })}
            </div>
          </div>
        </DesktopModalLayout>
      </BrowserView>
      <MobileView>
        <MainLayout
          header={{
            title: isFirstLogin ? step.title : step.masterTitle,
            onBack: onBack,
            onClose: !isFirstLogin ? toggleModal : undefined,
            center: true,
            hideMenu: true,
            progress: <Progress colors={[]} value={0} />
          }}
          footer={renderButtonSubmit()}
        >
          <div id="kyc_wrapper_content" className={styles.wrapperInnerMobile}>
            {renderKYCForm({})}
          </div>
        </MainLayout>
      </MobileView>
    </>
  );
};

export default KYCContents;
