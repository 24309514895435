import React from "react";
import * as queryString from "query-string";

import { history } from "src/ipm-shared/store";
import { FetchModalID } from "src/ipm-shared/components/GlobalUI/actions";
import Typography from "antd/es/typography";

import ROUTES from "src/bepaid/routes";

import RetrievedIdentityBusiness from "./components/RetrievedIdentityBusiness";
import RetrievedIdentityPersonal from "./components/RetrievedIdentityPersonal";
import RetrieveBusinessDescription from "./components/RetrieveBusinessDescription";
import LoginIdentity from "./components/LoginIdentity";
import { MY_INFO_MODES, MY_INFO_RESULTS, MY_INFO_VALID } from "./const";
import OnboardingRequirements from "./components/OnboardingRequirements/View";

const MyInfo: React.FC<any> = props => {
  const { openModal, toggleModal, fetchMyInfoSingPass } = props;

  const [extraInfoState, setExtraInfoState] = React.useState({
    mode: MY_INFO_MODES.initial,
    redirectUrl: ROUTES.ROOT,
    isBiz: false,
    businessIndustry: ""
  });

  React.useEffect(() => {
    const qs = queryString.parse(window.location.search);
    const { is_biz }: any = qs;
    fetchMyInfoSingPass(({ confirmed = false, info }: any) => {
      if (!confirmed) {
        const { redirect_url }: any = qs;
        let { result, valid }: any = qs;
        if (info) {
          result = MY_INFO_RESULTS.success;
          valid = MY_INFO_VALID.success;
        }
        handleRedirectModal(result, valid, redirect_url, is_biz);
      } else {
        // change sg_myinfo variable and reload to root
        props.changeUserSetting({
          enable: false,
          modalID: "MY_INFO_VERIFICATION_MODAL",
          property: "sg_myinfo",
          refreshDisable: false
        });
      }
    }, is_biz === "y");
  }, []);

  React.useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    if (extraInfoState.businessIndustry) {
      urlParams.set("business_industry", extraInfoState.businessIndustry);
      history.replace({
        pathname: `${window.location.pathname}`,
        search: "?" + urlParams.toString()
      });
    } else {
      if (urlParams.get("business_industry")) {
        setExtraInfoState({
          ...extraInfoState,
          businessIndustry: urlParams.get("business_industry")!
        });
      }
    }
  }, [extraInfoState.businessIndustry]);

  const handleRedirectModal = (
    result: string,
    valid: string,
    redirectUrlParam: string,
    isBiz: string
  ) => {
    if (result !== MY_INFO_RESULTS.success) {
      if (isBiz === "y") {
        setExtraInfoState({
          ...extraInfoState,
          mode: MY_INFO_MODES.onboardingRequirements,
          isBiz: isBiz === "y"
        });
      } else {
        setExtraInfoState({
          ...extraInfoState,
          mode: MY_INFO_MODES.login,
          isBiz: isBiz === "y"
        });
      }
      openModal(FetchModalID.MY_INFO_VERIFICATION_MODAL);
    } else {
      if (valid === MY_INFO_VALID.success) {
        setExtraInfoState({
          ...extraInfoState,
          mode: MY_INFO_MODES.success,
          redirectUrl: redirectUrlParam,
          isBiz: isBiz === "y"
        });
        openModal(FetchModalID.MY_INFO_VERIFICATION_MODAL);
      } else if (valid === MY_INFO_VALID.failed) {
        setExtraInfoState({
          ...extraInfoState,
          mode: MY_INFO_MODES.login,
          isBiz: isBiz === "y"
        });
        openModal(FetchModalID.MY_INFO_VERIFICATION_MODAL);
        openModal(FetchModalID.MY_INFO_VERIFICATION_ERROR_MODAL, {
          message1:
            isBiz === "y"
              ? "We had some trouble retrieving your business details."
              : "We had some trouble retrieving your personal details.",
          message2: (
            <Typography.Text>
              Please sign in using that account or contact us at{" "}
              <Typography.Link href="mailto:support@ipaymy.com">
                support@ipaymy.com
              </Typography.Link>{" "}
              for further assistance.
            </Typography.Text>
          ),
          message3: null
        });
      }
    }
  };

  const handleToggleModal = () => {
    toggleModal(FetchModalID.MY_INFO_VERIFICATION_MODAL);

    // return to root page if modal is closed
    if (
      window.location.href.includes(ROUTES.MY_INFO) ||
      window.location.href.includes(ROUTES.KYC)
    ) {
      history.push(ROUTES.ROOT);
    }
  };
  if (
    extraInfoState.mode === MY_INFO_MODES.onboardingRequirements &&
    extraInfoState.isBiz
  ) {
    return (
      <OnboardingRequirements
        {...props}
        redirectUrl={extraInfoState.redirectUrl}
        onClose={handleToggleModal}
        extraInfoState={extraInfoState}
        setExtraInfoState={setExtraInfoState}
      />
    );
  }

  if (extraInfoState.mode === MY_INFO_MODES.success) {
    return extraInfoState.isBiz ? (
      <RetrievedIdentityBusiness
        {...props}
        redirectUrl={extraInfoState.redirectUrl}
        onClose={handleToggleModal}
        extraInfoState={extraInfoState}
        setExtraInfoState={setExtraInfoState}
      />
    ) : (
      <RetrievedIdentityPersonal
        {...props}
        redirectUrl={extraInfoState.redirectUrl}
        onClose={handleToggleModal}
      />
    );
  } else if (extraInfoState.mode === MY_INFO_MODES.retriveBusiness) {
    return (
      <RetrieveBusinessDescription
        {...props}
        redirectUrl={extraInfoState.redirectUrl}
        onClose={handleToggleModal}
        extraInfoState={extraInfoState}
        setExtraInfoState={setExtraInfoState}
      />
    );
  } else if (
    [MY_INFO_MODES.login, MY_INFO_MODES.loginBusiness].includes(
      extraInfoState.mode
    )
  ) {
    return (
      <LoginIdentity
        {...props}
        isBusinessAccount={extraInfoState.isBiz}
        onClose={handleToggleModal}
        extraInfoState={extraInfoState}
        setExtraInfoState={setExtraInfoState}
      />
    );
  }

  return null;
};

export default MyInfo;
