import React from "react";
import { BrowserView, MobileView } from "react-device-detect";
import _isEmpty from "lodash-es/isEmpty";
import classNames from "classnames";
import Typography from "antd/es/typography";

import Form from "src/bepaid/components/Form";
import Image from "src/bepaid/components/Image";
import Card from "src/bepaid/components/Card";
import DesktopModalLayout from "src/bepaid/components/Modal/components/DesktopModalLayout";
import MainLayout from "src/bepaid/layouts/MainLayout";

import SingPassLogo from "src/bepaid/assets/images/verify/singpass_logo_fullcolours-1.svg";
import SingPassBt from "src/bepaid/assets/images/verify/button_singpass.svg";

import SingPassLogoBusiness from "src/bepaid/assets/images/verify/myInfo_business_neutral.svg";
import SingPassBtBusiness from "src/bepaid/assets/images/verify/myInfo_business_button.svg";

import styles from "./LoginIdentity.module.scss";
import * as queryString from "query-string";
import Button from "src/bepaid/components/Form/controls/Button";
// import { FetchModalID } from "src/ipm-shared/components/GlobalUI/actions";
// import { MY_INFO_MODES } from "../../const";
import { history } from "src/ipm-shared/store";
import ROUTES from "src/bepaid/routes";

const FormComponent = (props: any) => {
  const { loginMyInfoSingPass, isBusinessAccount } = props;
  const qs = queryString.parse(window.location.search);
  const { is_biz }: any = qs;

  const login = (): void => {
    const { ...rest } = qs;
    const redirectUrl = `${window.location.href}${
      !_isEmpty(rest) ? "&" : "?"
    }result=1`;

    loginMyInfoSingPass(false, redirectUrl, isBusinessAccount);
  };

  const loginWithoutSingpass = (): void => {
    if (window.location.pathname !== "/kyc") {
      history.push(
        `${ROUTES.KYC}?back_url=${ROUTES.MY_INFO}${window.location.search}`
      );
    }
  };

  return (
    <Form
      name="my-info-login-identity-form"
      layout="vertical"
      requiredMark={false}
      className={styles.form}
    >
      <div className={styles.itemSection}>
        <Typography.Text className={styles.itemText}>
          {is_biz === "y"
            ? `By accessing Myinfo via Singpass, your personal and corporate data will be
            automatically retrieved and relevant fields will be pre-filled, making
            verifying your identity faster and more convenient.`
            : `By accessing Myinfo via Singpass, your personal data will be
            automatically retrieved and relevant fields will be pre-filled, making
            verifying your identity faster and more convenient.`}
        </Typography.Text>
        {isBusinessAccount ? (
          <div className={styles.businessSingpassImage}>
            <Image src={SingPassLogoBusiness} className={styles.image} />
          </div>
        ) : (
          <Card className={styles.singpassImage}>
            <Image src={SingPassLogo} className={styles.image} />
          </Card>
        )}
      </div>
      <Form.Item className={styles.buttons}>
        {/* <span>Retrieve Myinfo with singpass</span> */}
        <Image
          src={isBusinessAccount ? SingPassBtBusiness : SingPassBt}
          className={styles.button}
          alt="SingPassBt"
          onClick={login}
        />
        {isBusinessAccount && (
          <Button
            type="primary"
            htmlType="button"
            onClick={loginWithoutSingpass}
          >
            Proceed without singpass
          </Button>
        )}
      </Form.Item>
    </Form>
  );
};

const LoginIdentity: React.FC<any> = props => {
  const {
    onClose
    // extraInfoState, setExtraInfoState, openModal
  } = props;

  // const goBack = () => {
  //   setExtraInfoState({
  //     ...extraInfoState,
  //     mode: MY_INFO_MODES.login,
  //     isBiz: extraInfoState.isBiz
  //   });
  //   openModal(FetchModalID.MY_INFO_VERIFICATION_MODAL);
  // };

  // var onBack: any = goBack;

  return (
    <>
      <BrowserView>
        <DesktopModalLayout
          title={"Verify your identity"}
          // onBack={extraInfoState.isBiz && onBack}
          onClose={onClose}
        >
          <div className={classNames(styles.wrapper, styles.wrapperDesktop)}>
            <FormComponent {...props} />
          </div>
        </DesktopModalLayout>
      </BrowserView>
      <MobileView>
        <MainLayout
          header={{
            title: "Verify your identity",
            // onBack: extraInfoState.isBiz && onBack,
            onClose,
            hideMenu: true
          }}
        >
          <div className={styles.wrapper}>
            <FormComponent {...props} />
          </div>
        </MainLayout>
      </MobileView>
    </>
  );
};

export default LoginIdentity;
