import React, { useEffect, useState } from "react";
import BaseCardForm, { IProps } from "src/ipm-shared/components/CardForm";
import { CardFormVersion } from "src/ipm-shared/components/CardForm/type";
import Image from "src/bepaid/components/Image";
import IconArrowDown from "src/bepaid/assets/images/common/drop_down_arrow_grey.svg";
import LockIcon from "src/ipm-shared/images/lock-icon.svg";
import Select from "src/bepaid/components/Form/controls/Select";
import InputText from "src/bepaid/components/Form/controls/InputText";
import _omit from "lodash-es/omit";
import _get from "lodash-es/get";
import T from "src/ipm-shared/Utils/Intl";
import { BRAND_ID } from "src/ipm-shared/store/model/CollectedAccount/const";

import styles from "./styles.module.scss";
import { ACQUIRER_ID } from "src/ipm-shared/store/model/Card/const";

interface ICardFormProps extends IProps {
  loading?: boolean;
  isDisabledChinaUnionPay?: boolean;
  availableAcquirers?: [];
  isCheckout?: boolean;
  form?: string;
  isWorldpayMasterCard?: boolean;
  isProduction?: boolean;
  isWorldPayCard?: boolean;
  isInternational?: boolean;
  isWorldpayVisa?: boolean;
  allowAmexPayment?: boolean;
  disableVisaPayment?: boolean;
  isAustraliaAccount?: boolean;
  isMalaysiaAccount?: boolean;
}

export const CardForm = React.forwardRef((props: ICardFormProps, ref) => {
  const [brandOptions, setBrandOptions] = useState(
    props.availableAcquirers
      ? props.availableAcquirers.map((o: any, idx: number) => {
          return {
            ...o,
            label: o.label ? T.transl(o.label) : "",
            value: o.label
          };
        })
      : []
  );

  const [selectedAcquirerId, setSelectedAcquirerId] = useState(
    _get(props, "acquirerId", -1)
  );

  const [selectedCardBrand, setSelectedCardBrand] = useState<number>();

  const sharedProps = {
    ..._omit(props, ["acquirerId"])
  };

  const showSelectCardBrand = !(
    props.isAustraliaAccount || props.isMalaysiaAccount
  );

  useEffect(() => {
    if (props.isInternational) {
      var newBrandOptions = brandOptions.filter((option: any) => {
        if (option.is_international_payment === true) {
          return true;
        }
        return false;
      });
      setBrandOptions(newBrandOptions);
      return;
    }
    var newBrandOptions = brandOptions;
    if (!props.allowAmexPayment) {
      newBrandOptions = newBrandOptions.filter((option: any) => {
        if (option.card_brand_id === BRAND_ID.AMEX) {
          return false;
        }
        return true;
      });
    }
    if (props.disableVisaPayment) {
      newBrandOptions = newBrandOptions.filter((option: any) => {
        if (option.card_brand_id === BRAND_ID.VISA) {
          return false;
        }
        return true;
      });
    }
    if (props.isWorldPayCard === true) {
      newBrandOptions = newBrandOptions.filter((option: any) => {
        if (option.is_dynamic_mcc === true) {
          return true;
        }
        return false;
      });
      setBrandOptions(newBrandOptions);
    } else {
      if (props.isWorldpayVisa) {
        newBrandOptions = newBrandOptions.filter((option: any) => {
          let wpSgCard =
            option.id === ACQUIRER_ID.STRIPE_TEST ||
            option.id === ACQUIRER_ID.STRIPE_PROD;
          if (option.card_brand_id === BRAND_ID.VISA && wpSgCard) {
            if (option.name != "Worldpay") {
              return false;
            }
          }
          return true;
        });
      } else {
        newBrandOptions = newBrandOptions.filter((option: any) => {
          if (
            option.is_dynamic_mcc === true &&
            option.card_brand_id === BRAND_ID.VISA
          ) {
            return false;
          }
          return true;
        });
      }
      if (props.isWorldpayMasterCard) {
        newBrandOptions = newBrandOptions.filter((option: any) => {
          let wpSgCard =
            option.id === ACQUIRER_ID.STRIPE_TEST ||
            option.id === ACQUIRER_ID.STRIPE_PROD;
          if (option.card_brand_id === BRAND_ID.MASTER_CARD && wpSgCard) {
            if (option.name != "Worldpay") {
              return false;
            }
          }
          return true;
        });
      } else {
        newBrandOptions = newBrandOptions.filter((option: any) => {
          if (
            option.is_dynamic_mcc === true &&
            option.card_brand_id === BRAND_ID.MASTER_CARD
          ) {
            return false;
          }
          return true;
        });
      }
      setBrandOptions(newBrandOptions);
    }
    const acquirerId: number = _get(newBrandOptions, "[0].id", -1);
    const brandId: number = _get(newBrandOptions, "[0].card_brand_id", 0);

    setSelectedCardBrand(brandId);
    setSelectedAcquirerId(acquirerId);
  }, [
    props.isWorldpayMasterCard,
    props.isInternational,
    props.isWorldpayVisa,
    props.allowAmexPayment,
    props.disableVisaPayment
  ]);

  const handleSelectBrand = (value: string) => {
    setSelectedAcquirerId(-1);
    setTimeout(() => {
      const brandOption = brandOptions.find(
        (acquirer: any) => acquirer.value === value
      );
      const acquirerId: number = _get(brandOption, "id", -1);
      const brandId: number = _get(brandOption, "card_brand_id", 0);

      setSelectedCardBrand(brandId);
      setSelectedAcquirerId(acquirerId);
    }, 100);
  };

  const renderSelectCardBrand = () => {
    return (
      <>
        {brandOptions.length > 1 && (
          <Select
            name={"brand"}
            form={props.form}
            placeholder={"Select card brand"}
            formItemClassName={styles.selectCardBrand}
            label={
              <>
                <span>Card brand</span>
                <div className={styles.rightLabel}>
                  <Image src={LockIcon} width={10} />
                  <span>Secure Form</span>
                </div>
              </>
            }
            options={brandOptions}
            suffixIcon={<Image src={IconArrowDown} />}
            onChange={handleSelectBrand}
            defaultValue={_get(brandOptions, "0.value")}
          />
        )}

        <div hidden={true}>
          <InputText
            name={"acquirer_id"}
            form={props.form}
            defaultValue={selectedAcquirerId}
          />
        </div>
      </>
    );
  };

  const renderCardNickname = () => {
    return (
      <InputText
        name={"card_nick_name"}
        label="Card nickname"
        placeholder="Card nickname"
        form={props.form}
        maxLength={25}
      />
    );
  };

  const baseCardFormProps = {
    ref: ref as any,
    cardFormVersion: CardFormVersion.BEPAID,
    ...sharedProps
  };

  return (
    <div className={styles.wrapper}>
      {props.loading && <div className={styles.loading} />}
      {props.isCheckout ? (
        <BaseCardForm acquirerId={props.acquirerId} {...baseCardFormProps} />
      ) : (
        <BaseCardForm
          renderCardNickname={renderCardNickname}
          {...(showSelectCardBrand && {
            renderSelectCardBrand: renderSelectCardBrand,
            selectedCardBrand: selectedCardBrand
          })}
          acquirerId={selectedAcquirerId}
          // isAmex={isAmex}
          {...baseCardFormProps}
        />
      )}
    </div>
  );
});

export default CardForm;
