import React from "react";
import { BrowserView, MobileView, isDesktop } from "react-device-detect";
import _get from "lodash-es/get";
import _isEmpty from "lodash-es/isEmpty";
import classNames from "classnames";

import Form from "src/bepaid/components/Form";
import SelectBusinessIndustries from "src/ipm-shared/components/SelectBusiness/BusinessIndustries";
import SelectBusinessTypes from "src/ipm-shared/components/SelectBusiness/BusinessTypes";
import Select from "src/bepaid/components/Form/controls/Select";
import Radio from "antd/es/radio";
import Space from "antd/es/space";
import InputFile from "src/bepaid/components/Form/controls/InputFile";
import InputText from "src/bepaid/components/Form/controls/InputText";
import IconUpload from "src/bepaid/assets/images/common/icon_upload.svg";
import InputFileDropZone from "src/bepaid/components/Form/controls/InputFile/components/InputFileDropZone";
import SignaturePad from "src/bepaid/components/Form/controls/SignaturePad";

import Button from "src/bepaid/components/Form/controls/Button";
import DesktopModalLayout from "src/bepaid/components/Modal/components/DesktopModalLayout";
import MainLayout from "src/bepaid/layouts/MainLayout";

import T from "src/ipm-shared/Utils/Intl";
import { FetchModalID } from "src/ipm-shared/components/GlobalUI/actions";

import OtherStyles from "./RetrieveBusinessDescription.module.scss";
import FormStyles from "src/bepaid/components/Form/CommonForm.module.scss";

import { ADD_FORM } from "src/ipm-shared/store/model/CollectedAccount/const";

const styles = {
  ...FormStyles,
  ...OtherStyles
};
import { MY_INFO_MODES, MyInfoRetrievedIdentityForm } from "../../const";

const RetriveBusinessDescription: React.FC<any> = props => {
  const commonFormItemProps = {
    reserveValueOnUnmount: true,
    revertValueOnMount: true
  };

  const bDigitalSignatureOptions = {
    file: "file",
    handWritten: "hand_written"
  };
  const { onClose, extraInfoState, setExtraInfoState, openModal } = props;

  const isSG = props.isSingaporeAccount;

  const [loading, setLoading] = React.useState(false);

  const [valueOption, setValueOption] = React.useState({
    bPoba: _get(props.optionAccountDetail, "bPoba", "N"),
    bStructuredChart: _get(props.optionAccountDetail, "bStructuredChart", "N"),
    bLetterOfAuthorization: _get(
      props.optionAccountDetail,
      "bLetterOfAuthorization",
      "N"
    ),
    bDigitalSignature: _get(
      props.optionAccountDetail,
      "bDigitalSignature",
      bDigitalSignatureOptions.handWritten
    )
  });
  const onChangeOption = (option: string) => (e: any) => {
    setValueOption({
      ...valueOption,
      [option]: e.target.value
    });
  };

  const handleErrorForm = (err: any) => {
    const fields = _get(err, "fields", {});
    Object.keys(fields).forEach((key: string) => {
      let elm = document.querySelector(
        "input[name='" + key + "']"
      ) as HTMLElement;
      if (!elm) {
        elm = document.querySelector("#" + key) as HTMLElement;
        elm.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    });
  };

  const onSubmit = () => {
    const businessType = _get(
      props.getControl("business_type", MyInfoRetrievedIdentityForm),
      "value"
    );
    if (!businessType) {
      props.setControl({
        errors: [
          {
            code: "REQUIRED_FIELD",
            message: T.transl("REQUIRED_FIELD")
          }
        ],
        form: MyInfoRetrievedIdentityForm,
        name: "business_type"
      });
      return;
    }
    const businessDescription = _get(
      props.getControl("business_industry", MyInfoRetrievedIdentityForm),
      "value"
    );

    if (!businessDescription) {
      props.setControl({
        errors: [
          {
            code: "REQUIRED_FIELD",
            message: T.transl("REQUIRED_FIELD")
          }
        ],
        form: MyInfoRetrievedIdentityForm,
        name: "business_industry"
      });
      return;
    }

    if (valueOption.bPoba === "N") {
      props.setControl({
        form: MyInfoRetrievedIdentityForm,
        name: "b_poba",
        value: null
      });
    } else {
      const bPoba = _get(
        props.getControl("b_poba", MyInfoRetrievedIdentityForm),
        "value"
      );
      if (!bPoba) {
        let errors = {
          fields: {
            b_poba: ["ERROR_FIELD_REQUIRED"]
          },
          form: []
        };
        props.parseServerErrors(errors, MyInfoRetrievedIdentityForm);
        handleErrorForm(errors);
        setLoading(false);
        return;
      }
    }
    if (valueOption.bStructuredChart === "N") {
      props.setControl({
        form: MyInfoRetrievedIdentityForm,
        name: "b_structured_chart",
        value: null
      });
    } else {
      const bStructuredChart = _get(
        props.getControl("b_structured_chart", MyInfoRetrievedIdentityForm),
        "value"
      );
      if (!bStructuredChart) {
        let errors = {
          fields: {
            b_structured_chart: ["ERROR_FIELD_REQUIRED"]
          },
          form: []
        };
        props.parseServerErrors(errors, MyInfoRetrievedIdentityForm);
        handleErrorForm(errors);
        setLoading(false);
        return;
      }
    }
    if (valueOption.bLetterOfAuthorization === "N") {
      props.setControl({
        form: MyInfoRetrievedIdentityForm,
        name: "b_letter_of_authorization",
        value: null
      });
    } else {
      const bLetterOfAuthorization = _get(
        props.getControl(
          "b_letter_of_authorization",
          MyInfoRetrievedIdentityForm
        ),
        "value"
      );
      if (!bLetterOfAuthorization) {
        let errors = {
          fields: {
            b_letter_of_authorization: ["ERROR_FIELD_REQUIRED"]
          },
          form: []
        };
        props.parseServerErrors(errors, MyInfoRetrievedIdentityForm);
        handleErrorForm(errors);
        setLoading(false);
        return;
      }
    }
    if (extraInfoState.isHaveTwoShareholders) {
      const bShareholderId = _get(
        props.getControl("b_shareholder_id", ADD_FORM),
        "value"
      );
      if (!bShareholderId) {
        let errors = {
          fields: {
            b_shareholder_id: ["ERROR_FIELD_REQUIRED"]
          },
          form: []
        };
        props.parseServerErrors(errors, ADD_FORM);
        handleErrorForm(errors);
        setLoading(false);
        return;
      }
    }
    const bDigitalSignature = _get(
      props.getControl("b_digital_signature", ADD_FORM),
      "value"
    );
    if (!bDigitalSignature && isSG) {
      let errors = {
        fields: {
          b_digital_signature: ["ERROR_FIELD_REQUIRED"]
        },
        form: []
      };
      props.parseServerErrors(errors, ADD_FORM);
      handleErrorForm(errors);
      setLoading(false);
      return;
    }
    setLoading(true);
    extraInfoState.onSubmit?.();
    // setTimeout(() => {
    //   setLoading(false);

    //   setExtraInfoState({
    //     mode: MY_INFO_MODES.loginBusiness,
    //     isBiz: true,
    //     businessIndustry: businessDescriptionState
    //   });
    //   openModal(FetchModalID.MY_INFO_VERIFICATION_MODAL);
    // }, 500);
  };

  const renderContent = () => (
    <div className={styles.content}>
      <Form
        name={MyInfoRetrievedIdentityForm}
        layout="vertical"
        requiredMark={false}
        className={styles.personalFormDetail}
      >
        <div className={styles.formWrapper}>
          <InputText
            label="Company Website (if applicable)"
            placeholder="Company Website"
            form={MyInfoRetrievedIdentityForm}
            name="business_website"
            defaultValue={_get(props, "businessWebsite")}
            {...commonFormItemProps}
          />
          <SelectBusinessTypes
            // @ts-ignore
            SelectComponent={Select}
            name={"business_type"}
            label="Business type"
            form={MyInfoRetrievedIdentityForm}
            className={styles.select}
            {...commonFormItemProps}
          />
          <SelectBusinessIndustries
            // @ts-ignore
            SelectComponent={Select}
            name={"business_industry"}
            label="Business industry"
            form={MyInfoRetrievedIdentityForm}
            className={styles.select}
            {...commonFormItemProps}
          />
        </div>
      </Form>

      {isSG && (
        <Form
          layout="vertical"
          requiredMark={false}
          className={styles.personalFormDetail}
        >
          <p className={styles.label}>
            Is your principal place of business different from the address on
            your ACRA Bizfile?
          </p>
          <div className={styles.wrapperRadioGroup}>
            <Radio.Group
              onChange={onChangeOption("bPoba")}
              defaultValue={"N"}
              value={valueOption.bPoba}
            >
              <Space direction="horizontal">
                <Radio value={"Y"}>Yes</Radio>
                <Radio value={"N"}>No</Radio>
              </Space>
            </Radio.Group>
          </div>

          {valueOption.bPoba === "Y" && (
            <>
              <p
                className={classNames(
                  styles.text,
                  styles.purple,
                  styles.textDescription
                )}
              >
                Your proof of address needs to be dated within the last 3
                months.
              </p>
              <InputFile
                className="upload_bank_documents"
                allowedFileTypes={["jpg", "png", "heic", "pdf"]}
                name="b_poba"
                purpose="kyc"
                label="Upload proof of address"
                form={MyInfoRetrievedIdentityForm}
                maxFileAmount={1}
                helperText={{
                  showInEmptyMode: true,
                  txt: (
                    <span>
                      Maximum file size: <b>16 MB</b>. Supported file types:{" "}
                      <b>JPG, PDF, PNG</b>.
                    </span>
                  )
                }}
                {...commonFormItemProps}
              />
            </>
          )}
        </Form>
      )}

      {isSG && (
        <Form
          layout="vertical"
          requiredMark={false}
          className={styles.personalFormDetail}
        >
          <p className={styles.label}>
            Do you have at least one corporate shareholder with a stake of 25%
            or more in your business?
          </p>
          <div className={styles.wrapperRadioGroup}>
            <Radio.Group
              onChange={onChangeOption("bStructuredChart")}
              defaultValue={"N"}
              value={valueOption.bStructuredChart}
            >
              <Space direction="horizontal">
                <Radio value={"Y"}>Yes</Radio>
                <Radio value={"N"}>No</Radio>
              </Space>
            </Radio.Group>
          </div>

          {valueOption.bStructuredChart === "Y" && (
            <>
              <p
                className={classNames(
                  styles.text,
                  styles.purple,
                  styles.textDescription
                )}
              >
                Your ultimate beneficial ownership chart needs to be the latest
                version.
              </p>
              <InputFile
                className="upload_bank_documents"
                allowedFileTypes={["jpg", "png", "heic", "pdf"]}
                name="b_structured_chart"
                purpose="kyc"
                label="Upload ultimate beneficial ownership chart"
                form={MyInfoRetrievedIdentityForm}
                maxFileAmount={1}
                helperText={{
                  showInEmptyMode: true,
                  txt: (
                    <span>
                      Maximum file size: <b>16 MB</b>. Supported file types:{" "}
                      <b>JPG, PDF, PNG</b>.
                    </span>
                  )
                }}
                {...commonFormItemProps}
              />
            </>
          )}
        </Form>
      )}

      {isSG && (
        <Form
          layout="vertical"
          requiredMark={false}
          className={styles.personalFormDetail}
        >
          <p className={styles.label}>
            Are you a non-director/shareholder using ipaymy on behalf of your
            company?
          </p>
          <div className={styles.wrapperRadioGroup}>
            <Radio.Group
              onChange={onChangeOption("bLetterOfAuthorization")}
              defaultValue={"N"}
              value={valueOption.bLetterOfAuthorization}
            >
              <Space direction="horizontal">
                <Radio value={"Y"}>Yes</Radio>
                <Radio value={"N"}>No</Radio>
              </Space>
            </Radio.Group>
          </div>

          {valueOption.bLetterOfAuthorization === "Y" && (
            <>
              <p
                className={classNames(
                  styles.text,
                  styles.purple,
                  styles.textDescription
                )}
              >
                Your letter of authorisation needs to be the latest version.
              </p>
              <InputFile
                className="upload_bank_documents"
                allowedFileTypes={["jpg", "png", "heic", "pdf"]}
                name="b_letter_of_authorization"
                purpose="kyc"
                label="Upload letter of authorisation"
                form={MyInfoRetrievedIdentityForm}
                maxFileAmount={1}
                helperText={{
                  showInEmptyMode: true,
                  txt: (
                    <span>
                      Maximum file size: <b>16 MB</b>. Supported file types:{" "}
                      <b>JPG, PDF, PNG</b>.
                    </span>
                  )
                }}
                {...commonFormItemProps}
              />
            </>
          )}
        </Form>
      )}

      {isSG && extraInfoState.isHaveTwoShareholders && (
        <Form
          layout="vertical"
          requiredMark={false}
          className={styles.personalFormDetail}
        >
          <p className={styles.label}>
            Please provide 2 national IDs of the directors and the individual
            shareholders of the business.
          </p>
          <p
            className={classNames(
              styles.text,
              styles.purple,
              styles.textDescription
            )}
          >
            Please ensure the details on your ID must be clearly visible.
          </p>
          <InputFile
            allowedFileTypes={["jpg", "jpeg", "png", "heic"]}
            name={"b_shareholder_id"}
            purpose={"kyc"}
            form={ADD_FORM}
            label={"Upload your front ID photo"}
            icon={IconUpload}
            canDrag={true}
            multiple={true}
            renderComponent={InputFileDropZone}
            defaultValue={[]}
            minFileAmount={2}
            {...commonFormItemProps}
          />
        </Form>
      )}

      {isSG && (
        <div className={styles.wrapperRadioGroup}>
          <Radio.Group
            onChange={onChangeOption("bDigitalSignature")}
            defaultValue={bDigitalSignatureOptions.handWritten}
            value={valueOption.bDigitalSignature}
          >
            <Space direction="horizontal">
              <Radio value={bDigitalSignatureOptions.handWritten}>
                Handwritten signature
              </Radio>
              <Radio value={bDigitalSignatureOptions.file}>
                Upload digital signature
              </Radio>
            </Space>
          </Radio.Group>
        </div>
      )}
      {isSG &&
        (valueOption.bDigitalSignature === bDigitalSignatureOptions.file ? (
          <Form
            layout="vertical"
            requiredMark={false}
            className={styles.personalFormDetail}
          >
            <p className={styles.label}>
              Alternatively, please upload a clear and focus digital signature
              for record purpose.
            </p>
            <p
              className={classNames(
                styles.text,
                styles.purple,
                styles.textDescription
              )}
            >
              Please ensure the details on your digital signature must be
              clearly visible.
            </p>
            <InputFile
              allowedFileTypes={["jpg", "jpeg", "png", "heic"]}
              name={"b_digital_signature"}
              purpose={"kyc"}
              form={ADD_FORM}
              label={"Upload your front ID photo"}
              icon={IconUpload}
              canDrag={true}
              multiple={true}
              renderComponent={InputFileDropZone}
              defaultValue={[]}
            />
          </Form>
        ) : (
          <Form
            layout="vertical"
            requiredMark={false}
            className={styles.personalFormDetail}
          >
            <p className={styles.label}>
              Please sign below for record purpose.
            </p>
            <SignaturePad
              form={ADD_FORM}
              name={"b_digital_signature"}
              purpose={"kyc"}
            />
          </Form>
        ))}
    </div>
  );

  const renderButton = () => (
    <div className={styles.button}>
      <Button
        type="primary"
        htmlType="submit"
        loading={loading}
        onClick={onSubmit}
      >
        {loading ? "Verifying..." : "Verify"}
      </Button>
    </div>
  );

  const goBack = () => {
    setExtraInfoState({
      ...extraInfoState,
      mode: MY_INFO_MODES.success,
      isBiz: extraInfoState.isBiz
    });
    openModal(FetchModalID.MY_INFO_VERIFICATION_MODAL);
  };

  return (
    <>
      <BrowserView>
        <DesktopModalLayout
          title={"Verify your identity"}
          onClose={onClose}
          onBack={extraInfoState.isBiz && goBack}
          onSubmit={onSubmit}
          btnSubmit={renderButton()}
        >
          <div
            className={classNames(styles.wrapper, {
              [styles.wrapperDesktop]: isDesktop
            })}
          >
            {renderContent()}
          </div>
        </DesktopModalLayout>
      </BrowserView>
      <MobileView>
        <MainLayout
          header={{
            title: "Verify your identity",
            onBack: extraInfoState.isBiz && goBack,
            onClose,
            hideMenu: true
          }}
          footer={renderButton()}
        >
          <div className={styles.wrapper}>{renderContent()}</div>
        </MainLayout>
      </MobileView>
    </>
  );
};

export default RetriveBusinessDescription;
