import { getType } from "typesafe-actions";
import * as types from "./types";
import * as actions from "./actions";
import _isEmpty from "lodash-es/isEmpty";
import { PayeeType } from "../Payee/reducers";

export type PaymentRequestState = {
  readonly amountOff?: number;
  readonly channelFees: number;
  readonly isFetching: boolean;
  readonly currencyId: number;
  readonly isScheduled?: boolean;
  readonly payees: PayeeType[];
  readonly purposeId: number;
  readonly statementDescriptor: string;
  readonly subtotal: number;
  readonly licenseFee: number;
  readonly token: string;
  readonly total: number;
  readonly savings: number;
  readonly rateBeforeCoupon: number;
  readonly rateUsed: number;
  readonly couponUsed: string;
  readonly currentPurpose: number;
  readonly paidCard: number;
  readonly exchangeRate: number;
  readonly paymentAmount: number;
  readonly paymentCountryCode: string;
  readonly paymentCurrencyCode: string;
  readonly payeeQuoteToken?: string;
  readonly paymentMethodType: string;
  readonly paymentMethodId?: number;
  readonly flashPayFee?: number;
  readonly instantPayFee?: number;
  readonly isDeductedRate?: boolean;
  readonly gstFee?: number;
  readonly bankPayoutFee?: number;
  readonly minimumTransactionFee?: number;
};

const defaultState: PaymentRequestState = {
  channelFees: 0,
  couponUsed: "",
  currencyId: 0,
  currentPurpose: 0,
  exchangeRate: 0,
  isFetching: false,
  isScheduled: false,
  licenseFee: 0,
  paidCard: 0,
  payees: [],
  paymentAmount: 0,
  purposeId: 0,
  rateBeforeCoupon: 0,
  rateUsed: 0,
  savings: 0,
  statementDescriptor: "",
  subtotal: 0,
  token: "",
  total: 0,
  paymentCountryCode: "",
  paymentCurrencyCode: "",
  paymentMethodType: "",
  paymentMethodId: 0,
  payeeQuoteToken: "",
  flashPayFee: undefined,
  instantPayFee: undefined,
  isDeductedRate: undefined,
  gstFee: undefined,
  bankPayoutFee: undefined,
  minimumTransactionFee: undefined
};

export { defaultState };
export default (state: PaymentRequestState, action: types.Action) => {
  if (_isEmpty(state)) {
    state = defaultState;
  }

  switch (action.type) {
    case getType(actions.setPaymentRequest):
      return {
        ...state,
        ...action.payload
      };
    case getType(actions.setFees):
      return {
        ...state,
        amountOff: action.payload.amountOff,
        couponUsed: action.payload.coupon,
        exchangeRate: action.payload.exchangeRate,
        licenseFee: action.payload.fee,
        paymentAmount: action.payload.paymentAmount,
        rateBeforeCoupon: action.payload.rateBeforeCoupon,
        rateUsed: action.payload.rate,
        savings: action.payload.savings,
        total: action.payload.total,
        payeeQuoteToken: action.payload.payeeQuoteToken,
        flashPayFee: action.payload.flashPayFee,
        instantPayFee: action.payload.instantPayFee,
        isDeductedRate: action.payload.isDeductedRate,
        gstFee: action.payload.gstFee,
        bankPayoutFee: action.payload.bankPayoutFee,
        minimumTransactionFee: action.payload.minimumTransactionFee
      };

    case getType(actions.setCoupon):
      return {
        ...state,
        couponUsed: action.payload.coupon
      };

    case getType(actions.setCurrentPurpose):
      return {
        ...state,
        currentPurpose: action.payload.purpose
      };

    case getType(actions.setExchangeRate):
      return {
        ...state,
        exchangeRate: action.payload.exchangeRate
      };
    case getType(actions.setPaymentCountryCode):
      return {
        ...state,
        paymentCountryCode: action.payload.code
      };
    case getType(actions.setPaymentCurrencyCode):
      return {
        ...state,
        paymentCurrencyCode: action.payload.code
      };
    case getType(actions.setPaymentMethodType):
      return {
        ...state,
        paymentMethodType: action.payload.methodType
      };
    case getType(actions.setPaymentMethodId):
      return {
        ...state,
        paymentMethodId: action.payload.id
      };
  }
  return state;
};
