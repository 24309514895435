import store from "src/ipm-shared/store";
import * as commonSelectors from "src/ipm-shared/store/model/selectors";

const utils = {
  // Current blob size limit is around 500MB for browsers
  fetchResource: (args: {
    url: string;
    type?: "download" | "getUrl";
    filename?: string;
    cb?: (value: string | Blob) => void;
  }) => {
    if (!args.url) {
      return;
    }

    fetch(args.url, {
      headers: new Headers({
        authorization: `Bearer ${commonSelectors.getToken(store.getState())}`
      }),
      mode: "cors"
    })
      .then(response => response.blob())
      .then(blob => {
        const blobUrl = window.URL.createObjectURL(blob);
        if (args.filename && args.type === "download") {
          return utils.forceDownload(blobUrl, args.filename);
        }
        if (args.type === "getUrl") {
          return utils.openFile(blobUrl);
        }
        if (args.cb) {
          args.cb(blob);
        }
      })
      .catch(e => window.Logger.guestError(e));
  },

  downloadResource: (
    url: string,
    filename: string,
    cb?: (blob: any) => void
  ) => {
    utils.fetchResource({ url, type: "download", filename, cb });
  },

  getFileUrl: (url: string) => {
    utils.fetchResource({ url, type: "getUrl" });
  },

  forceDownload: (blob: any, filename: string) => {
    const a = document.createElement("a");
    a.download = filename;
    a.href = blob;
    a.click();
  },

  openFile: (blob: any) => {
    window.open(blob);
  },

  getInfoFromKey: (key: string) => {
    const parts = key.split("__");

    let name = "";
    if (parts.length >= 4) {
      name = atob(parts[3]);
    }

    let size = 0;
    if (parts.length >= 3) {
      size = parseInt(parts[2], 10);
    }

    // const timestamp = parseInt(parts[1], 10)
    // const fileDate = new Date(timestamp)

    return {
      key,
      name,
      size,
      url: `${process.env.ENDPOINT}/v2/files/${key}`
    };
  },

  getInfoFromKeys: (keys: string[]) => {
    return keys.map(key => utils.getInfoFromKey(key));
  },

  getListDocument: (list: string[]) => {
    if (!list || list.length === 0) {
      return [];
    }

    return list.map(key => utils.getInfoFromKey(key));
  },

  generateUrl: (key: string) => {
    return `${process.env.ENDPOINT}/v2/files/${key}`;
  },

  convertToArray: (value: string) => {
    if (!value) {
      return [];
    }
    return [value];
  },

  convertBase64ToFile: (base64String: string, fileName: string) => {
    const arr = base64String.split(",");
    const mime = arr[0].match(/:(.*?);/)?.[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], fileName, { type: mime });
  }
};

export default utils;
