import React from "react";
import classNames from "classnames";
import _get from "lodash-es/get";
import _isEmpty from "lodash-es/isEmpty";
import Radio from "antd/es/radio";
import Space from "antd/es/space";
import Checkbox from "antd/es/checkbox";

import { ADD_FORM } from "src/ipm-shared/store/model/CollectedAccount/const";
import FileUtils from "src/ipm-shared/Utils/Files";

import Form from "src/bepaid/components/Form";
import InputFile from "src/bepaid/components/Form/controls/InputFile";
import InputFileDropZone from "src/bepaid/components/Form/controls/InputFile/components/InputFileDropZone";
import IconUpload from "src/bepaid/assets/images/common/icon_upload.svg";
import FormStyles from "src/bepaid/components/Form/CommonForm.module.scss";

import OtherStyles from "./AccountDetails.module.scss";
import { COUNTRY_CODES } from "src/bepaid/layouts/Menu/components/Companies/const";
import SignaturePad from "src/bepaid/components/Form/controls/SignaturePad";

const styles = {
  ...FormStyles,
  ...OtherStyles
};
const AccountDetailsForm = (props: any) => {
  const commonFormItemProps = {
    reserveValueOnUnmount: true,
    revertValueOnMount: true
  };
  const bDigitalSignatureOptions = {
    file: "file",
    handWritten: "hand_written"
  };

  const data = props.collectedAccount;
  const [valueOption, setValueOption] = React.useState({
    bPoba: _get(props.optionAccountDetail, "bPoba", "N"),
    bStructuredChart: _get(props.optionAccountDetail, "bStructuredChart", "N"),
    bLetterOfAuthorization: _get(
      props.optionAccountDetail,
      "bLetterOfAuthorization",
      "N"
    ),
    bDirector: _get(props.optionAccountDetail, "bDirector", "N"),
    bShareholder: _get(props.optionAccountDetail, "bShareholder", "N"),
    bDigitalSignature: _get(
      props.optionAccountDetail,
      "bDigitalSignature",
      bDigitalSignatureOptions.handWritten
    )
  });
  const onChangeOption = (option: string) => (e: any) => {
    props.setOptionAccountDetail(option, e.target.value);
    setValueOption({
      ...valueOption,
      [option]: e.target.value
    });
  };
  const onChangeCheckBox = (e: any) => {
    props.setCheckboxConfirm({
      [e.target.name]: e.target.checked
    });
  };

  const isSG = props.accountCountryCode === "SG";

  const objByCountry = {
    [COUNTRY_CODES.SINGAPORE]: {
      documentName: "ACRA Bizfile",
      documentRequired:
        "Your ACRA Bizfile needs to be dated within the last 3 months.",
      documentUploadLabel: "Upload ACRA Bizfile",
      governmentRegistration: "ACRA Bizfile"
    },
    [COUNTRY_CODES.HONGKONG]: {
      documentName: "Official Annual Return document (Form NAR1)",
      documentRequired:
        "Your document needs to be dated within the last 3 months.",
      documentUploadLabel: "Upload document",
      documentSampleUrl:
        "https://s3.ap-southeast-1.amazonaws.com/ipaymy.com/docs/HK+Annual+Return.pdf",
      governmentRegistration: "government registration"
    },
    [COUNTRY_CODES.MALAYSIA]: {
      documentName: "Official Company Profile document",
      documentRequired:
        "Your document needs to be dated within the last 3 months.",
      documentUploadLabel: "Upload document",
      documentSampleUrl:
        "https://s3.ap-southeast-1.amazonaws.com/ipaymy.com/docs/MY+Company+Profile.pdf",
      governmentRegistration: "government registration"
    },
    [COUNTRY_CODES.AUSTRALIA]: {
      documentName: "Official Current Company Extract document",
      documentRequired:
        "Your document needs to be dated within the last 3 months.",
      documentUploadLabel: "Upload document",
      documentSampleUrl:
        "https://s3.ap-southeast-1.amazonaws.com/ipaymy.com/docs/AU+Current+Company+Extract.pdf",
      governmentRegistration: "government registration"
    }
  };
  return (
    <div className={styles.content}>
      <Form
        layout="vertical"
        requiredMark={false}
        className={styles.personalFormDetail}
      >
        <p className={styles.label}>
          {objByCountry[props.accountCountryCode].documentName}
        </p>
        <p
          className={classNames(
            styles.text,
            styles.purple,
            styles.textDescription
          )}
        >
          {objByCountry[props.accountCountryCode].documentRequired}
        </p>
        <InputFile
          className="upload_bank_documents"
          defaultValue={
            _get(data, "payeeData.kycDocuments.b_acra_bizfile", [])
              ? FileUtils.getInfoFromKeys(
                  _get(data, "payeeData.kycDocuments.b_acra_bizfile", [])
                )
              : []
          }
          allowedFileTypes={["jpg", "png", "heic", "pdf"]}
          name="b_acra_bizfile"
          purpose="kyc"
          label={objByCountry[props.accountCountryCode].documentUploadLabel}
          form={ADD_FORM}
          maxFileAmount={1}
          helperText={{
            showInEmptyMode: true,
            txt: (
              <>
                <div className={classNames(styles.documentHelperText)}>
                  Maximum file size: <b>16 MB</b>. Supported file types:{" "}
                  <b>JPG, PDF, PNG</b>.
                </div>
                {!isSG && (
                  <div>
                    Please refer{" "}
                    <a
                      href={
                        objByCountry[props.accountCountryCode].documentSampleUrl
                      }
                      target="_blank"
                    >
                      here
                    </a>{" "}
                    for a sample reference of the document requested.
                  </div>
                )}
              </>
            )
          }}
          {...commonFormItemProps}
        />
      </Form>
      <Form
        layout="vertical"
        requiredMark={false}
        className={styles.personalFormDetail}
      >
        <p className={styles.label}>
          Is your principal place of business different from the address on your{" "}
          {objByCountry[props.accountCountryCode].governmentRegistration}?
        </p>
        <div className={styles.wrapperRadioGroup}>
          <Radio.Group
            onChange={onChangeOption("bPoba")}
            defaultValue={"N"}
            value={valueOption.bPoba}
          >
            <Space direction="horizontal">
              <Radio value={"Y"}>Yes</Radio>
              <Radio value={"N"}>No</Radio>
            </Space>
          </Radio.Group>
        </div>

        {valueOption.bPoba === "Y" && (
          <>
            <p
              className={classNames(
                styles.text,
                styles.purple,
                styles.textDescription
              )}
            >
              Your proof of address needs to be dated within the last 3 months.
            </p>
            <InputFile
              className="upload_bank_documents"
              defaultValue={
                _get(data, "payeeData.kycDocuments.b_poba", [])
                  ? FileUtils.getInfoFromKeys(
                      _get(data, "payeeData.kycDocuments.b_poba", [])
                    )
                  : []
              }
              allowedFileTypes={["jpg", "png", "heic", "pdf"]}
              name="b_poba"
              purpose="kyc"
              label="Upload proof of address"
              form={ADD_FORM}
              maxFileAmount={1}
              helperText={{
                showInEmptyMode: true,
                txt: (
                  <span>
                    Maximum file size: <b>16 MB</b>. Supported file types:{" "}
                    <b>JPG, PDF, PNG</b>.
                  </span>
                )
              }}
              {...commonFormItemProps}
            />
          </>
        )}
      </Form>

      {isSG && (
        <Form
          layout="vertical"
          requiredMark={false}
          className={styles.personalFormDetail}
        >
          <p className={styles.label}>
            Do you have more than 2 directors in your business?
          </p>
          <div className={styles.wrapperRadioGroup}>
            <Radio.Group
              onChange={onChangeOption("bDirector")}
              defaultValue={"N"}
              value={valueOption.bDirector}
            >
              <Space direction="horizontal">
                <Radio value={"Y"}>Yes</Radio>
                <Radio value={"N"}>No</Radio>
              </Space>
            </Radio.Group>
          </div>

          {valueOption.bDirector === "Y" && (
            <>
              <p
                className={classNames(
                  styles.text,
                  styles.purple,
                  styles.textSm,
                  styles.textDescription
                )}
              >
                Please provide the National IDs for any 2 directors, including
                yourself if you are one of the directors using the account.
              </p>
              <InputFile
                className="upload_bank_documents"
                defaultValue={
                  _get(data, "payeeData.kycDocuments.b_director", [])
                    ? FileUtils.getInfoFromKeys(
                        _get(data, "payeeData.kycDocuments.b_director", [])
                      )
                    : []
                }
                allowedFileTypes={["jpg", "png", "heic", "pdf"]}
                name="b_director"
                purpose="kyc"
                label="Upload the National IDs for any 2 directors."
                form={ADD_FORM}
                canDrag={true}
                multiple={true}
                maxFileAmount={2}
                renderComponent={InputFileDropZone}
                {...commonFormItemProps}
              />
            </>
          )}
        </Form>
      )}

      {isSG && (
        <Form
          layout="vertical"
          requiredMark={false}
          className={styles.personalFormDetail}
        >
          <p className={styles.label}>
            Do you have any individual shareholders with a stake of 25% or more
            in your business?
          </p>
          <div className={styles.wrapperRadioGroup}>
            <Radio.Group
              onChange={onChangeOption("bShareholder")}
              defaultValue={"N"}
              value={valueOption.bShareholder}
            >
              <Space direction="horizontal">
                <Radio value={"Y"}>Yes</Radio>
                <Radio value={"N"}>No</Radio>
              </Space>
            </Radio.Group>
          </div>

          {valueOption.bShareholder === "Y" && (
            <>
              <p
                className={classNames(
                  styles.text,
                  styles.purple,
                  styles.textSm,
                  styles.textDescription
                )}
              >
                Please provide the National IDs for the individual shareholders,
                including yourself if you are one of the shareholders using the
                account.
              </p>
              <InputFile
                className="upload_bank_documents"
                btnClassName={styles.uploadBtn}
                defaultValue={
                  _get(data, "payeeData.kycDocuments.b_shareholder", [])
                    ? FileUtils.getInfoFromKeys(
                        _get(data, "payeeData.kycDocuments.b_shareholder", [])
                      )
                    : []
                }
                allowedFileTypes={["jpg", "png", "heic", "pdf"]}
                name="b_shareholder"
                purpose="kyc"
                label="Upload the National IDs for all individual shareholders"
                form={ADD_FORM}
                canDrag={true}
                multiple={true}
                maxFileAmount={2}
                renderComponent={InputFileDropZone}
                {...commonFormItemProps}
              />
            </>
          )}
        </Form>
      )}

      <Form
        layout="vertical"
        requiredMark={false}
        className={styles.personalFormDetail}
      >
        <p className={styles.label}>
          Do you have at least one corporate shareholder with a stake of 25% or
          more in your business?
        </p>
        <div className={styles.wrapperRadioGroup}>
          <Radio.Group
            onChange={onChangeOption("bStructuredChart")}
            defaultValue={"N"}
            value={valueOption.bStructuredChart}
          >
            <Space direction="horizontal">
              <Radio value={"Y"}>Yes</Radio>
              <Radio value={"N"}>No</Radio>
            </Space>
          </Radio.Group>
        </div>

        {valueOption.bStructuredChart === "Y" && (
          <>
            <p
              className={classNames(
                styles.text,
                styles.purple,
                styles.textDescription
              )}
            >
              Your ultimate beneficial ownership chart needs to be the latest
              version.
            </p>
            <InputFile
              className="upload_bank_documents"
              defaultValue={
                _get(data, "payeeData.kycDocuments.b_structured_chart", [])
                  ? FileUtils.getInfoFromKeys(
                      _get(
                        data,
                        "payeeData.kycDocuments.b_structured_chart",
                        []
                      )
                    )
                  : []
              }
              allowedFileTypes={["jpg", "png", "heic", "pdf"]}
              name="b_structured_chart"
              purpose="kyc"
              label="Upload ultimate beneficial ownership chart"
              form={ADD_FORM}
              maxFileAmount={1}
              helperText={{
                showInEmptyMode: true,
                txt: (
                  <span>
                    Maximum file size: <b>16 MB</b>. Supported file types:{" "}
                    <b>JPG, PDF, PNG</b>.
                  </span>
                )
              }}
              {...commonFormItemProps}
            />
          </>
        )}
      </Form>

      <Form
        layout="vertical"
        requiredMark={false}
        className={styles.personalFormDetail}
      >
        <p className={styles.label}>
          Are you a non-director/shareholder using ipaymy on behalf of your
          company?
        </p>
        <div className={styles.wrapperRadioGroup}>
          <Radio.Group
            onChange={onChangeOption("bLetterOfAuthorization")}
            defaultValue={"N"}
            value={valueOption.bLetterOfAuthorization}
          >
            <Space direction="horizontal">
              <Radio value={"Y"}>Yes</Radio>
              <Radio value={"N"}>No</Radio>
            </Space>
          </Radio.Group>
        </div>

        {valueOption.bLetterOfAuthorization === "Y" && (
          <>
            <p
              className={classNames(
                styles.text,
                styles.purple,
                styles.textDescription
              )}
            >
              Your letter of authorisation needs to be the latest version.
            </p>
            <InputFile
              className="upload_bank_documents"
              defaultValue={
                _get(
                  data,
                  "payeeData.kycDocuments.b_letter_of_authorization",
                  []
                )
                  ? FileUtils.getInfoFromKeys(
                      _get(
                        data,
                        "payeeData.kycDocuments.b_letter_of_authorization",
                        []
                      )
                    )
                  : []
              }
              allowedFileTypes={["jpg", "png", "heic", "pdf"]}
              name="b_letter_of_authorization"
              purpose="kyc"
              label="Upload letter of authorisation"
              form={ADD_FORM}
              maxFileAmount={1}
              helperText={{
                showInEmptyMode: true,
                txt: (
                  <span>
                    Maximum file size: <b>16 MB</b>. Supported file types:{" "}
                    <b>JPG, PDF, PNG</b>.
                  </span>
                )
              }}
              {...commonFormItemProps}
            />
          </>
        )}
      </Form>

      {valueOption.bLetterOfAuthorization === "Y" && (
        <Form
          layout="vertical"
          requiredMark={false}
          className={styles.personalFormDetail}
        >
          <p className={styles.label}>
            Please upload a clear and in focus photo of the front of your valid
            ID (National ID/Passport/Driver’s License.)
          </p>
          <p
            className={classNames(
              styles.text,
              styles.purple,
              styles.textDescription
            )}
          >
            Please ensure the details on your ID must be clearly visible.
          </p>
          <InputFile
            allowedFileTypes={["jpg", "jpeg", "png", "heic"]}
            name={"id_verification"}
            purpose={"id_verification"}
            form={ADD_FORM}
            label={"Upload your front ID photo"}
            icon={IconUpload}
            canDrag={true}
            multiple={true}
            renderComponent={InputFileDropZone}
            defaultValue={[]}
          />
        </Form>
      )}
      {isSG && (
        <div className={styles.wrapperRadioGroup}>
          <Radio.Group
            onChange={onChangeOption("bDigitalSignature")}
            defaultValue={bDigitalSignatureOptions.handWritten}
            value={valueOption.bDigitalSignature}
          >
            <Space direction="horizontal">
              <Radio value={bDigitalSignatureOptions.handWritten}>
                Handwritten signature
              </Radio>
              <Radio value={bDigitalSignatureOptions.file}>
                Upload digital signature
              </Radio>
            </Space>
          </Radio.Group>
        </div>
      )}
      {isSG &&
        (valueOption.bDigitalSignature === bDigitalSignatureOptions.file ? (
          <Form
            layout="vertical"
            requiredMark={false}
            className={styles.personalFormDetail}
          >
            <p className={styles.label}>
              Alternatively, please upload a clear and focus digital signature
              for record purpose.
            </p>
            <p
              className={classNames(
                styles.text,
                styles.purple,
                styles.textDescription
              )}
            >
              Please ensure the details on your digital signature must be
              clearly visible.
            </p>
            <InputFile
              allowedFileTypes={["jpg", "jpeg", "png", "heic"]}
              name={"b_digital_signature"}
              purpose={"kyc"}
              form={ADD_FORM}
              label={"Upload your front ID photo"}
              icon={IconUpload}
              canDrag={true}
              multiple={true}
              renderComponent={InputFileDropZone}
              defaultValue={[]}
            />
          </Form>
        ) : (
          <Form
            layout="vertical"
            requiredMark={false}
            className={styles.personalFormDetail}
          >
            <p className={styles.label}>
              Please sign below for record purpose.
            </p>
            <SignaturePad
              form={ADD_FORM}
              name={"b_digital_signature"}
              purpose={"kyc"}
            />
          </Form>
        ))}
      <Checkbox
        className={styles.checkbox}
        name={"checkboxOther"}
        onChange={onChangeCheckBox}
      >
        By registering with us, you are confirming that you will not use ipaymy
        or our Services to accept payments in connection with any{" "}
        <a
          className={styles.restrictedText}
          href="https://help.ipaymy.com/en/articles/3268073-businesses-restricted-from-payment-collection"
          target="_blank"
        >
          "Restricted Businesses"
        </a>{" "}
        or for any illegal activities.
      </Checkbox>
      {props.checkboxConfirm.checkboxOtherError && (
        <p className={styles.checkboxError}>
          {props.checkboxConfirm.checkboxOtherError}
        </p>
      )}
    </div>
  );
};
export default AccountDetailsForm;
