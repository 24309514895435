import React from "react";
import * as uuidv1 from "uuid/v1";
import classNames from "classnames";
import _get from "lodash-es/get";
import classnames from "classnames";
import Typography from "antd/es/typography";

import ShortCardInfo from "src/ipm-shared/components/ShortCardInfo";
import ShortCurrency from "src/ipm-shared/components/ShortCurrency";
import DateUtils from "src/ipm-shared/Utils/Date";
import Image from "src/bepaid/components/Image";
import paymentUtil from "./utils/payment";
import CurrencyUtil from "src/ipm-shared/Utils/Currency";
import PaymentUtil from "src/ipm-shared/Utils/Payment";
import { PaymentStatusPill } from "../PaymentStatusPill";
import { RecipientDetail } from "./RecipientDetail";
import { useProcessPaymentDetail } from "./useProcessPaymentDetail";
import CountryUtil from "src/ipm-shared/Utils/Country";
import { FLAGS } from "src/ipm-shared/Utils/Country";
import IconExchangePurple from "src/bepaid/assets/images/payment-activity/icon_exchange_purple.svg";

import styles from "./PaymentDetail.module.scss";
import ImageUtils from "src/ipm-shared/Utils/Images";

const ContentExpanded = ({ selectedPayment, hasWallexPayment }: any) => {
  const {
    // feeTotal,
    payees,
    paymentStatus,
    refundedFee,
    refundedGSTFee,
    refundedFlashPayFee,
    refundedBankPayoutFee,
    refundedMinimumTransactionFee,
    refundedAmount,
    countryId,
    exchangeRate,
    subtotal,
    // paymentTotal,
    SGDSubtotal,
    SGDFee,
    SGDPaymentTotal,
    payeesAmount,
    bankFee,
    isInternationalPayment,
    isCryptoPaymentMethod,
    paymentMethodDetails,
    cryptoPaymentTotal,
    cryptoExchangeRate,
    flashPayFee,
    instantPayFee,
    isDeductedRate,
    gstFee,
    bankPayoutFee,
    minimumTransactionFee
  } = useProcessPaymentDetail(selectedPayment);

  const currencyId = _get(selectedPayment, "currencyId");
  const feeRate = _get(selectedPayment, "feeRate", 0);
  const couponCode = _get(selectedPayment, "couponCode");
  const receiptNumber = _get(selectedPayment, "receiptNumber");
  const cardId = _get(selectedPayment, "cards.0.id", 0);
  const scheduledChargeDate = _get(selectedPayment, "scheduledChargeDate");
  const payoutDate = _get(selectedPayment, "payoutDate");

  const isRefund = refundedFee > 0 || refundedAmount > 0;

  const intCurrencyId = _get(selectedPayment, "payees[0].currencyId");

  return (
    <>
      <div className={classNames(styles.row, styles.firstRow)}>
        <div className={styles.title}>
          <span>{paymentUtil.getPaymentPurpose(selectedPayment)}</span>
          {hasWallexPayment && (
            <div className={styles.flag}>
              <Image
                src={FLAGS[CountryUtil.getCountryCodeFromCountryId(countryId)]}
              />
            </div>
          )}
        </div>
        <PaymentStatusPill status={paymentStatus} />
      </div>
      {isInternationalPayment && (
        <div className={classNames(styles.row, styles.verticalRow)}>
          {payees.map((p: any) => (
            <div
              key={uuidv1()}
              className={classNames(
                styles.row,
                styles.collapsedSectionRecipientName
              )}
            >
              <Typography.Text strong={true} ellipsis={true}>
                {isInternationalPayment &&
                selectedPayment.purposeId == 5 &&
                p.international.bankAccountHolderName
                  ? p.international.bankAccountHolderName
                  : PaymentUtil.getInsurancePayeeName(p.name)}
              </Typography.Text>
              <span>
                <ShortCurrency
                  value={p.currentAmount}
                  currencyId={p.currencyId}
                />
              </span>
            </div>
          ))}
        </div>
      )}
      <hr className={styles.hr1px} />
      <div className={styles.row}>
        <span>{isDeductedRate ? "Amount paid to supplier" : "Subtotal"}</span>
        <span>
          {isInternationalPayment ? (
            <div className={styles.intSubtotal}>
              <div>
                <ShortCurrency
                  value={payeesAmount}
                  currencyId={intCurrencyId}
                />
              </div>
              <div>
                <ShortCurrency value={SGDSubtotal} currencyId={currencyId} />
              </div>
            </div>
          ) : (
            <>
              <ShortCurrency value={subtotal} currencyId={currencyId} />
            </>
          )}
        </span>
      </div>
      <hr className={styles.hr1px} />
      <div className={styles.rowsContainer}>
        <div className={styles.row}>
          <span>Fee {`(${feeRate / 100}%)`}</span>
          <span>
            <ShortCurrency value={SGDFee} currencyId={currencyId} />
          </span>
        </div>
        {gstFee ? (
          <div className={styles.row}>
            <span>GST (10%)</span>
            <span>
              <ShortCurrency value={gstFee} currencyId={currencyId} />
            </span>
          </div>
        ) : (
          <></>
        )}
        {flashPayFee ? (
          <div className={styles.row}>
            <span>Flash Pay</span>
            <span>
              <ShortCurrency value={flashPayFee} currencyId={currencyId} />
            </span>
          </div>
        ) : (
          <></>
        )}
        {instantPayFee ? (
          <div className={styles.row}>
            <span>Instant Pay</span>
            <span>
              <ShortCurrency value={instantPayFee} currencyId={currencyId} />
            </span>
          </div>
        ) : (
          <></>
        )}
        {bankPayoutFee ? (
          <div className={styles.row}>
            <span>Fund Safeguarding</span>
            <span>
              <ShortCurrency value={bankPayoutFee} currencyId={currencyId} />
            </span>
          </div>
        ) : (
          <></>
        )}
        {minimumTransactionFee ? (
          <div className={styles.row}>
            <span>Minimum Transaction Fee</span>
            <span>
              <ShortCurrency
                value={minimumTransactionFee}
                currencyId={currencyId}
              />
            </span>
          </div>
        ) : (
          <></>
        )}
        {bankFee ? (
          <div className={styles.row}>
            <span>Bank Fee</span>
            <span>
              <ShortCurrency value={bankFee} currencyId={currencyId} />
            </span>
          </div>
        ) : (
          <></>
        )}
        {couponCode && (
          <div className={styles.row}>
            <span>Promo Code</span>
            <span>{couponCode}</span>
          </div>
        )}
      </div>
      <hr className={styles.hr2px} />
      <div className={styles.row}>
        <b>{isDeductedRate ? "Amount Paid" : "Total Amount"}</b>
        <div className={classnames(styles.row, styles.verticalRow)}>
          <b
            className={classnames({
              [styles.totalValue]: isCryptoPaymentMethod
            })}
          >
            <ShortCurrency value={SGDPaymentTotal} currencyId={currencyId} />
          </b>
          {isCryptoPaymentMethod && (
            <div className={classnames(styles.cryptoCol)}>
              <Image
                src={ImageUtils.getCryptoTypeIcon(paymentMethodDetails?.id)}
              />
              <Typography.Text>{paymentMethodDetails?.label}</Typography.Text>
              <Typography.Text>{cryptoPaymentTotal}</Typography.Text>
            </div>
          )}
        </div>
      </div>
      {isCryptoPaymentMethod && (
        <>
          <hr className={styles.hr2px} />
          <div className={classnames(styles.row, styles.btcExchange)}>
            <span className={styles.btcText}>
              {paymentMethodDetails?.label}/
              {CurrencyUtil.convertFromId(currencyId as number).$}
            </span>
            <Typography.Text>
              <Image src={IconExchangePurple} />
              <span className={styles.btcText}>{cryptoExchangeRate}</span>
            </Typography.Text>
          </div>
        </>
      )}
      {isRefund && (
        <>
          <hr className={styles.hr2px} />

          <div className={styles.rowsContainer}>
            <div className={styles.row}>
              <span>Refunded Amount</span>
              <span>
                -{" "}
                <ShortCurrency value={refundedAmount} currencyId={currencyId} />
              </span>
            </div>
            <div className={styles.row}>
              <span>Refunded Fee</span>
              <span>
                - <ShortCurrency value={refundedFee} currencyId={currencyId} />
              </span>
            </div>
            {refundedGSTFee > 0 && (
              <div className={styles.row}>
                <span>Refunded GST Fee</span>
                <span>
                  -{" "}
                  <ShortCurrency
                    value={refundedGSTFee}
                    currencyId={currencyId}
                  />
                </span>
              </div>
            )}
            {refundedFlashPayFee > 0 && (
              <div className={styles.row}>
                <span>Refunded Flash Pay</span>
                <span>
                  -{" "}
                  <ShortCurrency
                    value={refundedFlashPayFee}
                    currencyId={currencyId}
                  />
                </span>
              </div>
            )}
            {refundedBankPayoutFee > 0 && (
              <div className={styles.row}>
                <span>Refunded Fund Safeguarding</span>
                <span>
                  -{" "}
                  <ShortCurrency
                    value={refundedBankPayoutFee}
                    currencyId={currencyId}
                  />
                </span>
              </div>
            )}
            {refundedMinimumTransactionFee > 0 && (
              <div className={styles.row}>
                <span>Refunded Minimum Transaction Fee</span>
                <span>
                  -{" "}
                  <ShortCurrency
                    value={refundedMinimumTransactionFee}
                    currencyId={currencyId}
                  />
                </span>
              </div>
            )}
            <div className={styles.row}>
              <span>New Total</span>
              <span>
                <ShortCurrency
                  value={selectedPayment.paymentTotal}
                  currencyId={currencyId}
                />
              </span>
            </div>
          </div>
        </>
      )}
      {isInternationalPayment && (
        <>
          <hr className={styles.hr2px} />
          <div className={styles.row}>
            <span className={styles.exchangeText}>Exchange Rate</span>
            <span className={styles.exchangeText}>
              <div className={styles.exchangeIcon}>
                <Image src={IconExchangePurple} />{" "}
              </div>
              {exchangeRate}
            </span>
          </div>
        </>
      )}
      <hr className={styles.hr1px} />
      <div className={styles.rowsContainer}>
        {receiptNumber && (
          <div className={styles.row}>
            <span>Receipt Number</span>
            <span>{receiptNumber}</span>
          </div>
        )}

        {!isCryptoPaymentMethod ? (
          <div className={styles.row}>
            <>
              <span>Card to be Charged</span>
              <div className={styles.cardIconContainer}>
                <ShortCardInfo cardId={cardId} />
              </div>
            </>
          </div>
        ) : null}

        <div className={styles.row}>
          <span>{isCryptoPaymentMethod ? "Pay-in Date" : "Charge Date"}</span>
          {isInternationalPayment || isCryptoPaymentMethod ? (
            <span>
              {new Date(scheduledChargeDate).toLocaleDateString("en-GB", {
                year: "numeric",
                month: "long",
                day: "2-digit"
              })}
            </span>
          ) : (
            <span>
              {DateUtils.formatLocaleDate(scheduledChargeDate, {
                day: "numeric",
                month: "short",
                year: "numeric"
              })}
            </span>
          )}
        </div>
        {payoutDate && (
          <div className={styles.row}>
            <span>
              {isCryptoPaymentMethod ? "Pay-out Date" : "Payout Date"}
            </span>
            {isInternationalPayment || isCryptoPaymentMethod ? (
              <span>
                {new Date(payoutDate).toLocaleDateString("en-GB", {
                  year: "numeric",
                  month: "long",
                  day: "2-digit"
                })}
              </span>
            ) : (
              <span>
                {DateUtils.formatLocaleDate(payoutDate, {
                  day: "numeric",
                  month: "short",
                  year: "numeric"
                })}
              </span>
            )}
          </div>
        )}
      </div>
      <hr className={styles.hr1px} />
      <div className={styles.recipientsContainer}>
        {payees.map((p: any) => (
          <React.Fragment key={uuidv1()}>
            <RecipientDetail payment={selectedPayment} recipient={p} />
            <hr className={styles.hr1px} />
          </React.Fragment>
        ))}
      </div>
    </>
  );
};

export default ContentExpanded;
