// export const FORM = "";
export enum ROLES {
  INTERNAL_ADMIN_1 = 1,
  INTERNAL_ADMIN_2,
  ACCOUNT_MEMBER,
  ACCOUNT_ADMIN,
  AM_ADMIN = 7,
  ENTERPRISE_USER = 10
}

export const ACCOUNT_TYPE = {
  BUSINESS: "business",
  PERSONAL: "personal"
};

export const PAYMENT_INTEREST = {
  PAY: "Pay",
  BE_PAID: "Be Paid",
  BOTH: "Both"
};
