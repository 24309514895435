import React from "react";
import styles from "./DisabledTextField.module.scss";

const DisabledTextField = (props: { label: any; value: any }) => {
  const { label, value } = props;
  return (
    <div className={styles.wrapper}>
      <div className={styles.label}>
        <label title={label}>{label}</label>
      </div>
      <input
        disabled
        type="text"
        name="uen"
        className="ant-input ant-input-disabled"
        value={value}
      />
    </div>
  );
};

export default DisabledTextField;
