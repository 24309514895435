import React, { useMemo, useEffect } from "react";
import { history } from "src/ipm-shared/store";
import IPMContext from "src/ipm-shared/Utils/IPMContext";
// import FormErrors from "src/ipm-shared/components/Form/helpers/Errors";
// import { ADD_FORM } from "src/ipm-shared/store/model/Payment/const";
// import ScrollUtil from "src/ipm-shared/Utils/Scroll";

import ROUTES from "src/bepaid/routes";
import { useMakePaymentUrlParams } from "src/bepaid/pages/Payments/hooks";
import { PROGRESS, TITLE } from "src/bepaid/pages/Payments/const";
import { redirectToPrevPath } from "src/bepaid/utils/redirectToPrevPath";
import {
  FORM,
  INTERNATIONAL_FORM
} from "src/ipm-shared/store/model/Payee/const";
import { CHECKOUT_FORM } from "src/ipm-shared/store/model/Card/const";

import AddOrSelectEntity from "./components/AddOrSelectEntity";
import SelectPaymentOption from "./components/SelectPaymentOption";
import SchedulePayment from "./components/SchedulePayment";
import CheckoutPayment from "./components/CheckoutPayment";
import InvoiceDetail from "./components/InvoiceDetail";
import PaymentDetail from "./components/PaymentCreationPaymentDetail";
import BankAccountDetail from "./components/BankAccountDetail";
import SalaryDetail from "./components/SalaryDetail";
import AddEditCompany from "./components/AddEditCompany";
import PaymentCountrySelectModal from "./components/SelectCountry";
import PaymentCurrencySelectModal from "./components/SelectCurrency";
import PaymentEntitySelectModal from "./components/SelectEntity";
import VerifyIdentity from "./components/VerifyIdentity";

import _lowerCase from "lodash-es/lowerCase";
import _get from "lodash-es/get";
import InsuranceDetail from "./components/InsuranceDetail";
import { PAYMENT_METHOD_TYPES } from "src/ipm-shared/store/model/PaymentRequest/const";

const View = (props: any) => {
  const {
    entity,
    action,
    paymentType,
    replaceRouteWithParams
  } = useMakePaymentUrlParams();

  const isWallex = paymentType === "international";

  const titleLabel = useMemo(() => {
    if (!TITLE[entity]) {
      return "...";
    }
    return TITLE[entity][action] || "...";
  }, [entity, action]);

  const progress = useMemo(() => {
    if (!PROGRESS[entity]) {
      return "...";
    }
    return PROGRESS[entity][action] || 33;
  }, [entity, action]);

  const onClose = () => {
    props.removeForm(entity === "self_transfer" ? INTERNATIONAL_FORM : FORM);
    if (entity === "supplier" && paymentType === "domestic") {
      props.removeForm(CHECKOUT_FORM);
    }
    props.resetExtraPayees();
    redirectToPrevPath();
  };

  useEffect(() => {
    IPMContext.initSession();
  }, []);

  useEffect(() => {
    if (
      action &&
      isWallex &&
      !props.paymentCountryCode &&
      action !== "checkout"
    ) {
      history.push(
        replaceRouteWithParams(ROUTES.PAYMENTS_CREATION, {
          newPaymentType: paymentType,
          newEntity: "country",
          newAction: "select"
        })
      );
    }

    if (!props.paymentMethodType) {
      props.setPaymentMethodType(PAYMENT_METHOD_TYPES.CARD);
    }
  }, [action]);

  useEffect(() => {
    // const currentCompany = props.getCompany;
    // console.log("currentCompany====", props.getCompany, currentCompany)
    // if (props.isBusinessAccount && !currentCompany) {
    //   history.push(
    //     replaceRouteWithParams(ROUTES.PAYMENTS_CREATION, {
    //       newEntity: "company",
    //       newAction: "add"
    //     })
    //   );
    //   return;
    // }
    // ScrollUtil.scrollTopElementId("modal-body-content");
  }, [entity, action]);

  const modalProps = {
    progress,
    titleLabel,
    onClose
  };

  const renderContent = () => {
    if (["company"].includes(entity)) {
      return <AddEditCompany />;
    }

    if (entity === "entity") {
      return <PaymentEntitySelectModal {...modalProps} />;
    }

    if (action === "type") {
      return <SelectPaymentOption {...modalProps} />;
    }

    if (isWallex && entity === "country") {
      return <PaymentCountrySelectModal {...modalProps} />;
    }

    if (isWallex && entity === "currency") {
      return <PaymentCurrencySelectModal {...modalProps} />;
    }

    if (entity === "verify") {
      return <VerifyIdentity {...modalProps} />;
    }

    if (["select", "add", "add_bank_account"].includes(action)) {
      return <AddOrSelectEntity {...modalProps} />;
    }

    if (action === "schedule") {
      return <SchedulePayment {...modalProps} />;
    }

    if (action === "invoice_details") {
      return <InvoiceDetail {...modalProps} />;
    }

    if (action === "salary_details") {
      return <SalaryDetail {...modalProps} />;
    }

    if (action === "payment_details") {
      return <PaymentDetail {...modalProps} />;
    }

    if (action === "bank_account_details") {
      return <BankAccountDetail {...modalProps} />;
    }

    if (action === "insurance_details") {
      return <InsuranceDetail {...modalProps} />;
    }

    if (action === "checkout") {
      return <CheckoutPayment {...modalProps} />;
    }

    return <></>;
  };

  return renderContent();
};

export default View;
