import React, { useEffect } from "react";
import { connect } from "react-redux";
import * as selectors from "src/ipm-shared/components/Form/selectors";
import { RootState } from "src/ipm-shared/store/model/reducers";

import * as actions from "src/ipm-shared/components/Form/actions";
import Signature from "signature_pad";
import Button from "src/bepaid/components/Form/controls/Button";
import FileUtils from "src/ipm-shared/Utils/Files";
import * as uuidv1 from "uuid/v1";
import styles from "./SignaturePad.module.scss";
import ShareFilePicker from "src/ipm-shared/components/Form/controls/FilePicker";
import Form from "src/bepaid/components/Form";

import T from "src/ipm-shared/Utils/Intl";
import _get from "lodash-es/get";

export type IInputFileProps = {
  defaultValue?: {
    key: string;
    size: string;
    url: string;
    name: string;
  };
  form: string;
  name: string;
  purpose: string;
  formItemLabel?: string;
  className?: string;
  helperText?: any;
};

type IProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  IInputFileProps;

const Component = (props: IProps) => {
  const { defaultValue = null } = props;

  const [signaturePad, setSignaturePad] = React.useState<Signature>();
  const [signatureFile, setSignatureFile] = React.useState<any>();

  const readyPad = () => {
    let wrapper = document.getElementById("signature-pad");
    let canvas = wrapper?.querySelector("canvas");
    if (canvas) {
      canvas.getContext("2d")?.scale(1, 1);
      let tempSignaturePad = new Signature(canvas, {
        backgroundColor: "rgb(255, 255, 255)"
      });
      setSignaturePad(tempSignaturePad);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      readyPad();
    }, 1000);
  }, []);

  const blobToBase64 = (blob: Blob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onloadend = () => {
        resolve(reader.result);
      };

      reader.onerror = error => {
        reject(error);
      };

      reader.readAsDataURL(blob);
    });
  };

  useEffect(() => {
    if (defaultValue) {
      const convertBlobToBase64 = async (blob: Blob) => {
        try {
          const base64String = await blobToBase64(blob);
          signaturePad?.fromDataURL(base64String as string);
        } catch (error) {
          console.error("Error converting Blob to base64:", error);
        }
      };

      FileUtils.fetchResource({
        cb: (value: any) => {
          convertBlobToBase64(value);
        },
        url: defaultValue.url
      });
    }
  }, [defaultValue]);

  const renderComponent = (sharedProps: any) => {
    const { name, className = undefined, helperText = null } = props;
    const handleSaveSignature = () => {
      const pngValue = signaturePad?.toDataURL("image/png");
      if (pngValue) {
        const inputName = `${name}-${uuidv1()}`;
        const file = FileUtils.convertBase64ToFile(
          pngValue,
          `${inputName}.png`
        );
        sharedProps.onAddFile([file], (err: any, data: any) => {
          if (!err) {
            setSignatureFile({
              ...data[2],
              fileId: data[1],
              fileKey: data[0],
              objectFile: file
            });
          }
        });
      }
    };

    const handleClearSignature = () => {
      signaturePad?.clear();
      if (signatureFile) {
        sharedProps.deleteFile(signatureFile);
        setSignatureFile(null);
      }
    };

    const { control = {}, filesErrors } = sharedProps;

    const { errors = [] } = control;

    let errorMessage = _get(errors, "0.code", "");
    let hasError = errors.length > 0;

    if (filesErrors.length > 0 && !hasError && !errorMessage) {
      try {
        hasError = true;
        errorMessage = _get(filesErrors[0].errors, "0.code", "");
      } catch (err) {
        // errorMessage = err.message;
      }
    }

    return (
      <>
        <Form.Item
          name={name}
          className={className}
          validateStatus={hasError ? "error" : ""}
          {...(hasError ? { help: T.transl(errorMessage) } : {})}
          label={props.formItemLabel}
        >
          <div id="signature-pad">
            <canvas
              className="signature-pad"
              width="366"
              height="200"
              id={name}
            />
            <div className={styles.groupBtn}>
              <Button
                id={"signature_button_save"}
                type="secondary"
                htmlType="button"
                onClick={handleSaveSignature}
              >
                Save
              </Button>
              <Button
                type="secondary"
                htmlType="button"
                onClick={handleClearSignature}
              >
                Clear
              </Button>
            </div>
          </div>
        </Form.Item>
        {helperText && (
          <p
            className={hasError ? styles.helperTextHasError : styles.helperText}
            hidden={helperText.showInEmptyMode}
          >
            {helperText.txt}
          </p>
        )}
      </>
    );
  };

  return <ShareFilePicker {...props} renderComponent={renderComponent} />;
};

const mapStateToProps = (state: RootState) => ({
  getControl: (name: string, form: string) =>
    selectors.getControl(state, name, form)
});

const mapDispatchToProps = {
  setControl: actions.setControl,
  uploadFile: actions.uploadFile
};

export default connect(mapStateToProps, mapDispatchToProps)(Component);
