import { RouteProps } from "react-router";
import { connect } from "react-redux";

import { RootState } from "src/ipm-shared/store/model/reducers";

import * as selectors from "src/bepaid/store/model/Fetch/selectors";
import * as actions from "src/bepaid/store/model/Fetch/actions";
import Component from "./View";

const mapStateToProps = (state: RootState) => ({
  residentialAddress: selectors.getResidentialAddress(state),
  getControl: (name: any, form: any) => selectors.getControl(state, name, form)
});

const mapDispatchToProps = {
  fetchMyInfoSingPass: actions.fetchMyInfoSingPass,
  fetchMainCollectedAccount: actions.fetchMainCollectedAccount,
  confirmMyInfoSingPass: actions.confirmMyInfoSingPass,
  getAddressByPostalCode: actions.getAddressByPostalCode,
  setControl: actions.setControl,
  openModal: actions.openModal,
  closeModal: actions.closeModal,
  parseServerErrors: actions.parseServerErrors
};

export type IProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  RouteProps & { qs: { [param: string]: any }; checkoutUrl?: any };

export default connect(mapStateToProps, mapDispatchToProps)(Component);
