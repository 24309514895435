import React, { useEffect, useState } from "react";
import classNames from "classnames";
// import { compareAsc } from "date-fns";
import _get from "lodash-es/get";
import _isEmpty from "lodash-es/isEmpty";
import Skeleton from "antd/es/skeleton";

import { SCHEDULE_EDIT_FORM } from "src/ipm-shared/store/model/Payment/const";
import { FetchModalID } from "src/ipm-shared/components/GlobalUI/actions";
import { PURPOSE } from "src/ipm-shared/components/Purpose/const";
import FormErrors from "src/ipm-shared/components/Form/helpers/Errors";
import CurrencyUtil from "src/ipm-shared/Utils/Currency";
import { toCents } from "src/ipm-shared/components/ShortCurrency";
import T from "src/ipm-shared/Utils/Intl";

import Collapse from "src/bepaid/components/Collapse";
import Image from "src/bepaid/components/Image";
import Form from "src/bepaid/components/Form";
import InputText from "src/bepaid/components/Form/controls/InputText";
import CardSelector from "src/bepaid/components/CardSelector";
import { PAY_WITH_ANOTHER_CARD_VALUE } from "src/bepaid/components/CardSelector/View";

import ArrowDown from "src/bepaid/assets/images/common/arrow_down.svg";
import ArrowUp from "src/bepaid/assets/images/common/arrow_up.svg";

import OneTimeDateSelect from "src/bepaid/shared/modals/PaymentsCreationModal/components/SchedulePayment/components/ScheduleForm/OneTimeDatesSelect";

import FormStyles from "src/bepaid/components/Form/CommonForm.module.scss";

import styles from "./EditPaymentDetail.module.scss";
import paymentUtil from "../../../PaymentDetailModal/components/PaymentDetail/utils/payment";
import FeatureGateUtil from "src/ipm-shared/components/FeatureGating/Util";
import RecurringDatesSelect from "../../../PaymentsCreationModal/components/SchedulePayment/components/ScheduleForm/RecurringDateSelect";

const View = (props: any) => {
  const {
    // loading,
    chargeDate,
    chargeDateEnd,
    exclusionDates,
    pspNonWorkingDates,
    selectedScheduleDetail,
    paymentSetting,
    selectedPaymentDetail,
    currentCurrencySymbol,
    upcomingPayment,
    availableAcquirers
  } = props;
  const {
    shouldDisplayLoadingCards,
    toggleModal,
    hasAtLeastOneCard,
    isEditAll
  } = props;
  const commonDatePickerProps = {
    // loading,
    chargeDate,
    chargeDateEnd,
    exclusionDates,
    pspNonWorkingDates
  };

  const handleSelectStartDate = (date: any) => {
    if (date) {
      let paymentDetail = paymentSetting;
      if (!isEditAll) {
        paymentDetail = selectedPaymentDetail;
      }
      if (!_isEmpty(paymentDetail)) {
        props.fetchDateConditions(
          new Date(date),
          paymentDetail.payees.map((p: any) => p.bankId),
          null,
          null,
          null,
          undefined,
          paymentDetail.payees.map((p: any) => p.id)
        );
      }
      if (!isEditAll) {
        props.setControl({
          name: `payout_date_${paymentDetail.scheduleId}`,
          errors: [],
          value: date,
          form: SCHEDULE_EDIT_FORM
        });
      } else {
        props.setControl({
          name: `upcoming_date_${paymentDetail.scheduleId}`,
          errors: [],
          value: date,
          form: SCHEDULE_EDIT_FORM
        });
      }
    }
  };
  const handleSelectEndDate = (date: any) => {
    if (date) {
      let paymentDetail = paymentSetting;
      if (!_isEmpty(paymentDetail)) {
        props.fetchDateConditions(
          new Date(date),
          paymentDetail.payees.map((p: any) => p.bankId),
          null,
          null,
          null,
          undefined,
          paymentDetail.payees.map((p: any) => p.id)
        );
      }
      props.setControl({
        name: `end_date_${paymentDetail.scheduleId}`,
        errors: [],
        value: date,
        form: SCHEDULE_EDIT_FORM
      });
    }
  };
  let paymentDetail = paymentSetting;
  if (!isEditAll) {
    paymentDetail = selectedPaymentDetail;
  }
  if (!paymentDetail) {
    return null;
  }

  let singleAmount = paymentDetail.paymentAmount;
  if (isEditAll) {
    if (upcomingPayment.length > 0) {
      let minPayment = upcomingPayment.filter(
        (p: any) => p.paymentStatusId === 2
      )[0];
      if (minPayment) {
        singleAmount = minPayment.paymentAmount;
      }
    }
  }

  let isOnlyWorldPayCard = false;
  let isOnlyWorldpayMasterCard = false;
  let isOnlyWorldpayVisa = false;
  let allowAmexPayment = false;
  if (props.isPersonalAccount) {
    allowAmexPayment = true;
  }
  const entity = paymentUtil.getPaymentEntity(paymentDetail);
  if (entity === "supplier") {
    if (paymentDetail.isAmexMCC) {
      allowAmexPayment = true;
    } else {
      allowAmexPayment = false;
    }
  }
  if (paymentDetail.isWPMCC === true) {
    isOnlyWorldpayMasterCard = true;
    isOnlyWorldpayVisa = true;
  } else {
    isOnlyWorldpayMasterCard = false;
    isOnlyWorldpayVisa = false;
  }
  isOnlyWorldpayMasterCard = true;
  availableAcquirers.forEach((item: any) => {
    if (item.card_brand_id === 0) {
      isOnlyWorldpayMasterCard = false;
      isOnlyWorldpayVisa = false;
    }
  });

  if (
    props.features &&
    !FeatureGateUtil.verifyFeature(props.features, "AMEX_ALLOW_PAYMENT")
  ) {
    allowAmexPayment = false;
  }

  useEffect(() => {
    if (
      paymentDetail &&
      paymentDetail.payees &&
      paymentDetail.payees.length > 0
    ) {
      props.setControl({
        errors: [],
        form: SCHEDULE_EDIT_FORM,
        name: `supplier_amount_${paymentDetail.payees[0].id}}`,
        value: singleAmount
      });
    }
  }, [singleAmount]);
  const [oneTimeDate, setOneTimeDate] = React.useState<Date>();
  const [recurringDates, setRecurringDates] = useState({
    firstDate: new Date(_get(paymentDetail, "upcomingPayout")) || undefined,
    lastDate: new Date(_get(paymentDetail, "scheduleEndDate")) || undefined
  });

  const [selectedCard, selectCard] = React.useState<number>(
    _get(paymentDetail, "cards[0].id", 0)
  );

  React.useEffect(() => {
    props.setControl({
      errors: [],
      form: SCHEDULE_EDIT_FORM,
      name: `card_id_${paymentDetail.scheduleId}`,
      value: _get(paymentDetail, "cards[0].id", 0)
    });
    selectCard(_get(paymentDetail, "cards[0].id", 0));
  }, [_get(paymentDetail, "cards[0].id", 0)]);

  React.useEffect(() => {
    if (!isEditAll && _get(paymentDetail, "payoutDate")) {
      props.setControl({
        errors: [],
        form: SCHEDULE_EDIT_FORM,
        name: `payout_date_${paymentDetail.scheduleId}`,
        value: _get(paymentDetail, "payoutDate")
      });
      props.setControl({
        errors: [],
        form: SCHEDULE_EDIT_FORM,
        name: `end_date_${paymentDetail.scheduleId}`,
        value: null
      });
      setOneTimeDate(new Date(_get(paymentDetail, "payoutDate")));
      if (!_isEmpty(paymentDetail)) {
        props.fetchDateConditions(
          new Date(_get(paymentDetail, "payoutDate")),
          paymentDetail.payees.map((p: any) => p.bankId),
          null,
          null,
          null,
          undefined,
          paymentDetail.payees.map((p: any) => p.id)
        );
      }
    }
  }, [_get(paymentDetail, "payoutDate")]);

  React.useEffect(() => {
    if (isEditAll && _get(paymentDetail, "scheduleEndDate")) {
      props.setControl({
        errors: [],
        form: SCHEDULE_EDIT_FORM,
        name: `end_date_${paymentDetail.scheduleId}`,
        value: _get(paymentDetail, "scheduleEndDate")
      });
      props.setControl({
        errors: [],
        form: SCHEDULE_EDIT_FORM,
        name: `payout_date_${paymentDetail.scheduleId}`,
        value: null
      });
      setOneTimeDate(new Date(_get(paymentDetail, "scheduleEndDate")));
      setRecurringDates({
        firstDate: new Date(_get(paymentDetail, "upcomingPayout")),
        lastDate: new Date(_get(paymentDetail, "scheduleEndDate"))
      });
      if (!_isEmpty(paymentDetail)) {
        props.fetchDateConditions(
          new Date(_get(paymentDetail, "scheduleEndDate")),
          paymentDetail.payees.map((p: any) => p.bankId),
          null,
          null,
          null,
          undefined,
          paymentDetail.payees.map((p: any) => p.id)
        );
      }
    }
  }, [_get(paymentDetail, "scheduleEndDate")]);

  const [isCardSelectorOpen, openCardselector] = React.useState(false);

  const handleSelectedCard = (cardId: any) => {
    props.setControl({
      errors: [],
      form: SCHEDULE_EDIT_FORM,
      name: `card_id_${paymentDetail.scheduleId}`,
      value: cardId
    });
    selectCard(cardId);
  };
  const cardSelectorOnChange = (value: string) => {
    if (value === PAY_WITH_ANOTHER_CARD_VALUE) {
      toggleModal(FetchModalID.ADD_NEW_CARD_MODAL, {
        allowAmexPayment: allowAmexPayment,
        notOpenList: true,
        cb: (cardId: number) => {
          handleSelectedCard(cardId);
        }
        // redirectUrl: `${ROUTES.PAYMENT_EDIT.replace(":id", paymentDetail.id)}`
      });
      return;
    }
    handleSelectedCard(value);
  };

  const cardSelectorOnVisibleChange = (open: boolean) => {
    if (!hasAtLeastOneCard) {
      toggleModal(FetchModalID.ADD_NEW_CARD_MODAL, {
        allowAmexPayment: allowAmexPayment
      });
      return;
    }

    openCardselector(open);
  };
  const isSingleRecipient = _get(paymentDetail, "payees.length", 0) === 1;
  const isOneTime =
    _get(selectedScheduleDetail, "scheduleFrequency", "").toLowerCase() ===
    "one-time";

  let earliestDate = props.earliestDate;

  // let compareDate = _get(
  //   selectedScheduleDetail,
  //   "paymentSetting.scheduleStartDate",
  //   ""
  // );
  // const firstPayoutUpcoming = _get(
  //   selectedScheduleDetail,
  //   "upcomingPayment.payments[0].payoutDate",
  //   ""
  // );

  const selectOnetimeDate = (d: any) => {
    setOneTimeDate(d);
    handleSelectStartDate(d);
  };

  // This method called when user select a date on calendar
  const onSelectRecurringStartDate = (d: any) => {
    setRecurringDates({
      firstDate: d,
      lastDate: recurringDates.lastDate
    });

    handleSelectStartDate(d);
  };
  const onSelectRecurringEndDate = (d: any) => {
    setRecurringDates({
      firstDate: recurringDates.firstDate,
      lastDate: d
    });

    handleSelectEndDate(d);
  };

  const selectRecurringDate = (firstDate: any, lastDate: any) => {
    setRecurringDates({
      firstDate,
      lastDate
    });

    handleSelectStartDate(firstDate);
    handleSelectEndDate(lastDate);
  };

  // if (isEditAll) {
  //   compareDate = firstPayoutUpcoming;
  // }
  // const result = compareAsc(compareDate, props.earliestDate as Date);

  // if (result > -1) {
  //   earliestDate = new Date(compareDate);
  // }
  let note2RecipientLabel = "";
  switch (_get(paymentDetail, "purposeId", 0)) {
    case PURPOSE.RENTAL:
      note2RecipientLabel = T.transl("PROPERTY_NOTE_HEADER");
      break;
    case PURPOSE.SALARY:
    case PURPOSE.SALARY_BUSINESS:
      note2RecipientLabel = T.transl("EMPLOYEE_NOTE_HEADER");
      break;
    case PURPOSE.INVOICE:
      note2RecipientLabel = T.transl("SUPPLIER_NOTE_LABEL");
      break;
  }
  const capitalizeFirstLetter = (text: string) => {
    return text.charAt(0).toUpperCase() + text.slice(1);
  };
  const currencySign = CurrencyUtil.convertFromId(
    _get(paymentDetail, "currencyId", 0)
  ).$;
  const payees = _get(paymentDetail, "payees", []);

  return (
    <div className={classNames(FormStyles.content, styles.wrapper)}>
      <FormErrors form={SCHEDULE_EDIT_FORM} />
      <Form
        layout="vertical"
        requiredMark={false}
        className={FormStyles.personalFormDetail}
      >
        {isEditAll ? (
          <RecurringDatesSelect
            label={"Upcoming payout date"}
            value={recurringDates}
            onSelectRecurringStartDate={onSelectRecurringStartDate}
            onSelectRecurringEndDate={onSelectRecurringEndDate}
            selectDate={selectRecurringDate}
            showOutsideDays={false}
            allowWeekends={false}
            disabledDates={[
              new Date(_get(paymentDetail, "scheduleEndDate", ""))
            ]}
            additionalModifiers={{
              chargeDate: props.chargeDate,
              publicHoliday: props.exclusionDates
            }}
            isEndDayNote={true}
            isCustomCalendar={true}
            earliestDate={earliestDate}
            countryCode={props.countryCode}
            {...commonDatePickerProps}
          />
        ) : (
          <OneTimeDateSelect
            label="Payout date"
            onSelectDate={selectOnetimeDate}
            value={oneTimeDate}
            defaultValue={paymentDetail.payoutDate}
            name={`payout_date_${paymentDetail.scheduleId}`}
            showOutsideDays={true}
            allowWeekends={false}
            disabledDates={props.exclusionDates}
            additionalModifiers={{
              chargeDate: props.chargeDate,
              publicHoliday: props.exclusionDates
            }}
            earliestDate={isOneTime ? props.earliestDate : earliestDate}
            isAUDeadline={props.countryCode === "AU"}
            countryCode={props.countryCode}
            {...commonDatePickerProps}
          />
        )}

        {isSingleRecipient && (
          <InputText
            name={`supplier_amount_${paymentDetail.payees[0].id}`}
            pattern={"__MONEY__"}
            form={SCHEDULE_EDIT_FORM}
            label={`${T.transl("SCHEDULED_AMOUNT_HEADER")} (${currencySign})`}
            defaultValue={toCents(singleAmount)}
            reserveValueOnUnmount={true}
            revertValueOnMount={false}
            placeholder={`${currentCurrencySymbol}0.00`}
          />
        )}
        <Skeleton loading={shouldDisplayLoadingCards}>
          <div className={styles.cardSelector}>
            <CardSelector
              open={isCardSelectorOpen}
              value={selectedCard}
              onChange={cardSelectorOnChange}
              onDropdownVisibleChange={cardSelectorOnVisibleChange}
              defaultValue={_get(paymentDetail, "cards[0].id", 0)}
              isOnlyWorldpayMasterCard={isOnlyWorldpayMasterCard}
              isOnlyWorldpayVisa={isOnlyWorldpayVisa}
              isOnlyWorldPayCard={isOnlyWorldPayCard}
              allowAmexPayment={allowAmexPayment}
              name={`card_id_${paymentDetail.scheduleId}`}
              label={"Card"}
            />
          </div>
        </Skeleton>
        {isSingleRecipient && (
          <InputText
            className={styles.capitalizeFirstLetter}
            name={`default_comments_${paymentDetail.payees[0].id}`}
            form={SCHEDULE_EDIT_FORM}
            label={capitalizeFirstLetter(
              note2RecipientLabel.toLocaleLowerCase()
            )}
            defaultValue={_get(paymentDetail, "payees[0].defaultComments", "")}
            pattern={"__STATEMENT__"}
          />
        )}
        <InputText
          className={styles.capitalizeFirstLetter}
          name={`code`}
          label={capitalizeFirstLetter(
            T.transl("LABEL_CHECKOUT_COUPON_CODE").toLocaleLowerCase()
          )}
          placeholder={`Code`}
          form={SCHEDULE_EDIT_FORM}
          defaultValue={_get(paymentDetail, "couponCode", "")}
        />
      </Form>
      {payees.length > 1 &&
        payees.map((p: any, idx: number) => {
          return (
            <div
              key={`${p.id}_${p.uid}`}
              className={styles.spacingBlock}
              hidden={false}
            >
              <Collapse
                iconOpened={<Image src={ArrowUp} />}
                iconClosed={<Image src={ArrowDown} />}
                type={"button"}
                title={p.name}
              >
                <Form
                  layout="vertical"
                  requiredMark={false}
                  className={FormStyles.personalFormDetail}
                >
                  <InputText
                    form={SCHEDULE_EDIT_FORM}
                    pattern={"__MONEY__"}
                    label={`${T.transl("AMOUNT_LABEL")} (${currencySign})`}
                    name={`supplier_amount_${p.id}${p.uid && "_" + p.uid}`}
                    defaultValue={toCents(p.defaultAmount)}
                    placeholder={`${currentCurrencySymbol}0.00`}
                  />
                  <InputText
                    label={note2RecipientLabel}
                    form={SCHEDULE_EDIT_FORM}
                    pattern={"__STATEMENT__"}
                    name={`default_comments_${p.id}${p.uid && "_" + p.uid}`}
                    defaultValue={p.defaultComments}
                  />
                </Form>
              </Collapse>
            </div>
          );
        })}
    </div>
  );
};

export default View;
