import _get from "lodash-es/get";
import _last from "lodash-es/last";
import paymentUtil from "./utils/payment";
import { PAYMENT_METHOD_TYPES } from "src/ipm-shared/store/model/PaymentRequest/const";

export const useProcessPaymentDetail = (payment: any) => {
  const paymentStatus = paymentUtil.getPaymentStatus(payment);
  const paymentMethodDetails = paymentUtil.getPaymentMethodDetails(payment);
  const payees = _get(payment, "payees", []);
  const discount = _get(payment, "amountOff", 0)
    ? _get(payment, "amountOff", 0)
    : 0;
  const cardCharge = _last(_get(payment, "cardCharge", []));

  const refundedFee = _get(cardCharge, "refundedFee", 0);
  const refundedGSTFee = _get(cardCharge, "refundedGSTFee", 0);
  const refundedFlashPayFee = _get(cardCharge, "refundedFlashPayFee", 0);
  const refundedBankPayoutFee = _get(cardCharge, "refundedBankPayoutFee", 0);
  const refundedMinimumTransactionFee = _get(
    cardCharge,
    "refundedMinimumTransactionFee",
    0
  );

  const refundedTotalAmount = _get(cardCharge, "refundedAmount", 0);
  const refundedAmount =
    refundedTotalAmount -
    refundedFee -
    refundedGSTFee -
    refundedFlashPayFee -
    refundedBankPayoutFee -
    refundedMinimumTransactionFee;

  const fee = _get(payment, "paymentFees", 0) + refundedFee + discount;

  const feeTotal = fee - discount;

  const isRefund = refundedFee > 0 || refundedAmount > 0;

  const subtotal = isRefund
    ? payment.paymentAmount + refundedAmount
    : payment.paymentAmount;

  const paymentTotal = refundedTotalAmount + _get(payment, "paymentTotal", 0);

  const gstFee = _get(payment, "paymentGSTFees", 0) + refundedGSTFee;

  const flashPayFee =
    _get(payment, "paymentFlashPayFees", 0) + refundedFlashPayFee;

  const instantPayFee = _get(payment, "paymentInstantPayFees", 0);

  const bankPayoutFee =
    _get(payment, "paymentBankPayoutFees", 0) + refundedBankPayoutFee;

  const minimumTransactionFee =
    _get(payment, "paymentMinimumTransactionFees", 0) +
    refundedMinimumTransactionFee;

  // Subtotal
  const payeesAmount = payees.reduce((acc: number, current: any) => {
    return acc + current.defaultAmount + current.refundedAmount;
  }, 0);

  // Int payment
  const countryId = _get(payment, "payees[0].countryId", 1);
  const exchangeRate = _get(payment, "exchangeRate") || 1;
  // const feeRate = _get(payment, "feeRate", 0);
  const bankFee = _get(payment, "channelFees", 0);
  const SGDSubtotal = subtotal;
  const SGDFee = feeTotal;
  const SGDPaymentTotal = paymentTotal;

  const isInternationalPayment = _get(payment, "isInternationalPayment", false);
  const isCryptoPaymentMethod =
    _get(payment, "paymentMethodType") === PAYMENT_METHOD_TYPES.CRYPTO;

  const cryptoExchangeRate = _get(payment, "cryptoPaymentDetail.exchangeRate");

  const cryptoPaymentTotal = (
    _get(payment, "paymentTotal") /
    (cryptoExchangeRate * 100)
  ).toFixed(4);

  const isDeductedRate = _get(payment, "isDeductedRate", false);
  return {
    paymentStatus,
    payees,
    feeTotal,
    paymentTotal,
    SGDSubtotal,
    refundedTotalAmount,
    refundedFee,
    refundedGSTFee,
    refundedFlashPayFee,
    refundedBankPayoutFee,
    refundedMinimumTransactionFee,
    refundedAmount,
    countryId,
    exchangeRate,
    subtotal,
    SGDFee,
    SGDPaymentTotal,
    payeesAmount,
    isInternationalPayment,
    bankFee,
    isCryptoPaymentMethod,
    paymentMethodDetails,
    cryptoPaymentTotal,
    cryptoExchangeRate,
    flashPayFee,
    instantPayFee,
    isDeductedRate,
    gstFee,
    bankPayoutFee,
    minimumTransactionFee
  };
};
