import Component from "./View";
import { connect } from "react-redux";

import * as cardSelectors from "src/ipm-shared/store/model/Card/selectors";
// import * as actions from "src/bepaid/store/model/Fetch/actions";
import * as selectors from "src/bepaid/store/model/Fetch/selectors";

import { RootState } from "src/ipm-shared/store/model/reducers";
import { SelectProps } from "antd/es/select";
import _isEmpty from "lodash-es/isEmpty";

const mapStateToProps = (state: RootState, props: IProps) => ({
  cards: cardSelectors
    .getCards(
      state,
      props.defaultValue,
      props.isOnlyWorldpayMasterCard,
      props.isOnlyWorldpayVisa,
      props.isOnlyWorldPayCard,
      false,
      props.allowAmexPayment,
      props.disableVisaPayment
    )
    .map(c => {
      const { cardNickName = "", name = "" } = c;
      return {
        brandId: c.brandId,
        label: `${!_isEmpty(cardNickName) ? cardNickName : name} - ${c.last4}`,
        value: c.id,
        isFavourite: c.isFavourite
      };
    }),
  control: selectors.getControl(state, props.name)
});

const mapDispatchToProps = {};

interface IProps extends SelectProps<any> {
  name: string;
  label: string;
  defaultValue?: number;
  isOnlyWorldpayMasterCard?: boolean;
  isOnlyWorldPayCard?: boolean;
  isOnlyWorldpayVisa?: boolean;
  allowAmexPayment?: boolean;
  disableVisaPayment?: boolean;
}

export default connect<any, any, IProps>(
  mapStateToProps,
  mapDispatchToProps
)(Component);
