/* tslint:disable:object-literal-sort-keys jsx-no-lambda */
import React, { useRef, useState, useEffect, useMemo } from "react";
import {
  BrowserView,
  MobileView,
  isDesktop,
  isMobile
} from "react-device-detect";
import classNames from "classnames";
import _isEmpty from "lodash-es/isEmpty";
import _get from "lodash-es/get";

import T from "src/ipm-shared/Utils/Intl";
import { history } from "src/ipm-shared/store";
import Tooltip from "src/ipm-shared/components/Tooltip";
import ShortCurrency from "src/ipm-shared/components/ShortCurrency";
import DateUtils from "src/ipm-shared/Utils/Date";
import CardUtil from "src/ipm-shared/Utils/Card";
import CountryUtil from "src/ipm-shared/Utils/Country";
import IntercomUtil from "src/ipm-shared/Utils/Intercom";
import CardForm from "src/bepaid/components/CardForm";
import IncentiveType from "src/ipm-shared/store/metadata/incentive_type";
import { Incentive } from "src/ipm-shared/store/model/PaymentCollectionRequest/reducers";
import { CardInfo } from "src/ipm-shared/store/model/Card/types";
import * as Images from "src/ipm-shared/components/Images";
import { FetchModalID } from "src/ipm-shared/components/GlobalUI/actions";
import { CardFormVersion } from "src/ipm-shared/components/CardForm/type";
// import ShareSocial, { SOCIAL_TYPES } from "src/ipm-shared/components/ShareSocial";

import MainLayout from "src/bepaid/layouts/MainLayout";
import Button from "src/bepaid/components/Form/controls/Button";
import Loader from "src/bepaid/components/Loading";
import Image from "src/bepaid/components/Image";
import useQuery from "src/bepaid/hooks/useQuery";
import FormStyles from "src/bepaid/components/Form/CommonForm.module.scss";
import ROUTES from "src/bepaid/routes";
import ChkAgreeTerms from "src/bepaid/shared/modals/PaymentsCreationModal/components/CheckoutPayment/components/ChkAgreeTerms";

import FetchLogo from "src/bepaid/assets/images/fetch_logo.svg";
import PoweredByIpaymy from "src/bepaid/assets/images/powered_by_ipaymy.svg";
import FacebookIcon from "src/bepaid/assets/images/icons/Facebook.svg";
import InstagramIcon from "src/bepaid/assets/images/icons/Instagram.svg";
import LinkedInIcon from "src/bepaid/assets/images/icons/LinkedIn.svg";

import styles from "./Checkout.module.scss";
import { PAYMENT_METHOD_TYPES } from "src/ipm-shared/store/model/PaymentRequest/const";
// import CryptoSelector from "src/bepaid/components/CryptoSelector";
import { CRYPTO } from "src/ipm-shared/Utils/Images";
import Typography from "antd/es/typography";

IntercomUtil.guestInit();

const {
  invoiceOverView,
  normalText,
  boldText,
  table,
  hrStyle,
  boldHr,
  titleCard,
  installmentNumberText,
  labelWrapper,
  refundPolicy
} = styles as any;

const Checkout: React.FC<any> = props => {
  // const paymentMethodTypes = [
  // {
  //   entity: PAYMENT_METHOD_TYPES.CARD,
  //   description: "Pay with card"
  // }
  // {
  //   entity: PAYMENT_METHOD_TYPES.CRYPTO,
  //   description: "Pay with crypto"
  // }
  // ];
  const qs = useQuery();
  const getToken = () => {
    const token = props.match.params.token;
    return token === "token" ? (qs.token as string) : token;
  };
  const [pendingCalculateAmount, isPendingCalculateAmount] = useState(false);
  const [pendingSubmit, isPendingSubmit] = useState(false);

  const [selectedCryptoId] = useState<number>(CRYPTO.BTC);

  const [selectedPaymentMethodType] = useState<any>(PAYMENT_METHOD_TYPES.CARD);

  const isCryptoPaymentMethod = useMemo(
    () => selectedPaymentMethodType === PAYMENT_METHOD_TYPES.CRYPTO,
    [selectedPaymentMethodType]
  );

  const [paymentSummary, setPaymentSummary] = useState({
    fee: 0,
    total: 0,
    subTotal: 0
  });
  const [card, setCard] = useState({
    cardIssuerCountry: "",
    cardBrand: "",
    cardInfo: "",
    cardholderName: ""
  });
  const [cardInfoTemp, setCardInfoTemp] = useState<CardInfo>({
    cardBin: "",
    cardToken: "",
    cardIssuerCountry: ""
  });
  const [cardInfo, setCardInfo] = useState<CardInfo>({
    cardBin: "",
    cardToken: "",
    cardIssuerCountry: ""
  });
  const [fetchUrl, setFetchUrl] = useState("");
  const [confirmation, setConfirmation] = useState(false);
  const [confirmedCryptoPayment, setConfirmCryptoPayment] = useState(false);
  const [isPaymentRequestToolong, setIsPaymentRequestToolong] = useState(false);

  useEffect(() => {
    const token = getToken();
    if (_isEmpty(token)) {
      history.push(ROUTES.ROOT);
    }
    localStorage.setItem(
      "checkoutUrl",
      window.location.pathname + window.location.search
    );
    props.fetchPaymentCollectionRequest(token);
    props.fetchCurrencies();
    props.fetchCountries();
    props.getEstimateRate(token);
    props.setPaymentCollectionMethodType(PAYMENT_METHOD_TYPES.CARD);
  }, []);
  useEffect(() => {
    if (timer.current) {
      return () => clearTimeout(timer.current);
    }
    return;
  }, [pendingSubmit]);

  useEffect(() => {
    const token = getToken();
    props.getEstimateRate(token);
    props.selectPaymentCollectionMethod(
      isCryptoPaymentMethod ? selectedCryptoId : 1,
      token,
      {
        cb: (err?: any) => {
          if (err) {
            window.Logger.error("selectPaymentMethod:", err);
          }
        }
      }
    );
  }, [isCryptoPaymentMethod]);

  const cardFormRef = useRef<any>();
  const timer = useRef<any>();

  // const onSelectPaymentMethodType = (payment_method_type: string) => {
  //   selecPaymentMethodType(payment_method_type);
  //   props.setPaymentCollectionMethodType(payment_method_type);
  //   if (payment_method_type === PAYMENT_METHOD_TYPES.CRYPTO) {
  //     setDisableSubmit(false);
  //   }
  // };

  // const cryptoSelectorOnChange = (value: any) => {
  //   isPendingCalculateAmount(true);
  //   const token = getToken();
  //   props.selectPaymentCollectionMethod(value, token, {
  //     cb: (err?: any) => {
  //       if (err) {
  //         window.Logger.error("selectPaymentMethod:", err);
  //       }
  //     }
  //   });
  //   selectCryptoId(value);
  //   const fee = _get(payment, "cryptoFee", 0);
  //   const subTotal = _get(payment, "subTotal", 0);
  //   const total = subTotal + fee;

  //   setPaymentSummary({
  //     ...paymentSummary,
  //     fee,
  //     subTotal,
  //     total
  //   });
  //   setTimeout(() => {
  //     isPendingCalculateAmount(false);
  //     isPendingSubmit(false);
  //   }, 1000);
  // };

  const onCardTokenizationCleared = () => {
    setCardInfoTemp({
      ...cardInfo,
      cardToken: "",
      cardBrand: ""
    });
    setCardInfo({
      ...cardInfo,
      cardToken: "",
      cardBrand: ""
    });
    setCard({
      ...card,
      cardInfo: ""
    });
    setConfirmation(false);
  };

  const onCardTokenized = (cardInfoData: CardInfo, cardholderName?: string) => {
    setCardInfoTemp({
      ...cardInfoData
    });
    setCardInfo({
      ...cardInfo,
      cardToken: "",
      cardBrand: ""
    });
    setCard({
      ...card,
      cardholderName: cardholderName ? cardholderName : ""
    });
  };

  const [disableSubmit, setDisableSubmit] = useState(true);

  const handleOnCardTokenized = (
    cardInfoData: CardInfo,
    cardholderName?: string
  ) => {
    onCardTokenizationCleared();
    if (cardInfoData.cardToken && cardholderName) {
      setCardInfoTemp({
        ...cardInfoTemp,
        cardToken: "token"
      });
      setDisableSubmit(false);
    } else {
      setDisableSubmit(true);
    }
  };

  const handleOnCardTokenizationCleared = () => {
    onCardTokenizationCleared();
    setDisableSubmit(true);
  };
  const handleOnChangeCardholderName = (cardholderName?: string) => {
    setCardInfo({
      ...cardInfo,
      cardToken: "",
      cardBrand: ""
    });
    setCard({
      ...card,
      cardInfo: ""
    });
    setConfirmation(false);
    if (!cardholderName) {
      setDisableSubmit(true);
    } else if (cardInfoTemp.cardToken) {
      setDisableSubmit(false);
    }
  };

  useEffect(() => {
    calculatingPaymentAmount();
  }, [props.paymentCollectionRequest]);

  useEffect(() => {
    if (CardUtil.isWorldpay(props.acquirerId)) {
      return;
    }
    isPendingSubmit(true);
    setCardInfo({
      ...cardInfo,
      ...cardInfoTemp
    });
  }, [cardInfoTemp.cardToken]);

  useEffect(() => {
    calculatingPaymentAmount();
  }, [cardInfo.cardToken]);

  const calculatingPaymentAmount = () => {
    if (
      (cardInfo.cardToken && cardInfo.cardToken !== "token") ||
      isCryptoPaymentMethod
    ) {
      isPendingCalculateAmount(true);
    }

    let fee = 0;
    let isNationFee = true;

    const countryName = CountryUtil.getCountryCodeFromCountryId(
      accountCountryId
    ).toLowerCase();

    if (countryName) {
      setFetchUrl(
        `https://www.ipaymy.com/${countryName}/en/products/be-paid/fetch`
      );
    }

    if (cardInfo.cardIssuerCountry) {
      const paymentCountryCode = getCountriesById[
        accountCountryId
      ].code.toUpperCase();

      isNationFee = paymentCountryCode === cardInfo.cardIssuerCountry;
    }
    let cardBrandId = 2;
    if (cardInfo.cardBrand) {
      cardBrandId = CardUtil.toBrandId(cardInfo.cardBrand.toLowerCase());
    }

    const { customerFee } = payment;
    if (customerFee) {
      cardBrandId = cardBrandId === 3 ? 2 : cardBrandId;
      const fees = customerFee.find(
        (item: any) => item.brandId === cardBrandId
      );
      if (fees) {
        fee = isNationFee ? fees.nationFee : fees.overseaFee;
      }
    }
    if (isCryptoPaymentMethod) {
      fee = payment.cryptoFee;
    }
    // Sub-Total, Total

    const subTotal = _get(payment, "subTotal", 0);
    // Incentive
    const paymentIncentives: Incentive | null = _get(
      payment,
      "incentives",
      null
    );

    let total = subTotal;
    if (paymentIncentives) {
      total = total + paymentIncentives.fee;
    }
    if (
      (cardInfo.cardToken && cardInfo.cardToken !== "token") ||
      isCryptoPaymentMethod
    ) {
      total = total + fee;
    }

    setPaymentSummary({
      ...paymentSummary,
      fee,
      subTotal,
      total
    });

    setTimeout(() => {
      isPendingCalculateAmount(false);
      isPendingSubmit(false);
    }, 1000);
  };
  const handleCreatePublicStripeCardIntent = (token: string) => () => {
    props.createPublicStripeCardIntent(token);
  };

  const handleSubmitCryptoPaymentMethod = () => {
    isPendingSubmit(true);
    props.chargePaymentCollection({}, isPendingSubmit);
  };

  const handleSubmitCheckout = () => {
    if (isCryptoPaymentMethod) {
      const { paymentCollectionRequest } = props;
      const isCryptoIdRequired = _get(
        paymentCollectionRequest,
        "isCryptoIdRequired",
        false
      );
      if (isCryptoIdRequired) {
        return props.toggleModal(FetchModalID.CRYPTO_VERIFY_IDENTITY, {
          onSubmit: handleSubmitCryptoPaymentMethod
        });
      }
      isPendingSubmit(true);
      props.chargePaymentCollection({}, isPendingSubmit);
      return;
    }
    if (!cardFormRef) {
      return;
    }
    const { acquirerId } = props;
    if (!cardInfo.cardBrand) {
      // Call card temp
      isPendingCalculateAmount(true);

      if (CardUtil.isWorldpay(acquirerId)) {
        try {
          cardFormRef.current.createTmpCard(getToken()).then((data: any) => {
            isPendingCalculateAmount(false);
            if (data && data.cardInfo) {
              setCardInfo({
                ...cardInfo,
                ...data.cardInfo
              });
              setCard({
                ...card,
                cardholderName: data.cardholderName
              });
              return;
            } else if (data && data.form) {
              props.toggleModal(FetchModalID.COLLECTED_PAYMENT_FAILED, {
                errors: data.form[0]
              });
              return;
            }
          });
        } catch (e) {
          isPendingCalculateAmount(false);
        }
      } else {
        cardFormRef.current.tokenize().then((data: any) => {
          if (!data) {
            isPendingCalculateAmount(false);
            return;
          }
          onCardTokenized(data.cardInfo, data.cardholderName);
        });
        calculatingPaymentAmount();
      }
      return;
    }
    isPendingSubmit(true);
    timer.current = setTimeout(() => {
      setIsPaymentRequestToolong(true);
    }, 15000);
    cardFormRef.current.tokenize().then((data: any) => {
      if (!data) {
        isPendingSubmit(false);
        return;
      }
      props.chargePaymentCollection(
        {
          cardInfo: data.cardInfo,
          cardholderName: data.cardholderName
        },
        isPendingSubmit
      );
    });
  };

  const onClickRetry = () => {
    isPendingSubmit(false);
    setIsPaymentRequestToolong(false);
  };

  useEffect(() => {
    const { paymentCollectionRequest } = props;
    const isCharged = _get(paymentCollectionRequest, "isCharged", false);
    const isCancelled = _get(paymentCollectionRequest, "isCancelled", false);
    const isOverdue = _get(paymentCollectionRequest, "isOverdue", false);
    const isOnScreening = _get(
      paymentCollectionRequest,
      "isOnScreening",
      false
    );
    if (isOverdue) {
      history.push(ROUTES.CHECKOUT_OVERDUE.replace(":token", getToken()));
      return;
    }
    if (isCancelled) {
      history.push(ROUTES.CHECKOUT_CANCEL.replace(":token", getToken()));
      return;
    }
    if (isOnScreening) {
      history.push(ROUTES.CHECKOUT_LINK_FAIL.replace(":token", getToken()));
      return;
    }
    if (isCharged) {
      history.push(ROUTES.CHECKOUT_CONFIRMATION.replace(":token", getToken()));
      return;
    }
  }, [props.paymentCollectionRequest]);

  const { getCurrenciesById, getCountriesById, accountCountryId } = props;
  const payment = props.paymentCollectionRequest;

  if (
    !props.acquirerId ||
    props.acquirerId <= 0 ||
    !props.paymentCollectionRequest
  ) {
    return <Loader />;
  }

  // Requests
  let paymentRequests: any[] = _get(payment, "requests", []);
  const isPartnership = props.isPartnershipPaymentCollectionRequest;
  // recheck the payment if it has PARTNERSHIP purpose
  if (paymentRequests.length && isPartnership) {
    // ignore payee isSecondary = null)
    paymentRequests = paymentRequests.filter(
      ({ isSecondary }) => !!isSecondary
    );
    // recalculate the subTotal with paymentRequests above
    payment.subTotal = paymentRequests.reduce((result: number, r: any) => {
      result += r.amountTotal;
      return result;
    }, 0);
  }
  const currencyId =
    getCurrenciesById[_get(paymentRequests, "0.currencyId", 1)].id;
  const incentives: Incentive | null = _get(payment, "incentives", null);
  const renderIncentives = () => {
    if (!incentives) {
      return null;
    }
    if (
      incentives.incentiveTypeId === "P1" ||
      incentives.incentiveTypeId === "P2"
    ) {
      // Not display label installment plan
      return null;
    }
    const incentiveSelected = IncentiveType.filter(
      (item: any) => item.id === incentives.incentiveTypeId
    )[0];
    if (!incentiveSelected) {
      return null;
    }
    let feeLabel = "";
    if (incentiveSelected.fee_label) {
      feeLabel = incentiveSelected.fee_label;
    }
    return (
      <div className={table} key={`incentive_${incentiveSelected.id}`}>
        <p className={classNames(normalText, styles.labelIncentive)}>
          {T.transl(feeLabel)}{" "}
          {incentives.rate ? `(${incentives.rate / 100}%)` : ""}
        </p>
        <p className={classNames(normalText, styles.labelIncentive)}>
          {_get(incentives, "fee", 0) < 0 ? "(" : ""}
          <ShortCurrency
            value={_get(incentives, "fee", 0)}
            currencyId={currencyId}
          />
          {_get(incentives, "fee", 0) < 0 ? ")" : ""}
        </p>
      </div>
    );
  };

  const isNotExistedCardInfo =
    !cardInfo.cardBrand &&
    (!cardInfo.cardToken || cardInfo.cardToken === "token");

  const getLabelButtonSubmit = () => {
    if (isCryptoPaymentMethod) {
      if (pendingSubmit) {
        return `${T.transl("PAYING")}...`;
      } else {
        return `${T.transl("PAY_NOW_BUTTON")}`;
      }
    }
    if (isNotExistedCardInfo) {
      if (pendingCalculateAmount) {
        return `${T.transl("CALCULATING_PAYMENT_AMOUNT")}...`;
      } else {
        return `${T.transl("CALCULATE_PAYMENT_AMOUNT")}`;
      }
    } else {
      if (pendingCalculateAmount) {
        return `${T.transl("CALCULATING_PAYMENT_AMOUNT")}...`;
      }
      if (pendingSubmit) {
        return `${T.transl("PAYING")}...`;
      } else {
        return `${T.transl("PAY_NOW_BUTTON")}`;
      }
    }
  };

  const renderIncentivesDeadline = () => {
    if (!incentives) {
      return null;
    }
    const incentiveSelected = IncentiveType.filter(
      (item: any) => item.id === incentives.incentiveTypeId
    )[0];
    let label = "";
    switch (incentives.incentiveTypeId) {
      case "I1":
        label = _get(
          incentiveSelected,
          "deadline_label",
          "Early payment deadline"
        );
        break;
      case "I2":
        label = _get(
          incentiveSelected,
          "deadline_label",
          "Immediate payment deadline"
        );
        break;
      case "D1":
        label = _get(
          incentiveSelected,
          "deadline_label",
          "Late payment deadline"
        );
        break;
      case "P1":
        label = _get(
          incentiveSelected,
          "deadline_label",
          "Half now, half later deadline"
        );
        break;
      case "P2":
        label = _get(
          incentiveSelected,
          "deadline_label",
          "Pay in three installments deadline"
        );
        break;
    }
    return T.transl(label);
  };

  const renderInstallmentPlanNumber = () => {
    if (!incentives) {
      return null;
    }
    let label = "";
    if (incentives.incentiveTypeId === "P1") {
      label = `${T.transl("INSTALLMENT_NUMBER_LABEL", {
        number: 1,
        total: 2
      })}`;
      return <p className={installmentNumberText}>{label}</p>;
    }
    if (incentives.incentiveTypeId === "P2") {
      label = `${T.transl("INSTALLMENT_NUMBER_LABEL", {
        number: 1,
        total: 3
      })}`;
      return <p className={installmentNumberText}>{label}</p>;
    }
    return null;
  };

  const disableConfirmationPayNow =
    !confirmation && !isNotExistedCardInfo && !pendingCalculateAmount;

  const renderLogo = () => <Image src={FetchLogo} className={styles.logo} />;

  const renderInvoiceInfo = () => (
    <div className={classNames(FormStyles.content, styles.invoiceInfo)}>
      <div
        className={classNames(FormStyles.personalFormDetail, styles.formDetail)}
      >
        <h2 className={titleCard}>{T.transl("PAYMENT_SUMMARY_LABEL")}</h2>
        <div className={invoiceOverView}>
          <div className={labelWrapper}>
            <p className={normalText}>
              {T.transl("PAYMENT_TO_LABEL", {
                value: _get(payment, "companyName", "")
              })}
            </p>
            {renderInstallmentPlanNumber()}
          </div>

          <hr className={classNames(hrStyle, boldHr)} />
          <p className={boldText}>{"Invoice Summary"}</p>
          <hr className={classNames(hrStyle, boldHr)} />
          {paymentRequests.map((request, idx) => (
            <div className={table} key={idx}>
              <p className={normalText}>{request.invoiceNumber}</p>
              <p className={normalText}>
                <ShortCurrency
                  value={_get(request, "amountTotal", 0)}
                  currencyId={currencyId}
                />
              </p>
            </div>
          ))}

          <hr className={hrStyle} />
          <div className={table}>
            <p className={normalText}>{T.transl("SUBTOTAL_LABEL")}</p>
            <p className={normalText}>
              <ShortCurrency
                value={paymentSummary.subTotal}
                currencyId={currencyId}
              />
            </p>
          </div>

          {(paymentSummary.fee &&
            cardInfo.cardToken &&
            cardInfo.cardToken !== "token") ||
          isCryptoPaymentMethod ||
          pendingCalculateAmount ? (
            <div className={table}>
              <p className={normalText}>
                {T.transl("LABEL_PAYMENT_DETAIL_FEE")}&nbsp;
                <Tooltip
                  description={T.transl("PAYMENT_COLLECTION_FEE_TOOLTIP")}
                  displayIcon={true}
                  target={"payment-collection-request-fee-sticky"}
                  className={classNames(
                    styles.feeTooltip,
                    "pc-fee-tooltip general-tooltip"
                  )}
                />
              </p>
              <p className={normalText}>
                {pendingCalculateAmount ? (
                  <div className="dot-elastic" />
                ) : (
                  <ShortCurrency
                    value={paymentSummary.fee}
                    currencyId={currencyId}
                  />
                )}
              </p>
            </div>
          ) : null}
          {renderIncentives()}
          <div className={table}>
            <p className={boldText}>{T.transl("CARD_TOTAL_LABEL")}</p>
            <p className={boldText}>
              {pendingCalculateAmount ? (
                <div className="dot-elastic" />
              ) : (
                <ShortCurrency
                  value={paymentSummary.total}
                  currencyId={currencyId}
                />
              )}
            </p>
          </div>
          <hr className={hrStyle} />
          {payment.dueDate && (
            <div className={table}>
              <p className={normalText}>{T.transl("INVOICE_DUE_DATE_LABEL")}</p>
              <p className={normalText}>
                {DateUtils.formatLocaleDate(_get(payment, "dueDate", ""), {
                  day: "numeric",
                  month: "short",
                  year: "numeric"
                })}
              </p>
            </div>
          )}
          {incentives && (
            <div className={table}>
              <p className={boldText}>{renderIncentivesDeadline()}</p>
              <p className={boldText}>
                {DateUtils.formatLocaleDate(_get(incentives, "deadline", ""), {
                  day: "numeric",
                  month: "short",
                  year: "numeric"
                })}
              </p>
            </div>
          )}
        </div>
      </div>

      <div
        className={classNames(FormStyles.personalFormDetail, styles.formDetail)}
      >
        <div className={refundPolicy}>
          <p className={normalText}>
            <span className={boldText}>Refund Policy: </span>For refunds or
            disputes, please contact {_get(payment, "companyName", "")}{" "}
            directly. Any disputes or claims regarding the fulfilment of goods
            or services must be handled directly with{" "}
            {_get(payment, "companyName", "")}.
          </p>
          <p className={normalText}>
            ipaymy is not responsible for the fulfilment of goods or services to
            you, they are merely acting as a payment processing provider for{" "}
            {_get(payment, "companyName", "")}. The Terms and Conditions of
            making payment through Fetch can be found{" "}
            <Typography.Link
              href="https://www.ipaymy.com/terms-conditions"
              target="_blank"
              underline={true}
              className={styles.link}
            >
              here.
            </Typography.Link>
          </p>
        </div>
      </div>
    </div>
  );
  const renderSubmitButton = () => (
    <>
      {((!isNotExistedCardInfo && !pendingCalculateAmount) ||
        isCryptoPaymentMethod) && (
        <div
          className={classNames(styles.agreeTerms, {
            [styles.isCrypto]: isCryptoPaymentMethod,
            [styles.isCard]: !isCryptoPaymentMethod && isDesktop,
            [styles.isMobile]: isMobile
          })}
        >
          <ChkAgreeTerms
            isCryptoPaymentMethod={isCryptoPaymentMethod}
            onChange={
              isCryptoPaymentMethod
                ? setConfirmCryptoPayment
                : (check: any) => setConfirmation(check)
            }
          />
        </div>
      )}
      <Button
        type="primary"
        htmlType="submit"
        onClick={handleSubmitCheckout}
        wrapperClassName={classNames({
          [styles.wrapperButton]: isCryptoPaymentMethod
        })}
        loading={
          pendingCalculateAmount ||
          (isCryptoPaymentMethod
            ? !confirmedCryptoPayment
            : !card.cardholderName && cardInfo.cardBrand) ||
          pendingSubmit ||
          disableSubmit ||
          disableConfirmationPayNow
        }
      >
        {getLabelButtonSubmit()}
      </Button>
      {!isNotExistedCardInfo &&
        !pendingCalculateAmount &&
        (isPaymentRequestToolong ? (
          <a className={styles.helperText} onClick={onClickRetry}>
            {T.transl("PAY_WAITING_TOO_LONG")}
          </a>
        ) : (
          <p
            className={styles.helperText}
            hidden={!isNotExistedCardInfo && !pendingSubmit}
          >
            {T.transl("PAY_BROWSER_WARNING")}
          </p>
        ))}
    </>
  );

  const renderCardForm = () => (
    <div className={styles.mainForm}>
      <div
        className={classNames(styles.CardFormView, {
          [styles.desktop]: isDesktop,
          [styles.isCrypto]: isCryptoPaymentMethod
        })}
      >
        <div
          className={classNames(
            {
              [styles.wrapperCardForm]: !isCryptoPaymentMethod,
              [styles.wrapperCryptoForm]: isCryptoPaymentMethod
            },
            {
              [styles.calculatedAmountDone]:
                !isNotExistedCardInfo &&
                !pendingCalculateAmount &&
                !isCryptoPaymentMethod &&
                isDesktop
            }
          )}
        >
          {isCryptoPaymentMethod ? (
            <>
              {/* <b>Pay with crypto</b>
              <div className={hrStyle} />
              <CryptoSelector
                name="selected_crypto"
                label={"Currency selected"}
                value={selectedCryptoId}
                onChange={cryptoSelectorOnChange}
              /> */}
            </>
          ) : (
            <CardForm
              ref={(_: any) => {
                cardFormRef.current = _;
              }}
              cardFormVersion={CardFormVersion.BEPAID}
              onCardTokenized={handleOnCardTokenized}
              modalView={false}
              acquirerId={props.acquirerId}
              countryCode={CountryUtil.getCountryCodeFromCurrencyId(
                props.paymentCollectionRequest.currencyId
              )}
              onCardTokenizationCleared={handleOnCardTokenizationCleared}
              stripeCardIntentCreator={handleCreatePublicStripeCardIntent(
                getToken()
              )}
              onChangeCardholderName={handleOnChangeCardholderName}
              isDisabledChinaUnionPay={true}
              isCheckout={true}
            />
          )}
        </div>
        {isDesktop && renderSubmitButton()}
      </div>
    </div>
  );

  const renderFooter = () => (
    <div className={styles.footer}>
      <div className={styles.leftFooter}>
        <div className={styles.socialGroup}>
          <a
            className={styles.socialItem}
            href="https://www.facebook.com/ipaymy/"
            target="_blank"
          >
            <Image src={FacebookIcon} />
          </a>
          <a
            className={styles.socialItem}
            href="https://www.instagram.com/ipaymy/"
            target="_blank"
          >
            <Image src={InstagramIcon} />
          </a>
          <a
            className={styles.socialItem}
            href="https://www.linkedin.com/company/ipaymy/"
            target="_blank"
          >
            <Image src={LinkedInIcon} />
          </a>
        </div>
        <div className={styles.poweredIpaymy}>
          <span>
            © {DateUtils.getCurrentYear()} Fetch. {T.transl("EMAIL_COPYRIGHT")}
          </span>
          <Image className={styles.icon} src={PoweredByIpaymy} />
        </div>
      </div>
      <div className={styles.support}>
        <a target={"_blank"} href={fetchUrl}>
          Fetch
        </a>{" "}
        |{" "}
        <a target={"_blank"} href="https://help.ipaymy.com">
          {T.transl("EMAIL_SUPPORT")}
        </a>{" "}
        |{" "}
        <a target={"_blank"} href="https://www.ipaymy.com/terms-conditions">
          T&Cs
        </a>
      </div>
    </div>
  );

  const renderPciBlock = () => (
    <div className={styles.pciBlock}>
      <img className={styles.pciLogo} src={Images.pci} alt="pci logo" />
      <p>{T.transl("PAYMENT_COLLECTION_PCI")}</p>
    </div>
  );

  // const renderHeaderPaymentMethod = () => {
  //   return (
  //     <div className={styles.header}>
  //       <div
  //         className={classNames(styles.wrapperPaymentMethod, {
  //           [styles.isMobile]: isMobile
  //         })}
  //       >
  //         <ul className={styles.list}>
  //           {paymentMethodTypes.map(({ entity, description }, idx: number) => (
  //             <li
  //               key={idx}
  //               onClick={() => onSelectPaymentMethodType(entity)}
  //               className={classNames({
  //                 [styles.selected]: entity === selectedPaymentMethodType
  //               })}
  //             >
  //               <b>{description}</b>
  //             </li>
  //           ))}
  //         </ul>
  //       </div>
  //     </div>
  //   );
  // };

  return (
    <>
      <BrowserView>
        <div className={classNames(styles.wrapper)}>
          <div className={classNames(styles.item, styles.leftItem)}>
            <div className={styles.header}>{renderLogo()}</div>
            <div className={styles.main}>
              {renderInvoiceInfo()}
              {renderFooter()}
            </div>
          </div>
          <div className={classNames(styles.item, styles.rightItem)}>
            {/* {renderHeaderPaymentMethod()} */}
            <div style={{ height: "120px" }}></div>
            {renderCardForm()}
            {renderPciBlock()}
          </div>
        </div>
      </BrowserView>

      <MobileView>
        <MainLayout header={{ hasBorder: false }} footer={renderSubmitButton()}>
          <div
            className={classNames(styles.wrapper, {
              [styles.wrapperMobile]: isMobile
            })}
          >
            {renderLogo()}
            <div className={styles.main}>{renderInvoiceInfo()}</div>
            {/* {renderHeaderPaymentMethod()} */}
            {renderCardForm()}
            {renderPciBlock()}
            {renderFooter()}
          </div>
        </MainLayout>
      </MobileView>
    </>
  );
};

export default Checkout;
