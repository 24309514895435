import React, { useState } from "react";
import _get from "lodash-es/get";
import _find from "lodash-es/find";
import _flattenDeep from "lodash-es/flattenDeep";
import _toString from "lodash-es/toString";
import TreeSelect from "antd/es/tree-select";
import { Tree } from "antd";
import Form from "src/bepaid/components/Form";
import T from "src/ipm-shared/Utils/Intl";
import ImageUtils from "src/ipm-shared/Utils/Images";
import SharedSelectMultiLevel from "src/ipm-shared/components/Form/controls/SelectMultiLevel";
import classNames from "classnames";
import styles from "./SelectMultiLevelCustom.module.scss";

const Component = (props: any) => {
  const {
    name,
    options = [],
    label,
    defaultValue,
    dropdownClassname,
    showSearch = true,
    allowClear = true
  } = props;
  const [expandedKeys, setExpandedKeys] = useState<string[] | undefined>([]);

  const expandNode = (key: string) => {
    setExpandedKeys((prev: string[]) => {
      if (!prev) {
        return;
      }
      if (prev.includes(key)) {
        return prev.filter((item: string) => item !== key);
      } else {
        return [...prev, key];
      }
    });
  };

  const renderTreeNodes = (data: any) => {
    return data.map((item: any) => {
      const disbaleMultipleAmex =
        props.payeeNum > 1 && item.value.includes("Pay with American Express");

      if (item.children?.length) {
        return (
          <Tree.TreeNode
            title={
              <span
                onClick={
                  !disbaleMultipleAmex && expandNode.bind(null, item.label)
                }
              >
                {
                  <div className={styles.container}>
                    {expandedKeys?.includes(item.label) ? (
                      <span className={styles.expandedKey}>{item.label}</span>
                    ) : (
                      item.label
                    )}
                    {item.showCardSurffix && (
                      <div className={classNames(styles.cardIcons)}>
                        {item.card_brand_ids?.map((c: string) => {
                          return (
                            <img
                              key={ImageUtils.getCardTypeIcon(c)}
                              className={styles.cardIcon}
                              src={ImageUtils.getCardTypeIcon(c)}
                            />
                          );
                        })}
                      </div>
                    )}
                  </div>
                }
              </span>
            }
            key={item.value}
            selectable={disbaleMultipleAmex ? true : false}
            {...(item.hideSwitcherIcon && { switcherIcon: <></> })}
            {...item}
          >
            {renderTreeNodes(item.children)}
          </Tree.TreeNode>
        );
      }
      return (
        <Tree.TreeNode
          key={item.value}
          title={
            (item.label === "Insurance" || item.label === "Utilities") &&
            item.value.includes("_amex") ? (
              <>
                {item.label} <i>*Not eligible for Points Guarantee</i>
              </>
            ) : (
              item.label
            )
          }
          {...item}
        />
      );
    });
  };
  const findRecurse = (node: any): any => {
    if (node.value === defaultValue) return node;

    var result;
    for (var i = 0; i < node.children?.length; i++) {
      result = findRecurse(node.children[i]);
      if (result) return result;
    }
    return null;
  };

  const getDefaultValue = () => {
    if (defaultValue) {
      let selectedItem = undefined;
      for (const o of options) {
        if (selectedItem) break;
        selectedItem = findRecurse(o);
      }

      return _get(selectedItem, "label");
    }
    return null;
  };

  const onSearch = (value: string) => {
    value ? setExpandedKeys(undefined) : setExpandedKeys([]);
  };

  const renderComponent = (sharedProps: any) => {
    // INFO: props in custom component should be a higher priority
    const handleChange = (...params: any[]) => {
      if (sharedProps.onChange) {
        sharedProps.onChange(...params);
      }
      if (props.onChange) {
        props.onChange(...params);
      }
    };

    const commonProps = {
      ...sharedProps,
      ...props,
      onChange: handleChange
    };

    const { control = {} } = sharedProps;

    const { errors = [] } = control;
    const errorMessage = _get(errors, "0.code", "");
    const hasError = errors.length > 0;

    return (
      <Form.Item
        label={label}
        {...(hasError
          ? {
              help: T.transl(errorMessage),
              validateStatus: "error"
            }
          : {})}
      >
        <TreeSelect
          {...commonProps}
          defaultValue={getDefaultValue()}
          showSearch={showSearch}
          allowClear={allowClear}
          treeExpandedKeys={expandedKeys}
          onDropdownVisibleChange={setExpandedKeys.bind(null, [])}
          treeNodeFilterProp={"title"}
          onSearch={onSearch}
          dropdownClassName={classNames(
            styles.treeSelectDropdown,
            dropdownClassname
          )}
        >
          {renderTreeNodes(options)}
        </TreeSelect>
        {props.description}
      </Form.Item>
    );
  };

  return (
    <SharedSelectMultiLevel
      {...props}
      name={name}
      options={options}
      renderComponent={renderComponent}
    />
  );
};

export default Component;
