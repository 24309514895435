import React from "react";
import { BrowserView, MobileView } from "react-device-detect";
import _isEmpty from "lodash-es/isEmpty";
import classNames from "classnames";
import Typography from "antd/es/typography";

import Form from "src/bepaid/components/Form";
import DesktopModalLayout from "src/bepaid/components/Modal/components/DesktopModalLayout";
import MainLayout from "src/bepaid/layouts/MainLayout";

import styles from "./OnboardingRequirements.module.scss";
import Button from "src/bepaid/components/Form/controls/Button";
// import { FetchModalID } from "src/ipm-shared/components/GlobalUI/actions";
// import { MY_INFO_MODES } from "../../const";
import { FetchModalID } from "src/ipm-platform/layout/actions";
import { MY_INFO_MODES } from "../../const";

const FormComponent = (props: any) => {
  return (
    <Form
      name="my-info-login-identity-form"
      layout="vertical"
      requiredMark={false}
      className={styles.form}
    >
      <div className={styles.itemSection}>
        <div className={styles.itemText}>
          <Typography.Text>
            Please note the following information is required to complete the
            onboarding process.
          </Typography.Text>
        </div>
        <ol>
          <li className={styles.purpleText}>
            <span>
              ACRA Bizfile (updated at least within the last 3 months),
              applicable if you proceed without Singpass
            </span>
          </li>
          <li className={styles.purpleText}>
            <span> Business website (if available)</span>
          </li>
          <li className={styles.purpleText}>
            <span>
              National IDs of 2 directors and major shareholders (i.e. 25% or
              more)
            </span>
          </li>
          <li className={styles.purpleText}>
            <span>
              Specimen signature of the ipaymy account holder (option to upload
              your digital signature)
            </span>
          </li>
        </ol>
        <div className={styles.itemText}>
          <Typography.Text>
            The process will take only 5 mins if you have all the relevant
            documents ready to proceed.
          </Typography.Text>
        </div>
      </div>
    </Form>
  );
};

const OnboardingRequirements: React.FC<any> = props => {
  const { onClose, setExtraInfoState, openModal } = props;

  const [loading, setLoading] = React.useState(false);

  const renderButton = () => (
    <div className={styles.button}>
      <div className={styles.italicNoteText}>
        <Typography.Text>
          Information is automatically saved should you choose to continue the
          process at a later stage.
        </Typography.Text>
      </div>
      <Button
        type="primary"
        htmlType="submit"
        loading={loading}
        onClick={onSubmit}
      >
        {loading ? "Continue..." : "Continue"}
      </Button>
    </div>
  );

  const onSubmit = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);

      setExtraInfoState({
        mode: MY_INFO_MODES.login,
        isBiz: true
      });
      openModal(FetchModalID.MY_INFO_VERIFICATION_MODAL);
    }, 500);
  };

  return (
    <>
      <BrowserView>
        <DesktopModalLayout
          title={"Onboarding Requirements"}
          // onBack={extraInfoState.isBiz && onBack}
          onClose={onClose}
          onSubmit={onSubmit}
          btnSubmit={renderButton()}
          submitButtonClassName={styles.submitButton}
        >
          <div className={classNames(styles.wrapper, styles.wrapperDesktop)}>
            <FormComponent {...props} />
          </div>
        </DesktopModalLayout>
      </BrowserView>
      <MobileView>
        <MainLayout
          header={{
            title: "Onboarding Requirements",
            // onBack: extraInfoState.isBiz && onBack,
            onClose,
            hideMenu: true
          }}
          footer={renderButton()}
        >
          <div className={styles.wrapper}>
            <FormComponent {...props} />
          </div>
        </MainLayout>
      </MobileView>
    </>
  );
};

export default OnboardingRequirements;
