import * as React from "react";
import { connect } from "react-redux";
import { RootState } from "src/ipm-shared/store/model/reducers";
import * as selectors from "../selectors";
import { IBaseProps } from "src/ipm-shared/components/Form/controls/lib/Base";
import Select from "src/ipm-shared/components/Form/controls/Select";
import T from "src/ipm-shared/Utils/Intl";
import Skeleton from "antd/es/skeleton";

import * as actions from "../actions";

type ISelect = {
  SelectComponent?: any;
};

const mapStateToProps = (state: RootState) => ({
  businessIndustries: selectors.getBusinessIndustries(state),
  isFetchingBusiness: selectors.getIsFetchBusiness(state)
});

const mapDispatchToProps = {
  fetchBusinessIndustries: actions.fetchBusinessIndustries
};

type IProps = IBaseProps &
  ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  ISelect;

class SelectBusinessIndustries extends React.Component<IProps> {
  public componentDidMount() {
    this.props.fetchBusinessIndustries();
  }

  public render() {
    const {
      businessIndustries,
      SelectComponent = Select,
      isFetchingBusiness
    } = this.props;

    return this.props.businessIndustries.length > 0 ? (
      <Skeleton loading={isFetchingBusiness} paragraph={{ rows: 1 }}>
        <SelectComponent
          placeholder={T.transl("BUSINESS_INDUSTRY_PLACEHOLDER")}
          options={businessIndustries.map(b => ({
            label: T.transl(`BUSINESS_INDUSTRY_${b.id}`),
            value: b.id
          }))}
          {...this.props}
        />
      </Skeleton>
    ) : (
      <Skeleton loading={true} paragraph={{ rows: 1 }}></Skeleton>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectBusinessIndustries);
