/**
 * Selectors
 *
 * It's like "views" in relational databases.
 * Store the most efficient format in the reducer state.
 * But to retrieve into easy to consume form, use selector.
 */
import { createSelector } from "reselect";
import { RootState } from "../reducers";
import { default as cardsReducer /*Card*/ } from "./reducers";
import * as cardBrandSelectors from "../CardBrand/selectors";
import * as accountProfileSelectors from "src/ipm-shared/store/model/AccountProfile/selectors";
// import store from "src/ipm-shared/store";
import ReducerFactory from "src/ipm-shared/Utils/ReduxReducer";
import { BRAND } from "src/ipm-shared/Utils/Images";
import CardUtil from "src/ipm-shared/Utils/Card";

ReducerFactory.registerReducer({ cards: cardsReducer });

const selectors = {
  ...cardBrandSelectors
};

// Use this to get available cards in wallet
export const getCards = (
  state: RootState,
  defaultValues?: number,
  isOnlyWorldpayMasterCard?: boolean,
  isOnlyWorldpayVisa?: boolean,
  isOnlyWorldPayCard?: boolean,
  isIgnoreCheckCard: boolean = true,
  allowAmexPayment?: boolean,
  disableVisaPayment: boolean = false
) => {
  const cards = state.cards.byId;
  const cardBrands = selectors.brandsById(state);
  const cardsMap = Object.keys(cards);
  const cardBrandsMap = Object.keys(cardBrands);
  if (cardsMap.length === 0 || cardBrandsMap.length === 0) {
    return [];
  }
  const availabelAcquirerIds = accountProfileSelectors.getAvailableAcquirers(
    state
  );
  let ignoreCheckCard = isIgnoreCheckCard;
  return (
    cardsMap
      .filter(
        cardId =>
          cards[cardId].isWallet === true ||
          parseInt(cardId, 10) === defaultValues
      )
      .filter(cardId => {
        let acquiredItem = availabelAcquirerIds.find(
          (x: any) =>
            x.id === cards[cardId].acquirerId &&
            (x.card_brand_id === cards[cardId].brandId || x.card_brand_id === 0)
        );
        if (acquiredItem) {
          if (acquiredItem.card_brand_id === 0) {
            ignoreCheckCard = true;
          }
          return true;
        }
        return false;
      })
      .filter(cardId => {
        if (!disableVisaPayment === true) {
          if (cards[cardId].brandId === BRAND.VISA) {
            return true;
          }
        } else {
          if (cards[cardId].brandId === BRAND.VISA) {
            return false;
          }
        }
        if (ignoreCheckCard) {
          return true;
        }
        if (allowAmexPayment === true) {
          if (cards[cardId].brandId === BRAND.AMEX) {
            return true;
          }
        } else {
          if (cards[cardId].brandId === BRAND.AMEX) {
            return false;
          }
        }
        if (isOnlyWorldPayCard === true) {
          if (CardUtil.isWorldpay(cards[cardId].acquirerId)) {
            return true;
          }
          return false;
        } else {
          if (cards[cardId].brandId === BRAND.VISA) {
            if (CardUtil.isSGCard(cards[cardId].acquirerId)) {
              if (isOnlyWorldpayVisa) {
                return (
                  CardUtil.isWorldpay(cards[cardId].acquirerId) &&
                  !CardUtil.isStripe(cards[cardId].oldAcquirerId)
                );
              } else {
                return (
                  !CardUtil.isWorldpay(cards[cardId].acquirerId) ||
                  CardUtil.isStripe(cards[cardId].oldAcquirerId) ||
                  (CardUtil.isWorldpay(cards[cardId].acquirerId) &&
                    !CardUtil.isWorldpay(cards[cardId].oldAcquirerId))
                );
              }
            }
          }
          if (cards[cardId].brandId === BRAND.MASTER_CARD) {
            if (CardUtil.isSGCard(cards[cardId].acquirerId)) {
              if (isOnlyWorldpayMasterCard) {
                return (
                  CardUtil.isWorldpay(cards[cardId].acquirerId) &&
                  !CardUtil.isStripe(cards[cardId].oldAcquirerId)
                );
              } else {
                return (
                  !CardUtil.isWorldpay(cards[cardId].acquirerId) ||
                  CardUtil.isStripe(cards[cardId].oldAcquirerId) ||
                  (CardUtil.isWorldpay(cards[cardId].acquirerId) &&
                    !CardUtil.isWorldpay(cards[cardId].oldAcquirerId))
                );
              }
            }
          }
        }
        return true;
      })
      .map(cardId => cards[cardId])
      .map(card => ({
        ...card,
        brand: cardBrands[card.brandId],
        label: `${cardBrands[card.brandId].name} **** **** **** ${card.last4}`
      }))
      // sort by createdAt (desc)
      .sort((c1: any, c2: any) => {
        const createdAtC1 = new Date(c1.createdAt);
        const createdAtC2 = new Date(c2.createdAt);
        if (createdAtC1 < createdAtC2) {
          return 1;
        }
        if (createdAtC1 > createdAtC2) {
          return -1;
        }
        return 0;
      })
  );
};

export const getAllCards = createSelector(
  (state: RootState) => state.cards.byId,
  (state: RootState) => selectors.brandsById(state),
  (cards, cardBrands) => {
    const cardsMap = Object.keys(cards);
    const cardBrandsMap = Object.keys(cardBrands);
    if (cardsMap.length === 0 || cardBrandsMap.length === 0) {
      return [];
    }
    return cardsMap
      .filter(cardId => cards[cardId].isWallet === true)
      .map(cardId => cards[cardId])
      .map(card => ({
        ...card,
        brand: cardBrands[card.brandId],
        label: `${cardBrands[card.brandId].name} **** **** **** ${card.last4}`
      }));
  }
);

export const getSelectedCard = (state: RootState) => state.cards.selectedId;

// export const getEditedCard = (state: RootState) =>
//   _get(state.cards.byId, state.cards.editedId, {}) as Card;

// export const getAdyenTokenizedCard = (state: RootState) => state.cards.token;
export const shouldDisplayLoadingCards = (state: RootState) =>
  state.cards.isFetching;

// Use this to get all cards including deleted ones
export const cardsById = (state: RootState) => state.cards.byId;

export const getCardsByCountryId = createSelector(
  (state: RootState) => state.cards.byId,
  (state: RootState) => selectors.brandsById(state),
  (state: RootState) => accountProfileSelectors.getAvailableAcquirers(state),
  (cards, cardBrands, availabelAcquirers) => {
    // (cards, cardBrands) => {
    const cardsMap = Object.keys(cards);
    const cardBrandsMap = Object.keys(cardBrands);
    if (cardsMap.length === 0 || cardBrandsMap.length === 0) {
      return [];
    }
    const availabelAcquirerIds = availabelAcquirers.map((o: any) => o.id);

    return (
      cardsMap
        .filter(cardId => cards[cardId].isWallet === true)
        .filter(cardId =>
          availabelAcquirerIds.includes(cards[cardId].acquirerId)
        )
        .map(cardId => cards[cardId])
        .map(card => ({
          ...card,
          brand: cardBrands[card.brandId],
          label: `${cardBrands[card.brandId].name} **** **** **** ${card.last4}`
        }))
        // sort by createdAt (desc)
        .sort((c1: any, c2: any) => {
          const createdAtC1 = new Date(c1.createdAt);
          const createdAtC2 = new Date(c2.createdAt);
          if (createdAtC1 < createdAtC2) {
            return 1;
          }
          if (createdAtC1 > createdAtC2) {
            return -1;
          }
          return 0;
        })
    );
  }
);
