import React from "react";
import { isDesktop } from "react-device-detect";
import _get from "lodash-es/get";
import Form from "src/bepaid/components/Form";
import Typography from "antd/es/typography";

import store from "src/ipm-shared/store";
import { getBanksOptionsByCountryId } from "src/ipm-shared/store/model/Bank/selectors";
import { BRAND_ID } from "src/ipm-shared/store/model/CollectedAccount/const";
import T from "src/ipm-shared/Utils/Intl";

import InputText from "src/bepaid/components/Form/controls/InputText";
import Select from "src/bepaid/components/Form/controls/Select";
import Button from "src/bepaid/components/Form/controls/Button";
import { invokeMessage } from "src/bepaid/components/Message";
import { ACCOUNT_SETTINGS_FORM } from "src/bepaid/store/model/Fetch/const";

import styles from "./AccountSettings.module.scss";
import classnames from "classnames";
import { getMinMaxFeeRate } from "src/ipm-shared/components/CollectionSplitFee";

import CollectionSplitFeesNew from "src/bepaid/components/CollectionSplitFees";

const FormComponent = (props: any) => {
  const {
    collectedAccount,
    countryId,
    fetchMainCollectedAccount,
    updateCollectedAccount,
    collectionRates
  } = props;

  if (!collectedAccount || !collectionRates) {
    return null;
  }

  const requesterNationalRateVisa = getMinMaxFeeRate(
    collectedAccount.payeeData.requesterRates.filter(
      (r: any) => r.brand_id === 2
    )[0].fee_payer as number,
    collectedAccount.payeeData.requesterRates.filter(
      (r: any) => r.brand_id === 2
    )[0].national as number,
    collectionRates.filter((r: any) => r.brand_id === 2)[0].national
  );

  const requesterInternationalRateVisa = getMinMaxFeeRate(
    collectedAccount.payeeData.requesterRates.filter(
      (r: any) => r.brand_id === BRAND_ID.VISA
    )[0].int_fee_payer as number,
    collectedAccount.payeeData.requesterRates.filter(
      (r: any) => r.brand_id === BRAND_ID.VISA
    )[0].international as number,
    collectionRates.filter((r: any) => r.brand_id === BRAND_ID.VISA)[0]
      .international
  );

  const requesterNationalRateUnion = getMinMaxFeeRate(
    collectedAccount.payeeData.requesterRates.filter(
      (r: any) => r.brand_id === BRAND_ID.UNION
    )[0].fee_payer as number,
    collectedAccount.payeeData.requesterRates.filter(
      (r: any) => r.brand_id === BRAND_ID.UNION
    )[0].national as number,
    collectionRates.filter((r: any) => r.brand_id === BRAND_ID.UNION)[0]
      .national
  );

  const requesterInternationalRateUnion = getMinMaxFeeRate(
    collectedAccount.payeeData.requesterRates.filter(
      (r: any) => r.brand_id === BRAND_ID.UNION
    )[0].int_fee_payer as number,
    collectedAccount.payeeData.requesterRates.filter(
      (r: any) => r.brand_id === BRAND_ID.UNION
    )[0].international as number,
    collectionRates.filter((r: any) => r.brand_id === BRAND_ID.UNION)[0]
      .international
  );

  const requesterNationalRateAmex = getMinMaxFeeRate(
    collectedAccount.payeeData.requesterRates.filter(
      (r: any) => r.brand_id === BRAND_ID.AMEX
    )[0].fee_payer as number,
    collectedAccount.payeeData.requesterRates.filter(
      (r: any) => r.brand_id === BRAND_ID.AMEX
    )[0].national as number,
    collectionRates.filter((r: any) => r.brand_id === BRAND_ID.AMEX)[0].national
  );

  const requesterInternationalRateAmex = getMinMaxFeeRate(
    collectedAccount.payeeData.requesterRates.filter(
      (r: any) => r.brand_id === BRAND_ID.AMEX
    )[0].int_fee_payer as number,
    collectedAccount.payeeData.requesterRates.filter(
      (r: any) => r.brand_id === BRAND_ID.AMEX
    )[0].international as number,
    collectionRates.filter((r: any) => r.brand_id === BRAND_ID.AMEX)[0]
      .international
  );

  const [loading, setLoading] = React.useState(false);
  const [feeSettings, setFeeSettings] = React.useState([
    {
      brand_id: BRAND_ID.VISA,
      international: requesterInternationalRateVisa,
      national: requesterNationalRateVisa
    },
    {
      brand_id: BRAND_ID.UNION,
      international: requesterInternationalRateUnion,
      national: requesterNationalRateUnion
    },
    {
      brand_id: BRAND_ID.AMEX,
      international: requesterInternationalRateAmex,
      national: requesterNationalRateAmex
    }
  ]);

  const onSubmit = () => {
    updateCollectedAccount(
      { feeSettings, form: ACCOUNT_SETTINGS_FORM },
      (err?: any) => {
        if (!err) {
          invokeMessage("success", "Changes saved.");
          fetchMainCollectedAccount();

          if (props.onSaved) {
            props.onSaved();
          }
        }
      },
      setLoading
    );
  };

  const handleChangeFees = (
    brandId: number,
    values: {
      requesterRate: {
        international: number;
        national: number;
        crypto_rate: number;
      };
    }
  ) => {
    const newFeeSettings = feeSettings.filter(r => r.brand_id !== brandId);
    newFeeSettings.push({
      brand_id: brandId,
      ...values.requesterRate
    });
    setFeeSettings(newFeeSettings);
  };

  const renderAccontNumberDescription = () => {
    switch (props.countryId) {
      case 1:
        return T.transl("SETTINGS_ACCOUNT_SG_DESCRIPTION");
      case 2:
        return T.transl("SETTINGS_ACCOUNT_MY_DESCRIPTION");
      case 3:
        return T.transl("SETTINGS_ACCOUNT_HK_DESCRIPTION");
      case 16:
        return T.transl("SETTINGS_ACCOUNT_AU_DESCRIPTION");
      default:
        return T.transl("SETTINGS_ACCOUNT_SG_DESCRIPTION");
    }
  };
  return (
    <Form
      name="account-settings-form"
      layout="vertical"
      requiredMark={false}
      className={styles.form}
    >
      <div className={styles.formWrapper}>
        <div className={styles.formContent}>
          <div className={styles.itemSection}>
            <InputText
              name="recipient_email"
              placeholder="Email"
              form={ACCOUNT_SETTINGS_FORM}
              pattern="__EMAIL__"
              label="Email"
              defaultValue={_get(
                collectedAccount,
                "payeeData.recipientEmail",
                ""
              )}
            />
            <Typography.Text className={styles.itemText}>
              Please provide the email address you would like all information
              regarding payments made to your account to be sent to.
            </Typography.Text>
          </div>
          <div className={styles.itemSection}>
            <Select
              name="bank_id"
              placeholder="Bank"
              form={ACCOUNT_SETTINGS_FORM}
              disabled={true}
              label="Bank"
              options={getBanksOptionsByCountryId(store.getState(), countryId)}
              defaultValue={
                _get(collectedAccount, "bankId") === 0
                  ? null
                  : _get(collectedAccount, "bankId")
              }
            />
            <Typography.Text className={styles.itemText}>
              In order to update your bank account details, please contact us at{" "}
              <Typography.Link href="mailto:support@ipaymy.com">
                support@ipaymy.com
              </Typography.Link>
              .
            </Typography.Text>
          </div>
          <div className={styles.itemSection}>
            <InputText
              name="account_number"
              placeholder="Account number"
              form={ACCOUNT_SETTINGS_FORM}
              disabled={true}
              label="Account number"
              defaultValue={_get(collectedAccount, "accountNumber", "")}
            />
            <Typography.Text className={styles.itemText}>
              {renderAccontNumberDescription()}
            </Typography.Text>
          </div>
          <div className={styles.itemSection}>
            <InputText
              name="default_statement_descriptor"
              placeholder="Statement descriptor"
              form={ACCOUNT_SETTINGS_FORM}
              label="Statement descriptor"
              defaultValue={_get(
                collectedAccount,
                "payeeData.defaultStatementDescriptor",
                ""
              )}
            />
            <Typography.Text className={styles.itemText}>
              We recommend using a descriptor your customer will recognise, such
              as the name of your business.
            </Typography.Text>
          </div>
          {/* <div className={styles.itemSection}>
            <InputText
              name="company_sms_name"
              placeholder="SMS Sender ID"
              form={ACCOUNT_SETTINGS_FORM}
              pattern="__SMS_NAME__"
              maxLength={11}
              label="SMS Sender ID"
              defaultValue={_get(
                collectedAccount,
                "payeeData.companySmsName",
                ""
              )}
            />
            <Typography.Text className={styles.itemText}>
              Specify the sender ID you want your customer to see in the SMS
              request, we recommend using a name your customer will recognise,
              such as the name of your business.
            </Typography.Text>
          </div> */}
          <div>
            <Typography.Text>
              Split the ipaymy platform fee with your customer
            </Typography.Text>
            <div className={styles.feeSection}>
              <CollectionSplitFeesNew
                feeSettings={feeSettings}
                handleChangeFees={handleChangeFees}
              />
            </div>
          </div>
        </div>
      </div>
      <Form.Item className={styles.button}>
        <Button
          type="primary"
          htmlType="submit"
          loading={loading}
          onClick={onSubmit}
        >
          {loading ? "Saving changes..." : "Save changes"}
        </Button>
      </Form.Item>
    </Form>
  );
};

const AccountSettings: React.FC<any> = props => {
  return (
    <div
      className={classnames(styles.wrapper, {
        [styles.wrapperDesktop]: isDesktop
      })}
    >
      <FormComponent {...props} />
    </div>
  );
};

export default AccountSettings;
