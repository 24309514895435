import React from "react";
import classnames from "classnames";

import ShortCurrency from "src/ipm-shared/components/ShortCurrency";
import DateUtils from "src/ipm-shared/Utils/Date";
import Image from "src/bepaid/components/Image";

import IconTemplateBlock from "src/bepaid/assets/images/invoices/template_color_block.svg";
import IconLogoPlaceHolder from "src/bepaid/assets/images/invoices/logo_placeholder.svg";
import * as Images from "src/ipm-shared/components/Images";

import styles from "./InvoiceTemplate.module.scss";

const optionDate = {
  day: "numeric",
  month: "short",
  year: "numeric"
};

function InvoiceTemplate(props: any) {
  const { invoice, customer, currencyId, taxes, currentTypeInvoice } = props;
  let amountDue = invoice.grossAmount;
  let subTotal = invoice.grossAmount;
  const itemsInvoice = invoice.items;
  const taxSelected: any[] = [];
  if (itemsInvoice.length > 0) {
    amountDue = 0;
    subTotal = 0;
    itemsInvoice.forEach((item: any) => {
      let itemAmount = item.amount;
      if (
        invoice.calculateMethod === "discount_all_item" ||
        invoice.calculateMethod === "discount_tax_all_item"
      ) {
        itemAmount = itemAmount * item.quantity;
      }
      if (item.discount) {
        if (item.discountType === "amount") {
          itemAmount = itemAmount - item.discount;
        } else if (item.discountType === "percentage") {
          itemAmount = itemAmount - (itemAmount * item.discount) / 100;
        }
      }
      if (
        invoice.calculateMethod === "discount_tax_per_item" ||
        invoice.calculateMethod === null
      ) {
        subTotal += itemAmount * item.quantity;
      } else {
        subTotal += itemAmount;
      }
      if (item.taxId && taxes.length > 0) {
        const data = taxes.find((t: any) => t.id === item.taxId);
        taxSelected.push({
          amount: itemAmount * (data.rate / 100) * item.quantity,
          name: data.name,
          rate: data.rate,
          taxId: item.taxId
        });
        itemAmount = itemAmount * (1 + data.rate / 100);
      }
      if (
        invoice.calculateMethod === "discount_tax_per_item" ||
        invoice.calculateMethod === null
      ) {
        amountDue += itemAmount * item.quantity;
      } else {
        amountDue += itemAmount;
      }
    });
  }

  const taxSelectedGroup = taxSelected.reduce((acc, tax) => {
    const taxFound = acc.find((item: any) => item.taxId === tax.taxId);
    if (taxFound) {
      return acc.map((item: any) => ({
        ...item,
        amount: tax.amount + item.amount
      }));
    }
    return [...acc, tax];
  }, []);

  const normalTemplateContent = (
    <div className={styles.itemService}>
      <div className={classnames(styles.flexRow, styles.itemServiceHeading)}>
        <div>
          <span className={styles.itemServiceTitle}>Item or service</span>
        </div>
        <div>
          <span className={styles.itemServiceTitle}>Amount</span>
        </div>
      </div>
      {itemsInvoice.map((item: any) => {
        let finalAmount = item.amount;
        if (
          invoice.calculateMethod === "discount_all_item" ||
          invoice.calculateMethod === "discount_tax_all_item"
        ) {
          finalAmount = finalAmount * item.quantity;
        }
        if (item.discount) {
          if (item.discountType === "amount") {
            finalAmount = finalAmount - item.discount;
          } else if (item.discountType === "percentage") {
            finalAmount = finalAmount - (finalAmount * item.discount) / 100;
          }
        }
        if (
          invoice.calculateMethod === "discount_tax_per_item" ||
          invoice.calculateMethod === null
        ) {
          finalAmount = finalAmount * item.quantity;
        }

        return (
          <div
            key={item.uid}
            className={classnames(styles.flexRow, styles.itemServiceValueRow)}
          >
            <div>
              <span className={styles.itemServiceName}>
                {item.name} (x{item.quantity})
              </span>
            </div>
            <div>
              <span className={styles.itemServiceValue}>
                <ShortCurrency
                  value={Math.round(finalAmount)}
                  currencyId={currencyId}
                />
              </span>
            </div>
          </div>
        );
      })}
    </div>
  );

  const quickTemplateContent = (
    <>
      <div className={classnames(styles.flexRow, styles.itemServiceHeading)}>
        <div>
          <span className={styles.itemServiceTitle}>Notes</span>
        </div>
        <div>
          <span className={styles.itemServiceTitle}>Amount</span>
        </div>
      </div>

      <div className={classnames(styles.flexRow, styles.itemServiceValueRow)}>
        <div>
          <span className={styles.itemServiceName}>{invoice.notes}</span>
        </div>
        <div>
          <span className={styles.itemServiceValue}>
            <ShortCurrency
              value={Math.round(subTotal)}
              currencyId={currencyId}
            />
          </span>
        </div>
      </div>
    </>
  );

  const templateContent = () => {
    switch (currentTypeInvoice) {
      case "create":
        return normalTemplateContent;

      case "quick":
        return quickTemplateContent;

      default:
        return;
    }
  };

  return (
    <div className={styles.wrapper}>
      <div>
        <Image src={IconTemplateBlock} />
      </div>

      <div className={styles.templateContent}>
        <div className={classnames(styles.flexRow, styles.invoiceLogoHeading)}>
          <Image src={IconLogoPlaceHolder} />
          <div>
            <span className={styles.invoiceTitle}>INVOICE</span>
          </div>
        </div>

        <div className={styles.flexRow}>
          <div>
            <div>
              <span className={styles.title}>{invoice.companyName}</span>
            </div>
            <div>
              <span className={styles.value}>
                Reg No. {invoice.registrationNumber}
              </span>
            </div>
          </div>
          <div className={styles.textRight}>
            <div>
              <span className={styles.titleSm}>Amount Due</span>
            </div>
            <div>
              <span className={styles.amountDue}>
                <ShortCurrency
                  value={Math.round(amountDue)}
                  currencyId={currencyId}
                />
              </span>
            </div>
          </div>
        </div>

        <div className={classnames(styles.flexRow, styles.rowInfoInvoice)}>
          <div>
            <div>
              <span className={styles.title}>Bill to:</span>
            </div>
            <div>
              <span className={styles.value}>{customer.name}</span>
            </div>
          </div>
          <div className={styles.textRight}>
            <div>
              <span className={styles.titleSm}>Invoice Number: </span>
              <span className={styles.value}>{invoice.invoiceNumber}</span>
            </div>
            <div>
              <span className={styles.titleSm}>Invoice date: </span>
              <span className={styles.value}>
                {DateUtils.formatLocaleDate(invoice.invoiceDate, optionDate)}
              </span>
            </div>
            <div>
              <span className={styles.titleSm}>Due date: </span>
              <span className={styles.value}>
                {DateUtils.formatLocaleDate(invoice.dueDate, optionDate)}
              </span>
            </div>
          </div>
        </div>
        <div className={styles.itemService}>
          {templateContent()}

          <div
            className={classnames(styles.flexRow, styles.itemServiceValueRow)}
          >
            <div>
              <span className={styles.itemServiceName}>Subtotal</span>
            </div>
            <div>
              <span className={styles.itemServiceValue}>
                <ShortCurrency
                  value={Math.round(subTotal)}
                  currencyId={currencyId}
                />
              </span>
            </div>
          </div>

          {currentTypeInvoice === "create" &&
            taxSelectedGroup.map((item: any, idx: number) => {
              return (
                <div
                  key={idx}
                  className={classnames(
                    styles.flexRow,
                    styles.itemServiceValueRow
                  )}
                >
                  <div>
                    <span className={styles.itemServiceName}>
                      {item.name} ({item.rate}%)
                    </span>
                  </div>
                  <div>
                    <span className={styles.itemServiceValue}>
                      <ShortCurrency
                        value={Math.round(item.amount)}
                        currencyId={currencyId}
                      />
                    </span>
                  </div>
                </div>
              );
            })}

          <div
            className={classnames(styles.flexRow, styles.itemServiceTotalRow)}
          >
            <div>
              <span className={styles.itemServiceTitle}>Total Amount Due</span>
            </div>
            <div>
              <span className={styles.itemServiceTitle}>
                <ShortCurrency
                  value={Math.round(amountDue)}
                  currencyId={currencyId}
                />
              </span>
            </div>
          </div>

          {currentTypeInvoice === "create" && (
            <>
              {invoice.notes && (
                <div className={styles.rowBlock}>
                  <div>
                    <span className={styles.title}>Notes:</span>
                  </div>
                  <div>
                    <span className={styles.value}>{invoice.notes}</span>
                  </div>
                </div>
              )}

              {invoice.termAndCondition && (
                <div className={styles.rowBlock}>
                  <div>
                    <span className={styles.title}>Terms and conditions:</span>
                  </div>
                  <div>
                    <span className={styles.value}>
                      {invoice.termAndCondition}
                    </span>
                  </div>
                </div>
              )}
            </>
          )}

          <div className={styles.payNowBtn}>
            <button>Pay now</button>
          </div>

          <div className={styles.itemService}>
            <div>
              <span className={styles.title}>Accepted Payment Methods</span>
            </div>
            <div className={classnames(styles.itemServicePaymentMethod)}>
              <span>{"-"} Credit card</span>
              <div className={styles.icons}>
                <Image
                  className={styles.brandIcons}
                  alt={"icon"}
                  src={Images.card_icons}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default InvoiceTemplate;
