import React, { useEffect } from "react";
import _get from "lodash-es/get";

import Form from "src/bepaid/components/Form";

import { ADD_FORM } from "src/ipm-shared/store/model/Company/const";

import InputText from "src/bepaid/components/Form/controls/InputText";
import CompanyForm from "src/bepaid/layouts/Menu/components/Companies/components/CompanyForm";
import usePrevious from "src/bepaid/hooks/usePrevious";

import styles from "src/bepaid/components/Form/CommonForm.module.scss";

const CompanyInformationForm = (props: any) => {
  const commonFormItemProps = {
    reserveValueOnUnmount: true,
    revertValueOnMount: true
  };

  useEffect(() => {
    let recipentEmail = _get(data, "payeeData.recipientEmail", "");
    if (!recipentEmail) {
      props.setControl({
        form: ADD_FORM,
        name: "recipient_email",
        value: _get(props.accountProfile, "email", "")
      });
      props.setControl({
        form: ADD_FORM,
        name: "confirm_recipient_email",
        value: _get(props.accountProfile, "email", "")
      });
    } else {
      props.setControl({
        form: ADD_FORM,
        name: "recipient_email",
        value: recipentEmail
      });
      props.setControl({
        form: ADD_FORM,
        name: "confirm_recipient_email",
        value: recipentEmail
      });
    }
    props.setControl({
      form: ADD_FORM,
      name: "current_id",
      value: _get(data, "currencyId", props.accountCurrencyId)
    });
    props.setControl({
      form: ADD_FORM,
      name: "b_acra_bizfile",
      value: _get(data, "payeeData.kycDocuments.b_acra_bizfile", [])
        ? _get(data, "payeeData.kycDocuments.b_acra_bizfile", []).join(",")
        : null
    });
    props.setControl({
      form: ADD_FORM,
      name: "b_poba",
      value: _get(data, "payeeData.kycDocuments.b_poba", [])
        ? _get(data, "payeeData.kycDocuments.b_poba", []).join(",")
        : null
    });
    props.setControl({
      form: ADD_FORM,
      name: "b_structured_chart",
      value: _get(data, "payeeData.kycDocuments.b_structured_chart", [])
        ? _get(data, "payeeData.kycDocuments.b_structured_chart", []).join(",")
        : null
    });
    props.setControl({
      form: ADD_FORM,
      name: "b_letter_of_authorization",
      value: _get(data, "payeeData.kycDocuments.b_letter_of_authorization", [])
        ? _get(
            data,
            "payeeData.kycDocuments.b_letter_of_authorization",
            []
          ).join(",")
        : null
    });
    props.setControl({
      form: ADD_FORM,
      name: "b_director",
      value: _get(data, "payeeData.kycDocuments.b_director", [])
        ? _get(data, "payeeData.kycDocuments.b_director", []).join(",")
        : null
    });
    props.setControl({
      form: ADD_FORM,
      name: "b_shareholder",
      value: _get(data, "payeeData.kycDocuments.b_shareholder", [])
        ? _get(data, "payeeData.kycDocuments.b_shareholder", []).join(",")
        : null
    });
    props.setControl({
      form: ADD_FORM,
      name: "b_digital_signature",
      value: _get(data, "payeeData.kycDocuments.b_digital_signature", [])
        ? _get(data, "payeeData.kycDocuments.b_digital_signature", []).join(",")
        : null
    });
  }, []);
  const prevAccountProfile = usePrevious(props.accountProfile);
  useEffect(() => {
    props.setControl({
      form: ADD_FORM,
      name: "first_name",
      value: _get(props.accountProfile, "displayedFirstName", "")
    });
    props.setControl({
      form: ADD_FORM,
      name: "last_name",
      value: _get(props.accountProfile, "displayedLastName", "")
    });
  }, [props.accountProfile, prevAccountProfile]);

  const data = props.collectedAccount;
  useEffect(() => {
    props.setControl({
      form: ADD_FORM,
      name: "id",
      value: _get(props.currentCompany, "id", null)
    });
  }, [props.currentCompany]);
  return (
    <div className={styles.content} hidden={props.hidden}>
      <Form
        layout="vertical"
        requiredMark={false}
        className={styles.personalFormDetail}
      >
        <InputText
          name={"id"}
          form={ADD_FORM}
          defaultValue={_get(props.currentCompany, "id", null)}
          disabled={true}
          hidden={true}
          className={styles.hidden}
          {...commonFormItemProps}
        />
        <InputText
          form={ADD_FORM}
          name="country_code"
          hidden={true}
          className={styles.hidden}
          defaultValue={_get(props, "countryCode")}
          {...commonFormItemProps}
        />
        <div hidden={true}>
          <InputText
            form={ADD_FORM}
            name="account_id"
            defaultValue={_get(props.currentCompany, "accountId", null)}
            {...commonFormItemProps}
          />
        </div>
        <div hidden={true}>
          <InputText
            form={ADD_FORM}
            name="currency_id"
            defaultValue={props.accountCurrencyId}
          />
        </div>
        <CompanyForm
          disableBusiness={true}
          data={{
            companyData: {
              ...data,
              name: _get(props.currentCompany, "name", ""),
              code: _get(props.currentCompany, "registrationNumber", "")
            },
            formName: ADD_FORM,

            formOptions: {
              names: {
                businessIndustry: "business_industry",
                businessType: "business_type",
                companyName: "company_name",
                regNo: "registration_number",
                uenSearchCompany: "company_name"
              }
            }
          }}
        />
      </Form>
      <Form
        layout="vertical"
        requiredMark={false}
        className={styles.personalFormDetail}
      >
        <CompanyForm
          disableName={true}
          disableBusinessDescription={props.countryCode === "SG"}
          showCompanyWebsite={props.countryCode === "SG"}
          data={{
            companyData: {
              ...data,
              name: _get(props.currentCompany, "name", ""),
              code: _get(props.currentCompany, "registrationNumber", "")
            },
            formName: ADD_FORM,
            formOptions: {
              names: {
                businessIndustry: "business_industry",
                businessType: "business_type",
                companyName: "company_name",
                regNo: "registration_number",
                uenSearchCompany: "company_name",
                businessWebsite: "business_website"
              }
            }
          }}
        />
      </Form>
    </div>
  );
};
export default CompanyInformationForm;
