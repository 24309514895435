// Lib
import _find from "lodash-es/find";
import _get from "lodash-es/get";
import _isEmpty from "lodash-es/isEmpty";
import { call, put, select } from "redux-saga/effects";
import { ActionType } from "typesafe-actions";
import UrlUtils from "src/ipm-shared/Utils/UrlHelper";
import LogRocket from "logrocket";
import Is from "src/ipm-shared/Utils/Is";

// Utils
import FeatureGateUtil from "src/ipm-shared/components/FeatureGating/Util";
import RestClient from "src/ipm-shared/services/Rest";
import DateUtils from "src/ipm-shared/Utils/Date";
import HttpRequestError from "src/ipm-shared/Utils/Exceptions/HttpRequestError";
import AccountProfileUtil from "src/ipm-shared/Utils/AccountProfile";
import T from "src/ipm-shared/Utils/Intl";
import {
  reTryTakeLatest,
  catchTakeLatest
} from "src/ipm-shared/Utils/ReduxSagaEffects";
import IntercomUtil from "src/ipm-shared/Utils/Intercom";
import IPMContext from "src/ipm-shared/Utils/IPMContext";

// Store, Selectors, Actions
import { history } from "src/ipm-shared/store";
import { RootState } from "../reducers";
import { AccountProfile } from "./reducers";
import * as formSelectors from "src/ipm-shared/components/Form/selectors";
import * as guiSelectors from "src/ipm-shared/components/GlobalUI/selectors";
import * as accountProfileSelector from "src/ipm-shared/store/model/AccountProfile/selectors";
import * as countrySelector from "src/ipm-shared/store/model/Country/selectors";
import * as guiActions from "src/ipm-shared/components/GlobalUI/actions";
import * as authActions from "src/ipm-shared/store/model/Auth/actions";
import * as formActions from "src/ipm-shared/components/Form/actions";
import { ModalID } from "src/ipm-shared/components/GlobalUI/actions";
import * as actionsAccount from "./actions";
import * as companyActions from "../Company/actions";
import * as commonActions from "../actions";
import settingProps from "src/ipm-shared/store/metadata/user_setting_property";

import {
  GENDER,
  RESIDENTIAL_STATUS_LIST,
  PASS_TYPE
} from "src/ipm-shared/store/model/OnBoarding/consts";
import { FORM } from "src/ipm-shared/store/model/Payee/const";
import UrlHelper from "src/ipm-shared/Utils/UrlHelper";
import Cookie from "../../../Utils/Cookie";
import { MyInfoRetrievedIdentityForm } from "src/bepaid/pages/MyInfo/const";

const actions = {
  ...actionsAccount,
  ...companyActions,
  ...commonActions
};

const watchedSagas = [
  reTryTakeLatest(actions.fetchAccountProfiles, handleFetchAccountProfiles),
  reTryTakeLatest(actions.fetchNricPhotos, handleFetchNricPhotos),
  reTryTakeLatest(actions.getReferralReport, handleGetReferralReport),
  reTryTakeLatest(actions.fetchMyInfoSingPass, handleFetchMyInfoSingPass),
  reTryTakeLatest(actions.updateAccountProfile, handleUpdateProfile),

  catchTakeLatest(actions.changeUserSetting, handleChangeUserSetting),
  catchTakeLatest(actions.joinReferral, handleJoinReferral),
  catchTakeLatest(actions.setAccountType, handleSetAccountType),
  catchTakeLatest(actions.changeTheme, handleChangeTheme),
  catchTakeLatest(actions.setNricPhotos, handleSetNricPhotos),
  catchTakeLatest(actions.enterProgram, handleEnterProgram),
  catchTakeLatest(actions.loginMyInfoSingPass, handleLoginMyInfoSingPass),
  catchTakeLatest(actions.confirmMyInfoSingPass, handleConfirmMyInfoSingPass),
  catchTakeLatest(actions.closeAccount, handleCloseAccount),
  catchTakeLatest(actions.updateAdditionalData, handleUpdateAdditionalData)
];
export default watchedSagas;

export function* handleFetchAccountProfiles(
  action: ActionType<typeof actions.fetchAccountProfiles>
) {
  if (!action.payload.isSkipFetching) {
    yield put(
      actions.setAccountProfiles({
        hasFetched: false,
        isFetching: true,
        profiles: []
      })
    );
  }

  const res = yield call(RestClient.send, {
    service: "get_user_profile"
  });

  if (!res) {
    throw new HttpRequestError("Failed to fetch");
  }

  try {
    const state = yield select();
    const json = res;

    const getUserSettingRequiredValueByKey = (
      key: string,
      value: number = 1
    ): boolean => {
      const settingPropValue = _get(
        json.data,
        `setting_required.required.${settingProps[key] - 1}`,
        0
      );
      return Number(settingPropValue) === value;
    };

    const currentProfile = {
      accountCountryCode: accountProfileSelector.getAccountCountryCode(state),
      acquirerId: accountProfileSelector.getAcquirerId(state),
      availableAcquirers: json.data.available_acquirers,
      countryCode: accountProfileSelector.getCurrentCountry(state), // Get from access token
      countryId: accountProfileSelector.getCurrentCountryId(state), // Get from access token
      createdAt: json.data.created_at,
      currencyCode: accountProfileSelector.getCurrentCurrency(state), // Get from access token
      currencyId: accountProfileSelector.getCurrentCurrencyId(state), // Get from access token
      displayedEmail: json.data.sub_email || json.data.email,
      displayedFirstName: json.data.sub_first_name || json.data.first_name,
      displayedLastName: json.data.sub_last_name || json.data.last_name,
      email: json.data.email,
      emailAwaitingVerification: json.data.email_awaiting_verification,
      features: accountProfileSelector.getFeatures(),
      firstName: json.data.first_name,
      hasActiveProgram: json.data.has_active_program,
      hasWallexPayment: json.data.has_wallex_payment,
      hasIdVerification: json.data.has_id_verification,
      hasIncorporationDate: json.data.has_incorporation_date,
      hasCompany: accountProfileSelector.hasCompany(), // Get from access token
      hasCreditCard: json.data.has_credit_card,
      intercomIdentifier: json.data.hmac,
      isProduction: accountProfileSelector.getIsProduction(state), // Get from access token
      lastName: json.data.last_name,
      mobileCountryId: json.data.mobile_country_id,
      mobileNumber: json.data.mobile_number,
      paymentPaidCurrencyCode: accountProfileSelector.getCurrentPaidCurrency(),
      paymentPaidCurrencyId: accountProfileSelector.getCurrentPaidCurrencyId(),
      prefilledCompany: json.data.prefilled_company,
      roleId: accountProfileSelector.getRoleId(),
      type: AccountProfileUtil.getAccountTypeString(
        accountProfileSelector.getIsBusinessAccount()
      ),
      userSettingRequired: {
        accountType: getUserSettingRequiredValueByKey("account_type"),
        additionalInvoiceSetting: getUserSettingRequiredValueByKey(
          "additional_invoice_setting"
        ),
        bePaidTncConfirmation: _get(
          json.data,
          `setting_required.values.be_paid_tnc_confirmation`,
          undefined
        ),
        cardsPalPopup: getUserSettingRequiredValueByKey("cards_pal_popup"),
        closeKycStatusBar: getUserSettingRequiredValueByKey(
          "close_kyc_status_bar"
        ),
        creditProgramOfferV1Enabled: false,
        creditProgramV1Notification: false,
        crossBorder: getUserSettingRequiredValueByKey("cross_border_announce"),
        crossBorderCardFlag: false,
        crossBorderNewIcon: getUserSettingRequiredValueByKey(
          "cross_border_new_icon"
        ),
        dashboardTheme: _get(
          json.data,
          `setting_required.values.dashboard_theme`
        ),
        editScheduleIndicator: getUserSettingRequiredValueByKey(
          "edit_schedule_indicator"
        ),
        fb199: getUserSettingRequiredValueByKey("fb199"),
        firstMySignup: getUserSettingRequiredValueByKey("first_my_signup"),
        intPayEnabledNotification: getUserSettingRequiredValueByKey(
          "int_pay_enabled_notification"
        ),
        intPayReminderNotification: getUserSettingRequiredValueByKey(
          "int_pay_reminder_notification",
          0
        ),
        irasPopup: getUserSettingRequiredValueByKey("iras_popup"),
        myCreditCard: getUserSettingRequiredValueByKey("my_credit_card"),
        nextDayPayout: getUserSettingRequiredValueByKey("next_day_payout"),
        nricPhoto: getUserSettingRequiredValueByKey("nric_photo"),
        paymentInterestConfirmation: getUserSettingRequiredValueByKey(
          "payment_interest_confirmation"
        ),
        pointsGuarantee: getUserSettingRequiredValueByKey("points_guarantee"),
        referral: getUserSettingRequiredValueByKey("referral"),
        rentPromo: getUserSettingRequiredValueByKey("rent_promo"),
        sgMyInfo: getUserSettingRequiredValueByKey("sg_myinfo"),
        sgTax2019: getUserSettingRequiredValueByKey("sg_tax_2019"),
        twoDayPayoutAnnouncement: getUserSettingRequiredValueByKey(
          "two_day_payout_announce"
        ),
        kycFirstTimePopup: getUserSettingRequiredValueByKey(
          "kyc_first_time_popup"
        ),
        oldCardTokenArchivedNotification: getUserSettingRequiredValueByKey(
          "old_card_token_archived_notification"
        ),
        personalFirstLoginTourInPayFetch: getUserSettingRequiredValueByKey(
          "personal_first_login_tour_in_pay_fetch"
        ),
        businessFirstLoginPayTourInPayFetch: getUserSettingRequiredValueByKey(
          "business_first_login_pay_tour_in_pay_fetch"
        ),
        businessFirstLoginFetchTourInPayFetch: getUserSettingRequiredValueByKey(
          "business_first_login_fetch_tour_in_pay_fetch"
        ),
        fetchHighlightTour: getUserSettingRequiredValueByKey(
          "fetch_highlight_tour"
        ),
        showSuggestionModal: getUserSettingRequiredValueByKey(
          "show_suggestion_modal"
        ),
        amexPromoModal: getUserSettingRequiredValueByKey("amex_promo_modal"),
        additionalDataModal: getUserSettingRequiredValueByKey(
          "additional_data_modal"
        ),
        flashpayPromoModal: getUserSettingRequiredValueByKey(
          "flashpay_promo_modal"
        )
      },
      wallexKycStatus:
        json.data.wallex_kyc_status !== null &&
        json.data.wallex_kyc_status !== undefined
          ? Number(json.data.wallex_kyc_status)
          : 0,
      canAccessCryptoPayment: json.data.can_access_crypto_payment
    };

    const accounts: { [email: string]: AccountProfile } = {};
    accounts[currentProfile.email] = currentProfile as AccountProfile;
    const currentUserId = accountProfileSelector.getCurrentUserId(state);
    // @ts-ignore
    if (window.heap && Is.live()) {
      // @ts-ignore
      window.heap.identify(currentUserId);
    }
    yield put(
      actions.setCurrentAccountProfile({
        email: json.data.email
      })
    );

    yield put(
      actions.setAccountProfiles({
        hasFetched: true,
        isFetching: false,
        profiles: Object.keys(accounts).map(email => accounts[email])
      })
    );

    yield call(
      [localStorage, "setItem"],
      "displayed_first_name",
      currentProfile.displayedFirstName
    );

    yield call(
      [localStorage, "setItem"],
      "displayed_last_name",
      currentProfile.displayedLastName
    );

    if (action.payload.cb) {
      action.payload.cb(null, currentProfile);
    }

    // Intercom
    IntercomUtil.userInit(currentProfile);

    try {
      if (Cookie.getCookie("log_rocket_enabled") === "y") {
        LogRocket.identify(
          currentProfile.isProduction ? "" : "test_" + currentProfile.email,
          {
            name: currentProfile.firstName,
            email: currentProfile.email

            // Add your own custom user variables here, ie:
          }
        );
      }
    } catch (e) {
      window.Logger.error(e);
    }

    // Active campaign
    try {
      if (!window.vgo) {
        return;
      }
      window.vgo("setEmail", currentProfile.email);
      window.vgo("process");
      window.vgo("update");
    } catch (e) {
      window.Logger.guestError("Active campaign boot failed: ", e.toString());
    }

    // Current app is Fetch / Pay V2 ?
    if (IPMContext.isPayFetchPlatform()) {
      // currentProfile.type === "personal" &&
      // if (
      //   window.location.pathname === "/" &&
      //   currentProfile.accountCountryCode.toUpperCase() === "SG" &&
      //   currentProfile.userSettingRequired.sgMyInfo
      // ) {
      //   window.location.href = "/my-info";
      // }

      return;
    }

    // ---DISPLAY MODALS---

    // CardsPalPopup required

    if (
      currentProfile.userSettingRequired.cardsPalPopup &&
      currentProfile.accountCountryCode === "SG" &&
      currentProfile.type === "personal"
    ) {
      yield put(guiActions.toggleModal(ModalID.CARDSPAL_MODAL));
      return;
    }

    // Top priority: Payment interest confirmation
    if (
      currentProfile.hasCompany &&
      currentProfile.type === "business" &&
      currentProfile.userSettingRequired.paymentInterestConfirmation
    ) {
      const hasOpenedModal = guiSelectors.hasOpenedModal(state);

      if (!hasOpenedModal) {
        yield put(guiActions.toggleModal(ModalID.PAYMENT_INTEREST_MODAL));
        return;
      }
    }

    // For MY personal user, add them to have credit card.
    if (
      (currentProfile.hasCreditCard === null ||
        currentProfile.hasCreditCard === undefined) &&
      currentProfile.userSettingRequired.myCreditCard &&
      currentProfile.type === "personal"
    ) {
      const isModalOpened = guiSelectors.isGivenModalOpened(
        state,
        guiActions.ModalID.DO_YOU_HAVE_CREDIT_CARD
      );

      if (!isModalOpened) {
        yield put(
          guiActions.toggleModal(guiActions.ModalID.DO_YOU_HAVE_CREDIT_CARD, {
            guideDisplay: true
          })
        );
        return;
      }
    }

    // Legacy. To allow v1 user to choose to be business or personal account
    // if (currentProfile.userSettingRequired.accountType) {
    //   const isModalOpened = guiSelectors.isGivenModalOpened(
    //     state,
    //     guiActions.ModalID.USER_SELECT_ACCOUNT_TYPE
    //   );
    //
    //   if (!isModalOpened) {
    //     // Always display add-company modal in every pages.
    //     yield put(
    //       guiActions.toggleModal(
    //         guiActions.ModalID.USER_SELECT_ACCOUNT_TYPE,
    //         {
    //           disallowCancel: true,
    //           guideDisplay: true
    //         }
    //       )
    //     );
    //     return
    //   }
    // }

    // Some settings for business user having company added only
    if (currentProfile.hasCompany) {
      if (currentProfile.userSettingRequired.firstMySignup) {
        const isModalOpened = guiSelectors.isGivenModalOpened(
          state,
          guiActions.ModalID.ONE_TIME_MODAL
        );

        if (!isModalOpened) {
          yield put(
            guiActions.toggleModal(guiActions.ModalID.ONE_TIME_MODAL, {
              guideDisplay: true
            })
          );
          return;
        }
      }

      if (currentProfile.userSettingRequired.twoDayPayoutAnnouncement) {
        const isModalOpened = guiSelectors.isGivenModalOpened(
          state,
          guiActions.ModalID.TWO_DAY_ANNOUNCEMENT_MODAL
        );

        if (!isModalOpened) {
          yield put(
            guiActions.toggleModal(
              guiActions.ModalID.TWO_DAY_ANNOUNCEMENT_MODAL,
              {
                guideDisplay: true
              }
            )
          );
          return;
        }
      }

      if (currentProfile.userSettingRequired.nextDayPayout) {
        const isModalOpened = guiSelectors.isGivenModalOpened(
          state,
          guiActions.ModalID.NEXT_DAY_PAYOUT_MODAL
        );

        if (!isModalOpened) {
          yield put(
            guiActions.toggleModal(guiActions.ModalID.NEXT_DAY_PAYOUT_MODAL, {
              guideDisplay: true
            })
          );
          return;
        }
      }

      if (currentProfile.userSettingRequired.pointsGuarantee) {
        const isModalOpened = guiSelectors.isGivenModalOpened(
          state,
          guiActions.ModalID.POINTS_GUARANTEE_MODAL
        );

        if (!isModalOpened) {
          yield put(
            guiActions.toggleModal(guiActions.ModalID.POINTS_GUARANTEE_MODAL, {
              guideDisplay: true
            })
          );
          return;
        }
      }

      if (currentProfile.userSettingRequired.sgTax2019) {
        const isModalOpened = guiSelectors.isGivenModalOpened(
          state,
          guiActions.ModalID.TAX_PAYMENT_PROMOTION_MODAL
        );

        if (!isModalOpened) {
          yield put(
            guiActions.toggleModal(
              guiActions.ModalID.TAX_PAYMENT_PROMOTION_MODAL,
              {
                guideDisplay: true
              }
            )
          );
          return;
        }
      }

      if (currentProfile.userSettingRequired.rentPromo) {
        const isModalOpened = guiSelectors.isGivenModalOpened(
          state,
          guiActions.ModalID.RENT_PROMO_MODAL
        );

        if (!isModalOpened) {
          yield put(
            guiActions.toggleModal(guiActions.ModalID.RENT_PROMO_MODAL, {
              guideDisplay: true
            })
          );

          return;
        }
      }

      if (currentProfile.userSettingRequired.fb199) {
        const isModalOpened = guiSelectors.isGivenModalOpened(
          state,
          guiActions.ModalID.PROMO_POPUP
        );

        if (!isModalOpened) {
          yield put(
            guiActions.toggleModal(guiActions.ModalID.PROMO_POPUP, {
              guideDisplay: true
            })
          );
          return;
        }
      }

      if (currentProfile.userSettingRequired.creditProgramOfferV1Enabled) {
        if (currentProfile.userSettingRequired.creditProgramV1Notification) {
          const isModalOpened = guiSelectors.isGivenModalOpened(
            state,
            guiActions.ModalID.CREDIT_PROGRAM_V1_NOTIFICATION
          );

          if (!isModalOpened) {
            yield put(
              guiActions.toggleModal(
                guiActions.ModalID.CREDIT_PROGRAM_V1_NOTIFICATION,
                {
                  countryCode: currentProfile.accountCountryCode
                }
              )
            );
            return;
          }
        }
      }
    }

    // currentProfile.type === "personal" &&
    if (
      window.location.pathname === "/" &&
      currentProfile.accountCountryCode.toUpperCase() === "SG" &&
      currentProfile.userSettingRequired.sgMyInfo
    ) {
      const isModalOpened = guiSelectors.isGivenModalOpened(
        state,
        guiActions.ModalID.MY_INFO_VERIFICATION_MODAL
      );

      if (!isModalOpened) {
        yield put(
          guiActions.toggleModal(
            guiActions.ModalID.MY_INFO_VERIFICATION_MODAL,
            { forced: false }
          )
        );
        return;
      }
    }

    // Display KYC modal
    if (
      currentProfile.wallexKycStatus === 0 &&
      currentProfile.userSettingRequired.intPayEnabledNotification &&
      FeatureGateUtil.verifyFeature(currentProfile.features, "WALLEX_FLOW")
    ) {
      const isModalOpened = guiSelectors.isGivenModalOpened(
        state,
        guiActions.ModalID.INTERNATIONAL_REDIRECT_KYC_MODAL
      );

      if (!isModalOpened) {
        yield put(
          guiActions.toggleModal(
            guiActions.ModalID.INTERNATIONAL_REDIRECT_KYC_MODAL,
            {
              guideDisplay: true
            }
          )
        );
        return;
      }
    }

    // ---End of DISPLAY MODALS---
  } catch (e) {
    window.Logger.error("handleFetchAccountProfiles: ", e.message);
  }
}

export function* handleSetAccountType(
  action: ActionType<typeof actions.setAccountType>
) {
  yield put(guiActions.showGlobalLoader());

  const res = yield call(RestClient.send, {
    body: {
      account_type: action.payload.accountType
    },
    service: "update_account_type",
    showGlobalLoader: true
  });

  if (!res) {
    return;
  }

  yield put(actions.fetchAccountProfiles());
  yield put(
    commonActions.closeModal(commonActions.ModalID.USER_SELECT_ACCOUNT_TYPE)
  );
}

export function* handleSetNricPhotos(
  action: ActionType<typeof actions.setNricPhotos>
) {
  const { extraInfo } = action.payload;
  const state: RootState = yield select();
  const formState = formSelectors.getControls(state, FORM);

  const frontNricPhotos = _get(formState, "front_nric_photo.value", "");
  const backNricPhotos = _get(formState, "back_nric_photo.value", "");
  const holdingNricPhotos = _get(formState, "holding_nric_photo.value", "");

  yield put(guiActions.showGlobalLoader());

  const res = yield call(RestClient.send, {
    body: {
      nric_back: holdingNricPhotos.split(","),
      nric_front: [frontNricPhotos, backNricPhotos]
    },
    service: "add_nric_photo",
    showGlobalLoader: true
  });

  if (!res) {
    return;
  }

  yield put(
    commonActions.closeModal(commonActions.ModalID.NRIC_ID_PHOTOS_UPLOAD)
  );
  yield put(actions.fetchAccountProfiles());
  yield put(
    commonActions.toggleModal(commonActions.ModalID.ADD_CARD_FORM, extraInfo)
  );
}

export function* handleFetchNricPhotos(
  action: ActionType<typeof actions.fetchNricPhotos>
) {
  const { cb, id } = action.payload;
  const res = yield call(RestClient.send, {
    query: {
      user_id: id
    },
    service: "get_nric_photos"
  });

  if (!res) {
    return;
  }

  if (cb) {
    cb(res.data);
  }
}

export function* handleChangeUserSetting(
  action: ActionType<typeof actions.changeUserSetting>
) {
  const {
    enable,
    property,
    targetUrl,
    refreshDisable,
    modalID,
    value,
    disableGlobalLoader,
    fetchAccountProfile
  } = action.payload;

  const res = yield call(RestClient.send, {
    body: {
      enable,
      value
    },
    params: {
      property
    },
    service: "one_time_popup",
    showGlobalLoader: !disableGlobalLoader
  });

  if (!res) {
    return;
  }

  if (!refreshDisable) {
    if (targetUrl) {
      UrlHelper.redirect(targetUrl);
    } else {
      UrlHelper.redirect("/");
    }
  } else {
    yield put(
      actions.updateUserSetting(
        AccountProfileUtil.getUserSettingObjectPropName(property),
        value !== undefined ? value : enable
      )
    );

    if (modalID) {
      yield put(commonActions.closeModal(commonActions.ModalID[modalID]));
    }

    if (targetUrl) {
      history.push(targetUrl);
    }
  }

  if (fetchAccountProfile) {
    yield put(actions.fetchAccountProfiles());
  }
}

function* handleEnterProgram(action: ActionType<typeof actions.enterProgram>) {
  const res1: Response = yield call(RestClient.send, {
    params: {
      program: action.payload.programName
    },
    service: "enter_program",
    showGlobalLoader: true
  });

  if (!res1) {
    return;
  }

  const errors1 = _get(res1, "errors", {});

  if (!_isEmpty(errors1)) {
    return;
  }

  UrlUtils.redirect(`/?m=${ModalID.CREDIT_PROGRAM_CONFIRMED_MODAL}`);
}

function* handleJoinReferral(action: ActionType<typeof actions.joinReferral>) {
  const res: Response = yield call(RestClient.send, {
    service: "join_referral",
    showGlobalLoader: true
  });

  if (!res) {
    throw new HttpRequestError("Failed to get referral link");
  }

  try {
    const referralLink = _get(res, "data.referral_link", "");
    if (action.payload.cb) {
      action.payload.cb(referralLink);
    }
  } catch (e) {
    window.Logger.error("handleGetReferralLink: ", e.message);
  }
}

function* handleGetReferralReport(
  action: ActionType<typeof actions.getReferralReport>
) {
  const res: Response = yield call(RestClient.send, {
    service: "get_referral_report",
    showGlobalLoader: true
  });

  if (!res) {
    throw new HttpRequestError("Failed to get referral report");
  }

  try {
    const joined = _get(res, "data.joined", false);
    const numberSignUps = _get(res, "data.number_of_sign_ups", 0);
    const numberPersonalReferrals = _get(
      res,
      "data.number_of_personal_referrals",
      0
    );
    const numberBusinessReferrals = _get(
      res,
      "data.number_of_business_referrals",
      0
    );
    const referralLink = _get(res, "data.referral_link", 0);

    if (action.payload.cb) {
      action.payload.cb({
        joined,
        numberBusinessReferrals,
        numberPersonalReferrals,
        numberSignUps,
        referralLink
      });
    }
  } catch (e) {
    window.Logger.error("handleGetReferralReport: ", e.message);
  }
}

function* handleLoginMyInfoSingPass(
  action: ActionType<typeof actions.loginMyInfoSingPass>
) {
  const {
    isModal = true,
    redirectUrl = window.location.origin,
    isBiz
  } = action.payload;

  let redirectSuccess: string;
  let redirectFail: string;

  if (isModal) {
    redirectSuccess = `${redirectUrl}?m=${ModalID.MY_INFO_VERIFICATION_RETRIEVED_NRIC_MODAL}&t=0&rm=1`;
    redirectFail = `${redirectUrl}?m=${ModalID.MY_INFO_VERIFICATION_ERROR_MODAL}&t=0&rm=1`;
  } else {
    redirectSuccess = `${redirectUrl}&valid=1`;
    redirectFail = `${redirectUrl}&valid=0`;
  }

  const state: RootState = yield select();
  const formState = formSelectors.getControls(
    state,
    MyInfoRetrievedIdentityForm
  );

  const businessIndustry = _get(formState, "business_industry.value");

  const res = (Response = yield call(RestClient.send, {
    query: {
      redirect_fail: encodeURIComponent(redirectFail),
      redirect_success: encodeURIComponent(redirectSuccess),
      is_biz: isBiz ? "y" : "n",
      business_industry: isBiz ? businessIndustry : null
    },
    service: "login_my_info_sing_pass"
  }));

  if (!res) {
    return;
  }

  const errors = _get(res, "errors", {});

  if (!_isEmpty(errors)) {
    return;
  }
}

function* handleFetchMyInfoSingPass(
  action: ActionType<typeof actions.fetchMyInfoSingPass>
) {
  const state: RootState = yield select();
  const { isBiz } = action.payload;

  const res = (Response = yield call(RestClient.send, {
    query: {
      is_biz: isBiz ? "y" : "n"
    },
    service: "fetch_my_info_sing_pass"
  }));

  if (!res) {
    return;
  }

  const error = _get(res, "error", {});

  if (!_isEmpty(error)) {
    return;
  }

  if (action.payload.cb) {
    const personalInfo = isBiz
      ? _get(res, "data.info.person")
      : _get(res, "data.info");

    const entity = _get(res, "data.info.entity");
    let formattedInfo = null;
    let businessFormattedInfo = null;

    if (personalInfo) {
      const nric = _get(personalInfo, "uinfin.value", "");
      const name = _get(personalInfo, "name.value", "");
      const principalName = _get(personalInfo, "name.value", "");

      const sex = _get(
        _find(GENDER, { code: _get(personalInfo, "sex.code") }),
        "label",
        ""
      );

      const dob = _get(personalInfo, "dob.value")
        ? DateUtils.formatDate(_get(personalInfo, "dob.value"), "DD MMMM YYYY")
        : "";

      const residentialStatus = _get(
        _find(RESIDENTIAL_STATUS_LIST, {
          code: _get(personalInfo, "residentialstatus.code")
        }),
        "label",
        ""
      );

      const nationality = _get(
        _find(countrySelector.getAllCountries(state), {
          code: (_get(personalInfo, "nationality.code") || "").toUpperCase()
        }),
        "name",
        ""
      );

      const passportNumber = _get(personalInfo, "passportnumber.value", "");

      const passportExpiryDate = _get(personalInfo, "passportexpirydate.value")
        ? DateUtils.formatDate(
            _get(personalInfo, "passportexpirydate.value"),
            "DD MMMM YYYY"
          )
        : "";

      const passType = _get(
        _find(PASS_TYPE, {
          code: _get(personalInfo, "passtype.code")
        }),
        "label",
        ""
      );

      const passExpiryDate = _get(personalInfo, "passexpirydate.value")
        ? DateUtils.formatDate(
            _get(personalInfo, "passexpirydate.value"),
            "DD MMMM YYYY"
          )
        : "";

      const mobileNumber = accountProfileSelector.getMobileNumber(state);

      const postalCode = _get(personalInfo, "regadd.postal.value", "");

      const registeredAddress = (() => {
        let value = "";
        const regAdd = _get(personalInfo, "regadd");
        if (regAdd) {
          const unit = _get(regAdd, "unit.value");
          if (unit) {
            value += unit;
          }
          const block = _get(regAdd, "block.value");
          if (block) {
            value += ` ${block}`;
          }
          const building = _get(regAdd, "building.value");
          if (building) {
            value += `${value ? "," : ""} ${building}`;
          }
          const street = _get(regAdd, "street.value");
          if (street) {
            value += `${value ? "," : ""} ${street}`;
          }
          const postal = _get(regAdd, "postal.value");
          if (postal) {
            value += `${value ? "," : ""} ${postal}`;
          }
        }

        return value;
      })();

      const unitNumber = _get(personalInfo, "regadd.unit.value", "");

      formattedInfo = {
        registeredAddress,
        dob,
        mobileNumber,
        name,
        principalName,
        nationality,
        nric,
        passExpiryDate,
        passType,
        passportExpiryDate,
        passportNumber,
        postalCode,
        residentialStatus,
        sex,
        unitNumber
      };
    }

    if (entity) {
      const basicProfile = _get(entity, "basic-profile");
      const uen = _get(basicProfile, "uen.value");
      const entityName = _get(basicProfile, "entity-name.value");
      const entityType = _get(basicProfile, "entity-type.code");
      const entityStatus = _get(basicProfile, "entity-status.value");
      const businessConstitution = _get(
        basicProfile,
        "business-constitution.code"
      );
      const companyType = _get(basicProfile, "company-type.code");
      const countryOfIncorporation = _get(
        _find(countrySelector.getAllCountries(state), {
          code: (
            _get(basicProfile, "country-of-incorporation.code") || ""
          ).toUpperCase()
        }),
        "name",
        ""
      );
      const ownership = _get(basicProfile, "ownership.desc");
      const registrationDate = _get(basicProfile, "registration-date.value");
      const businessExpiryDate = _get(
        basicProfile,
        "business-expiry-date.value"
      );
      const primaryActivityCode = _get(basicProfile, "primary-activity.code");
      const primaryActivityDescription = _get(
        basicProfile,
        "primary-activity.desc"
      );
      const secondaryActivityCode = _get(
        basicProfile,
        "secondary-activity.code"
      );
      const secondaryActivityDescription = _get(
        basicProfile,
        "secondary-activity.desc"
      );
      const entityProfile = {
        uen,
        entityName,
        entityType,
        entityStatus,
        businessConstitution,
        companyType,
        countryOfIncorporation,
        ownership,
        registrationDate,
        businessExpiryDate,
        primaryActivityCode,
        primaryActivityDescription,
        secondaryActivityCode,
        secondaryActivityDescription
      };

      const addresses = _get(entity, "addresses.addresses-list[0]");
      const standardAddress = _get(addresses, "postal.value");
      const address = (() => {
        let value = "";
        if (addresses) {
          const unit = _get(addresses, "unit.value");
          if (unit) {
            value += unit;
          }
          const block = _get(addresses, "block.value");
          if (block) {
            value += ` ${block}`;
          }
          const building = _get(addresses, "building.value");
          if (building) {
            value += `${value ? "," : ""} ${building}`;
          }
          const street = _get(addresses, "street.value");
          if (street) {
            value += `${value ? "," : ""} ${street}`;
          }
        }

        return value;
      })();
      const foreignAddressUnformatted = "";
      const entityAddress = {
        address,
        standardAddress,
        foreignAddressUnformatted
      };

      const previousUENs = _get(
        entity,
        "previous-uens.previous-uens-list"
      )?.map((pn: any) => ({
        previous_uen: _get(pn, "previous-uen.value")
      }));

      const previousNames = _get(
        entity,
        "previous-names.previous-names-list"
      )?.map((pn: any) => ({
        history_name: _get(pn, "history-name.value"),
        history_name_effective_date: _get(
          pn,
          "history-name-effective-date.value"
        )
      }));

      const shareholdersList = _get(
        entity,
        "shareholders.shareholders-list"
      )?.map((e: any) => ({
        idno: _get(e, "person-reference.idno.value"),
        person_name: _get(e, "person-reference.person-name.value"),
        nationality: _get(
          _find(countrySelector.getAllCountries(state), {
            code: (
              _get(e, "person-reference.nationality.code") || ""
            ).toUpperCase()
          }),
          "name",
          ""
        ),
        corppass_email: _get(e, "corppass-email.value"),
        corppass_mobileno: _get(e, "corppass-mobileno.value"),
        share_type: _get(e, "share-type.desc"),
        allocation: _get(e, "allocation.value"),
        currency: _get(e, "currency.code")
      }));

      const appointmentsList = _get(
        entity,
        "appointments.appointments-list"
      )?.map((e: any) => ({
        idno: _get(e, "person-reference.idno.value"),
        person_name: _get(e, "person-reference.person-name.value"),
        nationality: _get(
          _find(countrySelector.getAllCountries(state), {
            code: (
              _get(e, "person-reference.nationality.code") || ""
            ).toUpperCase()
          }),
          "name",
          ""
        ),
        category: _get(e, "category.desc"),
        position: _get(e, "position.desc"),
        appointment_date: _get(e, "appointment-date.value")
      }));

      const postalCode = _get(addresses, "postal.value");

      businessFormattedInfo = {
        ...formattedInfo,
        previousNames,
        previousUENs,
        shareholdersList,
        appointmentsList,
        postalCode,
        ...entityProfile,
        ...entityAddress
      };
    }

    action.payload.cb({
      confirmed: _get(res, "data.confirmed"),
      info: isBiz ? businessFormattedInfo : formattedInfo
    });
  }
}

function* handleConfirmMyInfoSingPass(
  action: ActionType<typeof actions.confirmMyInfoSingPass>
) {
  const { isBiz, loadingFunc = () => null, isRemove } = action.payload;
  const state: RootState = yield select();
  const formState = formSelectors.getControls(
    state,
    MyInfoRetrievedIdentityForm
  );

  const businessIndustry = _get(formState, "business_industry.value");
  const businessType = _get(formState, "business_type.value");
  const businessWebsite = _get(formState, "business_website.value");
  const bAcraBizFile = _get(formState, "b_poba.value");
  const bStructuredChart = _get(formState, "b_structured_chart.value");
  const bLetterOfAuthorization = _get(
    formState,
    "b_letter_of_authorization.value"
  );
  const bDigitalSignature = _get(formState, "b_digital_signature.value");
  const bShareholder = _get(formState, "b_shareholder_id.value");

  const res = (Response = yield call(RestClient.send, {
    body: {
      is_biz: isBiz,
      business_industry: isBiz ? businessIndustry : null,
      is_remove: isRemove,
      business_website: isBiz ? businessWebsite : null,
      business_type: isBiz ? businessType : null,
      b_acra_bizfile: isBiz ? bAcraBizFile?.split(",") : null,
      b_structured_chart: isBiz ? bStructuredChart?.split(",") : null,
      b_letter_of_authorization: isBiz
        ? bLetterOfAuthorization?.split(",")
        : null,
      b_digital_signature: isBiz ? bDigitalSignature?.split(",") : null,
      b_shareholder: isBiz ? bShareholder?.split(",") : null
    },
    service: "confirm_my_info_sing_pass"
  }));

  if (!res) {
    return;
  }
  if (loadingFunc) {
    loadingFunc(false);
  }
  const error = _get(res, "errors", {});

  if (!_isEmpty(error)) {
    yield put(
      formActions.parseServerErrors(error, MyInfoRetrievedIdentityForm)
    );
    if (action.payload.cb) {
      action.payload.cb({ confirmed: false, error: error });
    }
    return;
  }
  yield put(actions.fetchCompanies(true));
  if (action.payload.cb) {
    const data = _get(res, "data");
    if (data === "") {
      action.payload.cb({ confirmed: true });
    }
  }
}

function* handleChangeTheme(action: ActionType<typeof actions.changeTheme>) {
  const res = (Response = yield call(RestClient.send, {
    body: {
      dashboard_theme: action.payload.themeName
    },
    service: "change_theme",
    showGlobalLoader: true
  }));

  if (!res) {
    return;
  }

  const error = _get(res, "error", {});
  const data = _get(res, "data", {});

  if (!_isEmpty(error)) {
    return;
  }

  yield call([localStorage, "setItem"], "token", data.token);
  yield put(authActions.setToken({ token: data.token }));

  if (action.payload.targetUrl) {
    UrlUtils.redirect(action.payload.targetUrl);
  } else {
    UrlUtils.redirect("/");
  }
}

function* handleUpdateProfile(
  action: ActionType<typeof actions.updateAccountProfile>
) {
  const { form, cb = () => null } = action.payload;

  const state: RootState = yield select();
  const formState = formSelectors.getControls(state, form);

  const email = _get(formState, "email.value");
  const firstName = _get(formState, "firstName.value");
  const lastName = _get(formState, "lastName.value");

  const setRequiredField = (name: string) => {
    return formActions.setControl({
      errors: [
        {
          code: "REQUIRED_FIELD",
          message: T.transl("REQUIRED_FIELD")
        }
      ],
      form,
      name
    });
  };

  if (!firstName) {
    yield put(setRequiredField("firstName"));
  }
  if (!lastName) {
    yield put(setRequiredField("lastName"));
  }
  if (!firstName || !lastName) {
    return;
  }

  const body = {
    email,
    first_name: firstName,
    last_name: lastName
  };

  const res = (Response = yield call(RestClient.send, {
    body,
    service: "update_user_profile"
  }));

  if (!res) {
    return;
  }

  const errors = _get(res, "errors", {});

  if (!_isEmpty(errors)) {
    yield put(formActions.parseServerErrors(errors, form));
    return;
  }

  cb();
}

function* handleCloseAccount(action: ActionType<typeof actions.closeAccount>) {
  const { data, cb = () => null } = action.payload;

  try {
    const body = {
      explanation: data.reason,
      reason_id: data.option && data.option.id
    };

    const res = (Response = yield call(RestClient.send, {
      body,
      service: "close_account"
    }));

    if (!res) {
      return;
    }

    const error = _get(res, "error", {});

    if (error) {
      return;
    }

    cb();
  } catch (err) {
    window.Logger.error(err);
  }
}

function* handleUpdateAdditionalData(
  action: ActionType<typeof actions.updateAdditionalData>
) {
  const { form, cb = () => null } = action.payload;

  const state: RootState = yield select();
  const formState = formSelectors.getControls(state, form);

  const isSingaporeAccount = accountProfileSelector.isSingaporeAccount(state);

  const fullLegalName = _get(formState, "full_legal_name.value");
  const date = _get(formState, "date.value");
  const month = _get(formState, "month.value");
  const year = _get(formState, "year.value");
  const nationality = _get(formState, "nationality.value");
  const occupation = _get(formState, "occupation.value");
  const industry = _get(formState, "industry.value");
  const tellMoreData = _get(formState, "tell_more_data.value");
  const supportingDocuments = _get(formState, "supporting_documents.value");

  const setRequiredField = (name: string) => {
    return formActions.setControl({
      errors: [
        {
          code: "REQUIRED_FIELD",
          message: T.transl("REQUIRED_FIELD")
        }
      ],
      form,
      name
    });
  };

  if (!isSingaporeAccount) {
    if (!fullLegalName) {
      yield put(setRequiredField("full_legal_name"));
    }
    if (!date) {
      yield put(setRequiredField("date"));
    }
    if (!month) {
      yield put(setRequiredField("month"));
    }
    if (!year) {
      yield put(setRequiredField("year"));
    }
    if (!nationality) {
      yield put(setRequiredField("nationality"));
    }
    if (!occupation) {
      yield put(setRequiredField("occupation"));
    }
    if (occupation === 70 && !tellMoreData) {
      yield put(setRequiredField("tell_more_data"));
    }
    if (!industry) {
      yield put(setRequiredField("industry"));
    }
    if (!supportingDocuments) {
      yield put(setRequiredField("supporting_documents"));
    }

    if (
      !fullLegalName ||
      !date ||
      !month ||
      !year ||
      !nationality ||
      !occupation ||
      !industry ||
      (occupation === 70 && !tellMoreData) ||
      !supportingDocuments
    ) {
      return;
    }
  } else {
    if (!occupation) {
      yield put(setRequiredField("occupation"));
    }
    if (occupation === 70 && !tellMoreData) {
      yield put(setRequiredField("tell_more_data"));
    }
    if (!industry) {
      yield put(setRequiredField("industry"));
    }

    if (!occupation || !industry || (occupation === 70 && !tellMoreData)) {
      return;
    }
  }

  var dob;

  if (!isSingaporeAccount) {
    dob = year + "-" + month + "-" + date;
  }

  const body = {
    full_legal_name: fullLegalName,
    dob: dob,
    nationality: nationality,
    occupation: occupation,
    industry: industry,
    tell_more_data: tellMoreData
  };

  const res = (Response = yield call(RestClient.send, {
    body,
    service: "update_additional_data"
  }));

  if (!res) {
    return;
  }

  const errors = _get(res, "errors", {});

  if (!_isEmpty(errors)) {
    yield put(formActions.parseServerErrors(errors, form));
    return;
  }

  cb();
}
