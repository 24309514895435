// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SignaturePad_groupBtn__3rqDa{max-width:60%;padding:0 !important;display:-ms-flexbox;display:flex}.SignaturePad_groupBtn__3rqDa .ant-btn{height:auto !important}.SignaturePad_groupBtn__3rqDa .ant-btn span{font-weight:500 !important;font-size:.75rem}.SignaturePad_groupBtn__3rqDa .ant-btn-secondary{color:#c40698;border:none !important}", "",{"version":3,"sources":["webpack://./src/bepaid/components/Form/controls/SignaturePad/SignaturePad.module.scss"],"names":[],"mappings":"AAAA,8BAAU,aAAa,CAAC,oBAAoB,CAAC,mBAAW,CAAX,YAAY,CAAC,uCAA2B,sBAAsB,CAAC,4CAAgC,0BAA0B,CAAC,gBAAgB,CAAC,iDAAqC,aAAa,CAAC,sBAAsB","sourcesContent":[".groupBtn{max-width:60%;padding:0 !important;display:flex}.groupBtn :global .ant-btn{height:auto !important}.groupBtn :global .ant-btn span{font-weight:500 !important;font-size:.75rem}.groupBtn :global .ant-btn-secondary{color:#c40698;border:none !important}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"groupBtn": "SignaturePad_groupBtn__3rqDa"
};
export default ___CSS_LOADER_EXPORT___;
