// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DisabledTextField_wrapper__1ns_T{margin:0;padding:0;color:#242424;font-size:14px;margin-bottom:24px;vertical-align:top}.DisabledTextField_wrapper__1ns_T .DisabledTextField_label__3m1q5{padding:0 0 8px;line-height:1;white-space:normal;white-space:initial;text-align:left;color:#000000d9;font-size:12px}", "",{"version":3,"sources":["webpack://./src/bepaid/pages/MyInfo/components/DisabledTextField/DisabledTextField.module.scss"],"names":[],"mappings":"AAAA,kCAAS,QAAQ,CAAC,SAAS,CAAC,aAAa,CAAC,cAAc,CAAC,kBAAkB,CAAC,kBAAkB,CAAC,kEAAgB,eAAe,CAAC,aAAa,CAAC,kBAAkB,CAAC,mBAAmB,CAAC,eAAe,CAAC,eAAe,CAAC,cAAc","sourcesContent":[".wrapper{margin:0;padding:0;color:#242424;font-size:14px;margin-bottom:24px;vertical-align:top}.wrapper .label{padding:0 0 8px;line-height:1;white-space:normal;white-space:initial;text-align:left;color:#000000d9;font-size:12px}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "DisabledTextField_wrapper__1ns_T",
	"label": "DisabledTextField_label__3m1q5"
};
export default ___CSS_LOADER_EXPORT___;
