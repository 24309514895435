import React, { useState, useEffect } from "react";
import StyledCalendar from "../StyledCalendar";
import CalendarInput from "src/bepaid/components/Calendar/components/CalendarInput";
import Button from "src/bepaid/components/Form/controls/Button";
import Image from "src/bepaid/components/Image";

import CalendarIcon from "src/bepaid/assets/images/common/calendar.svg";
import FlashIcon from "src/bepaid/assets/images/common/icon_flash.svg";
import FlashIconHover from "src/bepaid/assets/images/common/icon_flash_hover.svg";
import FlashIconDisable from "src/bepaid/assets/images/common/icon_flash_disable.svg";
import InstantIcon from "src/bepaid/assets/images/common/icon_instant.svg";
import InstantIconHover from "src/bepaid/assets/images/common/icon_instant_hover.svg";
import InstantIconDisable from "src/bepaid/assets/images/common/icon_instant_disable.svg";
import Tooltip from "src/ipm-shared/components/Tooltip";

import DateUtils from "src/ipm-shared/Utils/Date";
import styles from "./ScheduleForm.module.scss";
import classNames from "classnames";

const OneTimeDateSelect = ({
  loading,
  value,
  onSelectDate, // this for on click a date in calendar
  earliestDate,
  flashPayDate,
  instantPayDate,
  exclusionDates,
  pspNonWorkingDates,
  chargeDate,
  allowWeekends,
  label,
  defaultValue,
  isEndDayNote,
  isAUDeadline,
  isCustomCalendar,
  showTooltip,
  flashPayInfo,
  showFlashPay,
  showFlashPayNote,
  instantPayInfo,
  showInstantPay,
  showInstantPayNote,
  countryCode
}: any) => {
  const [isDatePickerOpen, openDatePicker] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const SGT = new Date(
    new Date().toLocaleDateString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      timeZone: "Asia/Singapore"
    })
  );
  const isDisableFlashPayDate =
    (countryCode === "AU" &&
      (SGT.getHours() > 16 ||
        (SGT.getHours() === 16 && SGT.getMinutes() > 30))) ||
    (countryCode !== "AU" && SGT.getHours() >= 23);

  const isDisableInstantPayDate =
    (SGT.getHours() === 0 && SGT.getMinutes() < 1) || SGT.getHours() > 12;

  const handleSetSelectedOption = () => {
    if (DateUtils.isTwoDateEqual(value, earliestDate)) {
      return setSelectedOption("T+2");
    }
    if (
      !isDisableFlashPayDate &&
      showFlashPay &&
      DateUtils.isTwoDateEqual(value, flashPayDate)
    ) {
      return setSelectedOption("T+1");
    }
    if (
      !isDisableInstantPayDate &&
      showInstantPay &&
      DateUtils.isTwoDateEqual(value, instantPayDate)
    ) {
      return setSelectedOption("T+0");
    }
    setSelectedOption("Schedule");
  };

  useEffect(() => {
    var earliest;
    if (!isNaN(instantPayDate) && showInstantPay && !isDisableInstantPayDate) {
      earliest = instantPayDate;
    } else if (!isNaN(flashPayDate) && showFlashPay && !isDisableFlashPayDate) {
      earliest = flashPayDate;
    } else {
      earliest = earliestDate;
    }
    if (
      earliestDate &&
      (!value ||
        (value && new Date(earliest).getTime() > new Date(value).getTime()))
    ) {
      onSelectDate(earliestDate);
    }
    handleSetSelectedOption();
  }, [earliestDate, value]);

  const internalSelectDate = (d: any) => {
    onSelectDate(d);
    openDatePicker(false);
    handleSetSelectedOption();
  };

  return (
    <React.Fragment>
      {!showFlashPay && !showInstantPay ? (
        <CalendarInput
          label={label ? label : "Payout date"}
          labelClass={styles.calendarLabel}
          btnClass={styles.calendarInput}
          iconClass={styles.iconCalendar}
          value={value}
          defaultValue={defaultValue}
          onClick={openDatePicker.bind(null, true)}
        />
      ) : (
        <div className={styles.scheduleButtons}>
          {showInstantPay && (
            <>
              <div
                className={classNames(styles.scheduleButtonWrapper, {
                  [styles.scheduleButtonActive]: selectedOption === "T+0",
                  [styles.scheduleButtonDisable]: isDisableInstantPayDate
                })}
              >
                <Button
                  htmlType="button"
                  onClick={() => {
                    if (!isDisableInstantPayDate) {
                      setSelectedOption("T+0");
                      onSelectDate(instantPayDate);
                    }
                  }}
                  className={styles.scheduleButton}
                >
                  {isDisableInstantPayDate && (
                    <Tooltip
                      target={"btn-instant-pay-date"}
                      displayIcon={false}
                      description={
                        <div className="flash-pay-date-description">
                          <b>Instant Pay</b>
                          <span>
                            {instantPayInfo.instantPayAmountTooltipDisable}
                          </span>
                        </div>
                      }
                      className={styles.tooltip}
                    />
                  )}
                  <div
                    className={styles.scheduleButtonContent}
                    id={"btn-instant-pay-date"}
                  >
                    <span className={styles.scheduleButtonContentDate}>
                      {instantPayDate
                        ? DateUtils.formatDate(instantPayDate, "DD MMM YYYY")
                        : "..."}
                    </span>
                    <span className={styles.scheduleButtonContentDescription}>
                      Instant Pay
                    </span>
                    <div className={styles.flashPayAmount}>
                      {instantPayInfo.instantPayAmount}
                      <Image
                        src={
                          isDisableInstantPayDate
                            ? InstantIconDisable
                            : selectedOption === "T+0"
                            ? InstantIconHover
                            : InstantIcon
                        }
                      />
                    </div>
                  </div>
                </Button>
              </div>
            </>
          )}
          <div
            className={classNames(styles.scheduleButtonWrapper, {
              [styles.scheduleButtonActive]: selectedOption === "T+1",
              [styles.scheduleButtonDisable]: isDisableFlashPayDate
            })}
          >
            <Button
              htmlType="button"
              onClick={() => {
                if (!isDisableFlashPayDate) {
                  setSelectedOption("T+1");
                  onSelectDate(flashPayDate);
                }
              }}
              className={styles.scheduleButton}
            >
              {isDisableFlashPayDate && (
                <Tooltip
                  target={"btn-flash-pay-date"}
                  displayIcon={false}
                  description={
                    <div className="flash-pay-date-description">
                      <b>Flash Pay</b>
                      <span>{flashPayInfo.flashPayAmountTooltipDisable}</span>
                    </div>
                  }
                  className={styles.tooltip}
                />
              )}
              <div
                className={styles.scheduleButtonContent}
                id={"btn-flash-pay-date"}
              >
                <span className={styles.scheduleButtonContentDate}>
                  {flashPayDate
                    ? DateUtils.formatDate(flashPayDate, "DD MMM YYYY")
                    : "..."}
                </span>
                <span className={styles.scheduleButtonContentDescription}>
                  Flash Pay
                </span>
                <div className={styles.flashPayAmount}>
                  {flashPayInfo.flashPayAmount}
                  <Image
                    src={
                      isDisableFlashPayDate
                        ? FlashIconDisable
                        : selectedOption === "T+1"
                        ? FlashIconHover
                        : FlashIcon
                    }
                  />
                </div>
              </div>
            </Button>
          </div>

          <div
            className={classNames(styles.scheduleButtonWrapper, {
              [styles.scheduleButtonActive]: selectedOption === "T+2"
            })}
          >
            <Button
              htmlType="button"
              onClick={() => {
                setSelectedOption("T+2");
                onSelectDate(earliestDate);
              }}
              className={styles.scheduleButton}
            >
              <div className={styles.scheduleButtonContent}>
                <span className={styles.scheduleButtonContentDate}>
                  {earliestDate
                    ? DateUtils.formatDate(earliestDate, "DD MMM YYYY")
                    : "..."}
                </span>
                <span className={styles.scheduleButtonContentDescription}>
                  Standard
                </span>
                <span className={styles.flashPayAmount}></span>
              </div>
            </Button>
          </div>
          <div
            className={classNames(styles.scheduleButtonWrapper, {
              [styles.scheduleButtonActive]: selectedOption === "Schedule"
            })}
          >
            <Button
              htmlType="button"
              onClick={openDatePicker.bind(null, true)}
              className={styles.scheduleButton}
            >
              <div className={styles.scheduleButtonContent}>
                <span>Schedule</span>
                <Image src={CalendarIcon} />
              </div>
            </Button>
          </div>
          {showInstantPayNote && (
            <p className={styles.warningText}>
              {
                "Note: Instant or Flash Pay is only applicable to Visa and Mastercard."
              }
            </p>
          )}
          {!showInstantPayNote && showFlashPayNote && (
            <p className={styles.warningText}>
              {"Note: Flash Pay is only applicable to Visa and Mastercard."}
            </p>
          )}
        </div>
      )}

      <StyledCalendar
        submitText={loading ? "Selecting payout date..." : "Select"}
        loading={loading}
        selectDate={value}
        defaultValue={defaultValue}
        onSelectDate={onSelectDate}
        header={
          isEndDayNote ? "Select schedule end date" : "Select payout date"
        }
        isOpen={isDatePickerOpen}
        onSubmit={internalSelectDate}
        onClose={openDatePicker.bind(null, false)}
        earliestDate={earliestDate}
        flashPayDate={flashPayDate}
        instantPayDate={instantPayDate}
        disabledDates={exclusionDates}
        pspNonWorkingDates={pspNonWorkingDates}
        allowWeekends={allowWeekends}
        additionalModifiers={{
          chargeDate,
          publicHoliday: exclusionDates,
          modifiers: {
            today: chargeDate || new Date()
          }
        }}
        isEndDayNote={isEndDayNote}
        isAUDeadline={isAUDeadline}
        isCustomCalendar={isCustomCalendar}
        label={"Schedule end date"}
        showTooltip={showTooltip}
        flashPayInfo={flashPayInfo}
        showFlashPay={showFlashPay}
        instantPayInfo={instantPayInfo}
        showInstantPay={showInstantPay}
        countryCode={countryCode}
      />
    </React.Fragment>
  );
};

export default OneTimeDateSelect;
